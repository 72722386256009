import { ENV } from 'env'
// ** Import React
import { useState } from 'react'

// ** Import MUI components
import { useTheme, Typography, Box, Button } from '@mui/material'

// ** Import components
import { DealPoint, DownloadPdf } from 'shared/ui'
import { ModalInsurance } from '../ModalInsurance/ModalInsurance'
import { ReactComponent as InfoIcon } from './info-icon.svg'

type TabInsuranceProps = {
  id: string
  companyId: number
  insurance: {
    text: string
    phone: string
    site: string
  }
  policy?: {
    name: string
    src: string
    size: string
  }[]
}

export const TabInsurance = ({ id, companyId, insurance, policy }: TabInsuranceProps): JSX.Element => {
  const theme = useTheme()
  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <>
      {policy
        ? policy.map(doc => (
            <DownloadPdf
              key={doc.name}
              link={`${window.routes5[`media${ENV}`]}${doc.src}`}
              name={doc.name}
              weight={doc.size}
            />
          ))
        : null}

      <Typography
        sx={{
          fontSize: '1.125rem',
          fontWeight: 700,
          lineHeight: '22px',
          margin: '20px 0 10px'
        }}
      >
        {insurance.text}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: '20px' }}>
        <Box>
          <InfoIcon />
        </Box>
        <Typography
          sx={{
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: '15px',
            ml: '13px',
            color: theme.palette['Sup-2']
          }}
        >
          В случае возникновения страхового случая необходимо обращаться в компанию, предоставляющую услуги по
          страхованию.
        </Typography>
      </Box>

      {insurance.phone ? (
        <DealPoint
          title='Телефон компании'
          link={insurance.phone}
          href={`tel:+${insurance.phone.replace(/\D/g, '')}`}
        />
      ) : null}

      {insurance.site ? (
        <DealPoint title='Сайт компании' link={insurance.site} href={`https://${insurance.site}`} target='blank' />
      ) : null}

      <Button
        variant='outlined'
        sx={{ marginTop: '20px', marginBottom: '20px' }}
        onClick={() => setOpenModal(!openModal)}
      >
        Сообщить о страховом случае
      </Button>

      {/* Open modal window */}
      <ModalInsurance openModal={openModal} id={id} companyId={companyId} setOpenModal={setOpenModal} />
    </>
  )
}
