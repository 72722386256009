import { ENV } from 'env'
import { FiltresNews, FullNews, FullNewsResponse, News, NewsAllResponse } from './types'
import { baseApi } from 'shared/api'

export const NewsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getAllNews: build.query<FiltresNews, void>({
      query: () => ({
        url: `${window.routes5.news.requests.allNews[`url${ENV}`]}`,
        params: {
          action: 'getAllData'
        }
      }),
      transformResponse: (response: NewsAllResponse) => {
        const transformedResponse: FiltresNews = {}
        const date = new Date().getFullYear()
        const nonFiltredNews: News[] = Object.values(response.result)
        transformedResponse.actualYear = nonFiltredNews.filter((news: News) => news.DATE.includes(String(date)))
        transformedResponse.lastYear = nonFiltredNews.filter((news: News) => news.DATE.includes(String(date - 1)))
        transformedResponse.archive = nonFiltredNews
          .filter((news: News) => !news.DATE.includes(String(date)))
          .filter((news: News) => !news.DATE.includes(String(date - 1)))
        return transformedResponse
      }
    }),
    getFullNews: build.query<FullNews, string>({
      query: id => ({
        url: `${window.routes5.news.requests.newsDetail[`url${ENV}`]}`,
        params: {
          action: 'getData',
          id
        }
      }),
      transformResponse: (response: FullNewsResponse) => {
        const rejectData = response.result
        rejectData.otherNews = Object.values(response.result.OTHER)

        return rejectData
      }
    })
  })
})

export const { useGetAllNewsQuery, useGetFullNewsQuery } = NewsApi
