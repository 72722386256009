import { styled, Box } from '@mui/material'

export const S_CloseButton = styled('button')(props => ({
  margin: 0,
  padding: 0,
  border: 0,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  color: props.theme.palette['Corp-1'],
  backgroundColor: 'transparent',
  cursor: 'pointer'
}))

export const S_Icon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  boxShadow: `inset 0 0 0 1px ${theme.palette['Sup-2']}, inset 0 -1px 0 ${theme.palette['Sup-2']}`,
  backgroundColor: theme.palette['Main-2']
}))

export const S_CheckedIcon = styled(S_Icon)(props => ({
  backgroundColor: props.theme.palette['copper'],
  boxShadow: `inset 0 0 0 1px ${props.theme.palette['copper']}, inset 0 -1px 0 ${props.theme.palette['copper']}`,
  backgroundImage: `linear-gradient(180deg,${props.theme.palette['copper']},hsla(0,0%,100%,0))`,
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: `radial-gradient(${props.theme.palette['Main-2']},${props.theme.palette['Main-2']} 28%,transparent 32%)`,
    content: '""'
  }
}))

export const S_Search = styled('input')(props => ({
  outline: 'none',
  width: '100%',
  border: 0,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px',
  color: props.theme.palette['Main-1'],
  backgroundColor: 'transparent',

  '&:placeholder': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: props.theme.palette['Sup-3']
  }
}))

export const S_Chips = styled(Box)(props => ({
  overflow: 'auto',
  backgroundColor: props.theme.palette['Main-2'],
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingLeft: '16px',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    width: 0,
    height: 0
  }
}))
