// ** Import components
import { S_Code } from './Code.styled'

type CodeProps = {
  status: string | undefined
  onComplete: (arg0: string) => void
  pin: boolean
}

export const Code = ({ status, onComplete, pin }: CodeProps): JSX.Element => {
  return (
    <S_Code
      status={status}
      pin={pin}
      placeholder={['•', '•', '•', '•', '•']}
      fields={5}
      onComplete={(value: string) => {
        onComplete(value)
      }}
    />
  )
}
