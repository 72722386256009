// ** Import MUI components
import { useTheme, Box } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'

// ** Import redux
import { useAppDispatch } from 'shared/hooks'

// ** Import react-redux
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import components
import { Loader, NewsCard, TopNavigation } from 'shared/ui'

// ** Import api
import { useGetAllUsefulNewsQuery } from 'entities/usefulNews/api/api'
import { setCurrentArticlesPage } from 'entities/meta/model/model'

export const UsefulNews = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const { data, isSuccess } = useGetAllUsefulNewsQuery()

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <Box sx={{ background: theme.palette['Main-2'] }}>
          <TopNavigation header='Полезные статьи' back={() => navigate(-1)} />
          <TabContext value={''}>
            <TabPanel
              value={''}
              sx={{
                padding: '20px  16px'
              }}
            >
              {data.map(item => (
                <Box key={item.ID} sx={{ marginBottom: '20px' }}>
                  <NewsCard
                    {...item}
                    navigationRoute={item.ID.toString()}
                    onClick={() => {
                      dispatch(setCurrentArticlesPage('usefulNews'))
                    }}
                  />
                </Box>
              ))}
            </TabPanel>
          </TabContext>
        </Box>
      ) : null}
    </>
  )
}
