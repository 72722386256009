import type { MoreProps } from 'shared/@types'

// ** Import react
import { useEffect } from 'react'

// ** Import react-router
import { Link, useNavigation } from 'react-router-dom'

// ** Import redux
import { useAppSelector } from 'shared/hooks'

// ** Import MUI components
import { useTheme, Typography, Box } from '@mui/material'

// ** Import components
import { Loader, MoreItem, Points } from 'shared/ui'
import { Bell } from 'entities/notification'
import UnknownUser from './user-fill.svg'

// ** Import API
import { useGetProfileQuery } from 'entities/profile'
import { useGetPointsQuery } from 'entities/point'

export const MoreAuthArray = [
  // { id: 1, href: '/more/bonus-welcome', icon: 'bonus', title: 'Бонусный счёт' },
  { id: 2, href: '/more/news', icon: 'news', title: 'Новости' },
  { id: 3, href: '/more/adverts', icon: 'promo', title: 'Акции' },
  { id: 4, href: '/more/services', icon: 'service', title: 'Сервисы' },
  // { id: 5, href: '', icon: 'chat', title: 'Чат' },
  { id: 5, href: '/more/help', icon: 'help', title: 'Помощь' },
  { id: 6, href: '/more/feedback', icon: 'feedback', title: 'Обратная связь' },
  { id: 7, href: '/more/info', icon: 'info', title: 'Правовая информация' },
  { id: 8, href: '/more/bug-bounty', icon: 'bug', title: 'Bug Bounty' },
  { id: 9, href: '/more/settings', icon: 'notification', title: 'Настройка уведомлений' },
  { id: 10, href: '/more/about', icon: 'about', title: 'О приложении' }
] as MoreProps[]

export const MoreArray = [
  { id: 1, href: '/more/news', icon: 'news', title: 'Новости' },
  { id: 2, href: '/more/adverts', icon: 'promo', title: 'Акции' },
  { id: 3, href: '/more/services', icon: 'service', title: 'Сервисы' },
  { id: 4, href: '/more/help', icon: 'help', title: 'Помощь' },
  // { id: 5, href: '', icon: 'chat', title: 'Чат' },
  { id: 5, href: '/more/feedback', icon: 'feedback', title: 'Обратная связь' },
  { id: 6, href: '/more/info', icon: 'info', title: 'Правовая информация' },
  { id: 7, href: '/more/bug-bounty', icon: 'bug', title: 'Bug Bounty' },
  { id: 8, href: '/more/about', icon: 'about', title: 'О приложении' }
] as MoreProps[]

const jivoChat = document.createElement('script')

export const More = (): JSX.Element => {
  const theme = useTheme()
  const navigation = useNavigation()
  const isAuth = useAppSelector(state => state.meta.isAuth)
  const { data: profileData } = useGetProfileQuery(undefined, { skip: !isAuth })
  const { data: pointsData } = useGetPointsQuery(undefined, { skip: !profileData?.bonus })

  useEffect(() => {
    return () => {
      document.body.appendChild(jivoChat)
      document.body.removeChild(jivoChat)
      document.querySelector('#jivo-iframe-container')?.remove()
      document.querySelector('jdiv')?.remove()
    }
  }, [])

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Box sx={{ paddingTop: '20px' }}>
        <Box
          sx={{
            paddingX: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px'
          }}
        >
          <Typography
            variant='Head-1-B'
            component='h1'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            Еще
          </Typography>
          {isAuth ? <Bell to='/notifications' /> : null}
        </Box>

        <Link to={isAuth ? '/profile' : '/authentication'} style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              background: theme.palette['Main-2'],
              padding: '10px 16px',
              mb: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            {isAuth ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '34px',
                  height: '34px',
                  borderRadius: '50%',
                  backgroundColor: theme.palette.Green
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '18px',
                    textAlign: 'center',
                    color: theme.palette['Main-1']
                  }}
                >
                  {profileData?.initials}
                </Typography>
              </Box>
            ) : (
              <img style={{ paddingTop: '7px', paddingBottom: '7px' }} src={UnknownUser} alt='user-icon' />
            )}

            <Typography
              sx={{
                fontSize: '0.875rem',
                ml: '8px',
                textDecoration: 'none',
                color: theme.palette['Main-1'],
                fontWeight: '500'
              }}
            >
              {isAuth ? `${profileData?.lastName} ${profileData?.name}` : 'Вход и регистрация'}
            </Typography>
          </Box>
        </Link>

        <Box sx={{ background: theme.palette['Main-2'], padding: '0 16px 0 20px' }}>
          {isAuth ? (
            <>
              {MoreAuthArray.map(item =>
                item.title === 'Бонусный счёт' && pointsData ? (
                  <MoreItem key={item.id} pointsElement={<Points points={pointsData.points} />} {...item} />
                ) : (
                  <MoreItem key={item.id} {...item} />
                )
              )}
            </>
          ) : (
            <>
              {MoreArray.map(item => (
                <MoreItem key={item.id} {...item} />
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
