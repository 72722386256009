// ** Import react
import { SyntheticEvent, useState } from 'react'

// ** Import redux
import { useAppSelector } from 'shared/hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'

// ** Import react-router
import { useNavigate, useNavigation, useLoaderData } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// ** Import components
import { TopNavigation, DealPoint, Loader } from 'shared/ui'
import { TabPayments } from './ui/TabPayments/TabPayments'
import { TabInsurance } from './ui/TabInsurance/TabInsurance'
import { TabDocument } from './ui/TabDocument/TabDocument'

// ** Import utils
import { formatter } from 'shared/utils'

// ** Import api
import { useGetRequestQuery } from 'entities/request'

export const RequestDetail = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const defaultCompany = useAppSelector(state => state.profile.defaultCompany)
  const [value, setValue] = useState<string>('1')
  const { id } = useLoaderData() as { id: string }
  const { data, isSuccess } = useGetRequestQuery(defaultCompany ? { id: id, companyId: defaultCompany.id } : skipToken)

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}
      {isSuccess && defaultCompany ? (
        <Box sx={{ background: theme.palette['Sup-1'] }}>
          <TopNavigation
            header={data.header}
            back={() => {
              navigate(-1)
            }}
          />

          <Box sx={{ padding: '20px 16px', background: theme.palette['Main-2'], mb: '8px' }}>
            <Typography
              variant='Small-text-1-M'
              component='span'
              sx={{
                padding: '2px 8px',
                background: data.status.color,
                borderRadius: '4px'
              }}
            >
              {data.status.text}
            </Typography>
            <Typography
              variant='Head-3-B'
              component='h2'
              sx={{
                margin: '10px 0 5px'
              }}
            >
              {data.header}
            </Typography>
            {data.model ? (
              <Typography variant='Head-3-R' component='p' sx={{ color: theme.palette['Corp-1'], mb: '15px' }}>
                {data.model}
              </Typography>
            ) : null}

            <DealPoint title='Договор' description={data.treat} />
            <DealPoint title='Дата заключения' description={data.treat_date} />
            <DealPoint title='Срок лизинга' description={data.leasing_duration} />
            {data.sum ? <DealPoint title='Итоговая сумма платежей' description={formatter.format(data.sum)} /> : null}

            {data.redemption_value ? (
              <DealPoint title='Выкупная стоимость' description={data.redemption_value} />
            ) : null}
            {data.redemption_date ? <DealPoint title='Срок выкупа' description={data.redemption_date} /> : null}
          </Box>

          <Box sx={{ background: theme.palette['Main-2'] }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label='lab API tabs example' variant='fullWidth'>
                  <Tab
                    sx={{
                      fontSize: '0.75rem',
                      padding: '8px 15px',
                      textTransform: 'capitalize',
                      '&.Mui-selected': {
                        color: theme.palette['Corp-1']
                      }
                    }}
                    label='Платежи'
                    value='1'
                  />
                  <Tab
                    sx={{
                      fontSize: '0.75rem',
                      padding: '8px 15px',
                      textTransform: 'capitalize',
                      '&.Mui-selected': {
                        color: theme.palette['Corp-1']
                      }
                    }}
                    label='Страхование'
                    value='2'
                  />

                  {data.contracts || data.accompanying ? (
                    <Tab
                      sx={{
                        fontSize: '0.75rem',
                        padding: '8px 15px',
                        textTransform: 'capitalize',
                        '&.Mui-selected': {
                          color: theme.palette['Corp-1']
                        }
                      }}
                      label='Документы'
                      value='3'
                    />
                  ) : null}
                </TabList>
              </Box>
              <TabPanel value='1' sx={{ padding: '20px 0 25px' }}>
                <TabPayments
                  payment_sum={data.payment_sum}
                  sum={data.sum}
                  next_payment={data.next_payment}
                  remaining_payments={data.remaining_payments}
                  payment_schedule={data.payment_schedule}
                  docs={data.docs}
                  next_payment_date={data.next_payment_date}
                  questionRoute='/manager'
                />
              </TabPanel>
              <TabPanel value='2' sx={{ padding: '20px  16px' }}>
                <TabInsurance
                  id={data.id}
                  companyId={defaultCompany.id}
                  insurance={data.insurance}
                  policy={data.policy}
                />
              </TabPanel>

              <TabPanel value='3' sx={{ padding: '20px  16px 25px' }}>
                <TabDocument contracts={data.contracts} accompanying={data.accompanying} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      ) : null}
    </>
  )
}
