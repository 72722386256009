import { ENV } from 'env'
// ** Import react
import { useState } from 'react'

// ** Import swiper
import SwiperCore, { EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-fade'

// ** styles
import { S_Container, S_SlideContent, S_Navigation, S_Text, S_Counter, S_Image } from './Onboarding.styled'

// ** Import components
import { Box, Button } from '@mui/material'
import { Loader } from 'shared/ui/Loader/Loader'

// ** Import api
import { useGetOnboardingDataQuery } from 'entities/onboarding'

export const Onboarding = ({ start }: { start: () => void }): JSX.Element => {
  const { data, isLoading } = useGetOnboardingDataQuery()
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>()
  const [activeIndex, setActiveIndex] = useState<number>(0)

  if (isLoading) {
    return (
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(17, 17, 17, 0.4)'
        }}
      >
        <Loader width={60} />
      </Box>
    )
  }

  return (
    <>
      {data !== undefined ? (
        <S_Container>
          <Swiper
            modules={[EffectFade]}
            onSwiper={swiper => {
              setSwiperInstance(swiper)
            }}
            onSlideChange={swiperInstance => {
              setActiveIndex(swiperInstance.activeIndex)
            }}
            slidesPerView={1}
            allowTouchMove={false}
            effect='fade'
          >
            {data.map((slide, index) => (
              <SwiperSlide key={index}>
                <S_SlideContent>
                  <S_Image src={`${window.routes5[`media${ENV}`]}${slide.image}`} alt='Slide image' />
                  {/* <S_Navigation>
                    <S_Counter variant='Text-1-M' component={'p'}>
                      {index + 1}/{data.length}
                    </S_Counter>
                    <S_Text
                      variant='Head-2-B'
                      component={'div'}
                      dangerouslySetInnerHTML={{ __html: data[index].heading }}
                    />
                    {data.length === index + 1 ? (
                      <Button
                        variant='outlined'
                        onClick={() => {
                          start()
                        }}
                      >
                        Начать
                      </Button>
                    ) : (
                      <Button
                        aria-label='next'
                        variant='outlined'
                        onClick={() => {
                          swiperInstance?.slideNext()
                        }}
                      >
                        Далее
                      </Button>
                    )}
                  </S_Navigation> */}
                </S_SlideContent>
              </SwiperSlide>
            ))}

            <S_Navigation>
              <S_Counter variant='Text-1-M' component={'p'}>
                {activeIndex + 1}/{data.length}
              </S_Counter>
              <S_Text
                variant='Head-2-B'
                component={'div'}
                dangerouslySetInnerHTML={{ __html: data[activeIndex].heading }}
              />
              {data.length === activeIndex + 1 ? (
                <Button
                  variant='outlined'
                  onClick={() => {
                    start()
                  }}
                >
                  Начать
                </Button>
              ) : (
                <Button
                  aria-label='next'
                  variant='outlined'
                  onClick={() => {
                    swiperInstance?.slideNext()
                  }}
                >
                  Далее
                </Button>
              )}
            </S_Navigation>
          </Swiper>
        </S_Container>
      ) : null}
    </>
  )
}
