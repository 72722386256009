// ** Import MUI components
import { useTheme, Box, Typography } from '@mui/material'

// ** Import components
import { Tooltip } from '../Tooltip/Tooltip'

type OfferType = {
  header: string
  text: string
  icons?: string[]
  detail?: string
}

export const Offer = ({ header, text, icons, detail }: OfferType): JSX.Element => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '12px',
        padding: '15px',
        boxShadow: '0px -5px 30px rgba(0, 0, 0, 0.05)'
      }}
    >
      <Typography variant='Head-3-B' component='p' sx={{ marginBottom: '5px' }}>
        {header}
      </Typography>

      <Typography
        variant='Text-1-R'
        component='div'
        sx={{
          marginRight: '40px',
          '& p': {
            marginTop: 0,
            marginBottom: 0,
            color: theme.palette['Main-1']
          },
          '& span': {
            color: theme.palette.True
          }
        }}
        dangerouslySetInnerHTML={{
          __html: text
        }}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', marginRight: '40px', marginTop: '10px' }}>
        {icons
          ? icons.map((icon, index) => (
              <Box
                key={index}
                sx={{
                  borderRadius: '8px',
                  overflow: 'hidden',
                  minWidth: '34px',
                  width: '35px',
                  height: '35px',

                  '& img': {
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }
                }}
              >
                <img src={icon} alt='offer' />
              </Box>
            ))
          : null}

        {detail ? (
          <Box sx={{ position: 'absolute', bottom: '15px', right: '15px' }}>
            <Tooltip disabled={false} text={detail} />
          </Box>
        ) : (
          <Box sx={{ position: 'absolute', bottom: '15px', right: '15px' }}>
            <Tooltip disabled={true} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
