// ** Import MUI components
import { useTheme, Box, Typography } from '@mui/material'

// ** Import components
import { ReactComponent as PointIcon } from './point-icon.svg'

type PointsProps = {
  points: number
}

export const Points = ({ points }: PointsProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '2px',
        paddingTop: '2px',
        paddingRight: '6px',
        paddingBottom: '2px',
        paddingLeft: '6px',
        borderRadius: '4px',
        backgroundColor: theme.palette['Main-1']
      }}
    >
      <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Main-2'] }}>
        +{points}
      </Typography>
      <PointIcon />
    </Box>
  )
}
