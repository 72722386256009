import { ENV } from 'env'
import { baseApi } from 'shared/api'
import type { PhoneResponse, PhoneResponseResult, SmsResponse, PinResponse } from './types'

export const AuthApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    sendPhone: build.mutation<PhoneResponseResult, string>({
      query: phone => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'auth')
        bodyFormData.append('phone', phone)
        return {
          url: `${window.routes5.auth.requests.sendPhone[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: PhoneResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PhoneResponse) => response.result,
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as PhoneResponse

          if (serverResponse.status === 'error') {
            return serverResponse.text
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    }),
    sendSms: build.mutation<
      'error' | 'success',
      {
        code: string
        phone: string
      }
    >({
      query: values => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'sms_code_by_pin')
        bodyFormData.append('phone', values.phone)
        bodyFormData.append('code', values.code)
        return {
          url: `${window.routes5.auth.requests.sendSms[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: SmsResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: SmsResponse) => response.status,
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as SmsResponse

          if (serverResponse.status === 'error') {
            return serverResponse.text
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    }),
    sendPin: build.mutation<
      'error' | 'success',
      {
        phone: string
        pin: string
      }
    >({
      query: values => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'auth_by_pin')
        bodyFormData.append('phone', values.phone)
        bodyFormData.append('pin', values.pin)
        return {
          url: `${window.routes5.auth.requests.sendPin[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: PinResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PinResponse) => response.status,
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as PinResponse

          if (serverResponse.status === 'error') {
            return serverResponse.text
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    }),
    sendNewPin: build.mutation<
      'error' | 'success',
      {
        phone: string
        pin: string
      }
    >({
      query: values => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'set_new_pin')
        bodyFormData.append('phone', values.phone)
        bodyFormData.append('pin', values.pin)
        return {
          url: `${window.routes5.auth.requests.sendNewPin[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: PinResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PinResponse) => response.status,
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as PinResponse

          if (serverResponse.status === 'error') {
            return serverResponse.text
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    }),
    sendForgotPin: build.mutation<
      'error' | 'success',
      {
        phone: string
      }
    >({
      query: values => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'reset_pin')
        bodyFormData.append('phone', values.phone)
        return {
          url: `${window.routes5.auth.requests.sendForgotPin[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: PinResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PinResponse) => response.status,
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as PinResponse

          if (serverResponse.status === 'error') {
            return serverResponse.text
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    }),
    sendLogout: build.mutation<SmsResponse['status'], void>({
      query: () => {
        return {
          url: `${window.routes5.auth.requests.sendLogout[`url${ENV}`]}`,
          method: 'GET',
          params: {
            action: 'logout'
          },
          credentials: 'include',
          validateStatus: (response, result: PhoneResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PhoneResponse) => response.status,
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as PhoneResponse

          if (serverResponse.status === 'error') {
            return serverResponse.text
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    })
  })
})

export const {
  useSendPhoneMutation,
  useSendSmsMutation,
  useSendPinMutation,
  useSendNewPinMutation,
  useSendForgotPinMutation,
  useSendLogoutMutation
} = AuthApi
