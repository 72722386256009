// ** Import MUI components
import { styled, TableCell } from '@mui/material'

export const TableCellCustom = styled(TableCell)(props => ({
  fontSize: '0.75rem',
  lineHeight: '15px',
  fontWeight: 500,
  border: 'none',
  padding: '10px 16px',
  color: props.theme.palette['Main-1']
}))
