// ** Import react-router
import { Link } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography } from '@mui/material'

// ** Import components
import { Points } from 'shared/ui'

type PartnerProps = {
  navigationRoute: string
  image: string
  header: string
  text: string
  points: number
}

export const PartnerOffer = ({ navigationRoute, image, header, text, points }: PartnerProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Box>
      <Link to={navigationRoute} style={{ textDecoration: 'none' }}>
        <Box
          sx={{
            position: 'relative',
            borderRadius: '12px',
            overflow: 'hidden',
            height: '100px',

            '& img': {
              display: 'block',
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }
          }}
        >
          <img src={image} alt='partner offer' />

          {/* <Box sx={{ position: 'absolute', bottom: '8px', left: '8px' }}>
            <Points points={points} />
          </Box> */}
        </Box>
        <Typography
          variant='Text-1-M'
          component='p'
          sx={{ marginBottom: '5px', marginTop: '10px', color: theme.palette['Main-1'] }}
        >
          {header}
        </Typography>

        <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
          {text}
        </Typography>
      </Link>
    </Box>
  )
}
