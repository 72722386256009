import { styled, Box } from '@mui/material'

export const S_MainLayout = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  maxWidth: '425px',
  width: '100%',
  margin: '0 auto',
  paddingBottom: '58px'
})

export const S_Outlet = styled(Box)({
  flex: 1,
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column'
})
