import type { StateTypes } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: StateTypes = {
  defaultCompany: undefined
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setDefaultCompany: (
      state,
      action: PayloadAction<{
        id: number
        inn: string
        kpp: string
        ogrn: string
        companyName: string
        companyAddress: string
      }>
    ) => {
      state.defaultCompany = action.payload
    }
  }
})

export const { setDefaultCompany } = profileSlice.actions

export default profileSlice.reducer
