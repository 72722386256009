// ** Import redux
import { useAppDispatch, useAppSelector } from 'shared/hooks'

// ** Import react-router
import { useNavigation, useNavigate, Link } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography, Button } from '@mui/material'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'
import presentImage from './present.png'

// ** Import api
import { setSmsType } from 'entities/meta'
import { useSendBonusPhoneMutation } from 'entities/bonus'

export const BonusEmpty = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const userPhone = useAppSelector(state => state.meta.authPhone)
  const [sendBonusPhone] = useSendBonusPhoneMutation({ fixedCacheKey: 'bonusPhone' })

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}
      <>
        <TopNavigation header='Бонусный счет' background={theme.palette['Corp-1']} back={() => navigate(-1)} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            backgroundColor: theme.palette['Corp-1']
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '20px',
              paddingRight: '16px',
              paddingBottom: '15px',
              paddingLeft: '16px',

              '& img': {
                display: 'block',
                width: '138px',
                height: '138px',
                objectFit: 'cover'
              }
            }}
          >
            <Typography variant='Head-3-B' component='p' sx={{ color: theme.palette['Main-2'] }}>
              Бонусная программа для клиентов Ураллизинга
            </Typography>

            <img src={presentImage} alt='present' />
          </Box>

          <Box
            sx={{
              paddingTop: '16px',
              paddingRight: '16px',
              paddingBottom: '25px',
              paddingLeft: '16px',
              backgroundColor: theme.palette['Main-2'],
              borderRadius: '16px 16px 0 0',
              flexGrow: '1'
            }}
          >
            <Typography variant='Head-3-B' component='p' sx={{ color: theme.palette['Main-1'], marginBottom: '5px' }}>
              Регистрация в бонусной программе “УралБонус”
            </Typography>

            <Typography variant='Text-1-R' component='p' sx={{ color: theme.palette['Main-1'], marginBottom: '20px' }}>
              После регистрации вы получите 100 приветственных баллов.
            </Typography>

            <Button
              variant='outlined'
              sx={{
                marginBottom: '10px'
              }}
              onClick={() => {
                dispatch(setSmsType('bonus'))
                if (userPhone) {
                  sendBonusPhone(userPhone)
                    .unwrap()
                    .then(() => {
                      navigate('/sms')
                    })
                    .catch(() => {
                      navigate('/sms')
                    })
                }
              }}
            >
              Стать участником
            </Button>

            <Button
              variant='outlined'
              component={Link}
              to='/more/bonus/program'
              sx={{
                marginBottom: '10px'
              }}
            >
              Подробнее о программе
            </Button>
          </Box>
        </Box>
      </>
    </>
  )
}
