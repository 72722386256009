import { ENV } from 'env'
import type { AdvertsResponse, AdvertResponse, AdvertDetailResponse, AdvertDetailData, AdvertForm } from './types.js'
import { baseApi } from 'shared/api'

export const AdvertApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getAdverts: build.query<AdvertsResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.adverts.requests.getAdverts[`url${ENV}`]}`,
          params: {
            action: 'getAllAdverts'
          },
          credentials: 'include',
          validateStatus: (response, result: AdvertsResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: AdvertsResponse) => response.result
    }),
    getAdvert: build.query<AdvertResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.adverts.requests.getAdvert[`url${ENV}`]}`,
          params: {
            action: 'getAdvert'
          },
          credentials: 'include',
          validateStatus: (response, result: AdvertResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: AdvertResponse) => response.result
    }),
    getDetailAdvert: build.query<AdvertDetailData, string>({
      query: id => {
        return {
          url: `${window.routes5.adverts.requests.getDetailAdvert[`url${ENV}`]}`,
          params: {
            action: 'getDetailAdvert',
            id: id
          },
          credentials: 'include',
          validateStatus: (response, result: AdvertDetailResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: AdvertDetailResponse) => {
        const transformedResponse = {} as AdvertDetailData

        transformedResponse.id = response.result.ID
        transformedResponse.header = response.result.PROP.HEADER
        transformedResponse.image = response.result.PROP.IMAGE
        transformedResponse.to = response.result.PROP.TO
        transformedResponse.viable = response.result.PROP.VIABLE
        transformedResponse['condition-1'] = response.result.PROP['CONDITION-1']
        transformedResponse['condition-2'] = response.result.PROP['CONDITION-2']

        transformedResponse.description = response.result.PROP.DESCRIPTION

        if (response.result.PROP.FROM) {
          transformedResponse.from = response.result.PROP.FROM
        }

        if (response.result.PROP.LINK) {
          transformedResponse.link = response.result.PROP.LINK
        }

        if (response.result.PROP.PHONE) {
          transformedResponse.phone = response.result.PROP.PHONE
        }

        return transformedResponse
      }
    }),
    sendAdvertForm: build.mutation<string, AdvertForm>({
      query: body => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'sendAdvertForm')
        bodyFormData.append('id', body.id)
        bodyFormData.append('phone', body.phone)
        bodyFormData.append('message', body.text)
        if (body.files) {
          for (let i = 0; i < body.files.length; i++) {
            bodyFormData.append(`file${i}`, body.files[i])
          }
        }
        return {
          url: `${window.routes5.adverts.requests.sendAdvertForm[`url${ENV}`]}`,
          method: 'POST',
          credentials: 'include',
          body: bodyFormData
        }
      },
      transformResponse: (response: { status: 'success' | 'error' }) => response.status
    })
  })
})

export const { useGetAdvertsQuery, useGetAdvertQuery, useGetDetailAdvertQuery, useSendAdvertFormMutation } = AdvertApi
