import { ENV } from 'env'
import type { SettingsResponse, SettingsData } from './types.js'
import { baseApi } from 'shared/api'

export const SettingsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getSettings: build.query<SettingsResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.settings.requests.getSettings[`url${ENV}`]}`,
          params: {
            action: 'getSettings'
          },
          credentials: 'include',
          validateStatus: (response, result: SettingsResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      providesTags: ['Settings'],
      transformResponse: (response: SettingsResponse) => response.result
    }),
    sendSettings: build.mutation<string, SettingsData>({
      query: body => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'send_settings')
        bodyFormData.append('deals_push', `${body.dealsPush}`)
        bodyFormData.append('deals_email', `${body.dealsEmail}`)
        bodyFormData.append('deals_sms', `${body.dealsSms}`)

        bodyFormData.append('chat_push', `${body.chatPush}`)
        bodyFormData.append('chat_email', `${body.chatEmail}`)
        bodyFormData.append('chat_sms', `${body.chatSms}`)

        bodyFormData.append('category_push', `${body.categoryPush}`)
        bodyFormData.append('category_email', `${body.categoryEmail}`)
        bodyFormData.append('category_sms', `${body.categorySms}`)

        return {
          url: `${window.routes5.settings.requests.sendSettings[`url${ENV}`]}`,
          method: 'POST',
          credentials: 'include',
          body: bodyFormData
        }
      },
      invalidatesTags: ['Settings'],
      transformResponse: (response: { status: 'success' | 'error' }) => response.status
    })
  })
})

export const { useGetSettingsQuery, useSendSettingsMutation } = SettingsApi
