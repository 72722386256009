import { styled } from '@mui/material/styles'

export const S_Icon = styled('span')(props => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  boxShadow: `inset 0 0 0 1px ${props.theme.palette['Sup-2']}, inset 0 -1px 0 ${props.theme.palette['Sup-2']}`,
  backgroundColor: props.theme.palette['Main-2']
}))

export const S_CheckedIcon = styled(S_Icon)(props => ({
  backgroundColor: props.theme.palette['copper'],
  boxShadow: `inset 0 0 0 1px ${props.theme.palette['copper']}, inset 0 -1px 0 ${props.theme.palette['copper']}`,
  backgroundImage: `linear-gradient(180deg,${props.theme.palette['copper']},hsla(0,0%,100%,0))`,
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: `radial-gradient(${props.theme.palette['Main-2']},${props.theme.palette['Main-2']} 28%,transparent 32%)`,
    content: '""'
  }
}))
