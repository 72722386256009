// ** Import styles
import { S_LoaderIcon } from './Loader.styled'

interface Props {
  width?: number
}

export const Loader = ({ width = 44 }: Props): JSX.Element => {
  return <S_LoaderIcon width={width} />
}
