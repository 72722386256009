// ** Import react
import type { CSSProperties } from 'react'

// ** Import react-router
import { Link } from 'react-router-dom'

// ** Import MUI
import { Button } from '@mui/material'

// ** Import styles
import { S_Container, S_Empty, S_Heading, S_Description } from './Empty.styled'

import empty from '../../assets/icons/empty.svg'

type EmptyProps = {
  isAuth: boolean
  style?: CSSProperties
}

type AuthProps = EmptyProps & {
  catalogLink: string
  guestLink?: never
}

type GuestProps = EmptyProps & {
  catalogLink?: never
  guestLink: string
}

export const Empty = ({ isAuth, catalogLink, guestLink, style }: AuthProps | GuestProps) => {
  return (
    <S_Container style={style}>
      <S_Empty src={empty} alt='empty' />
      <S_Heading variant='Text-1-M' component='h2'>
        Сделок пока нет
      </S_Heading>
      <S_Description variant='Text-2-R' component='p'>
        {isAuth
          ? 'Перейдите на страницу каталог чтобы совершить сделку'
          : 'Авторизуйтесь, чтобы начать совершать сделки.'}
      </S_Description>

      {isAuth ? (
        <Button variant='outlined' component={Link} to={`${catalogLink}`}>
          Перейти в каталог
        </Button>
      ) : (
        <Button variant='outlined' component={Link} to={`${guestLink}`} aria-label='authentication'>
          Авторизоваться
        </Button>
      )}
    </S_Container>
  )
}
