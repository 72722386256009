import { styled, Typography } from '@mui/material'

export const S_Container = styled('div')({
  maxWidth: '425px',
  width: '100%',
  margin: '0 auto'
})

export const S_SlideContent = styled('section')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'calc(100vh - 44px)',
  paddingTop: '44px',
  overflow: 'hidden',
  backgroundColor: props.theme.palette['Sup-1']
}))

export const S_Navigation = styled('section')(props => ({
  position: 'fixed',
  zIndex: 1,
  bottom: 0,
  width: '100%',
  maxWidth: '425px',
  margin: 0,
  padding: 0,
  borderRadius: '20px 20px 0 0',
  paddingTop: '15px',
  paddingRight: '16px',
  paddingBottom: '25px',
  paddingLeft: '16px',
  backgroundColor: props.theme.palette['Main-2'],
  boxSizing: 'border-box'
}))

export const S_Text = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '33px',
  padding: 0,
  color: props.theme.palette['Main-1']
})) as typeof Typography

export const S_Counter = styled(Typography)(props => ({
  color: props.theme.palette['Sup-2']
})) as typeof Typography

export const S_Image = styled('img')({
  display: 'block',
  maxWidth: '100%'
})
