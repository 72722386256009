// ** Import MUI components
import { styled, Typography, Box } from '@mui/material'

export const Container = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '20px 16px',

  '& .fullNews': {
    margin: 0,
    padding: 0,
    maxWidth: '100%',

    // '& h2': {
    //   margin: 0,
    //   marginTop: '10px',
    //   marginBottom: '10px',
    //   padding: 0,
    //   fontFamily: 'Inter',
    //   fontStyle: 'normal',
    //   fontWeight: 700,
    //   fontSize: '14px',
    //   lineHeight: '18px',
    //   color: props.theme.palette['Main-1']
    // },
    '& p': {
      margin: 0,
      marginTop: '10px',
      marginBottom: '10px',
      padding: 0,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      color: props.theme.palette['Main-1'],

      '&:first-of-type': {
        marginTop: 0
      },
      '&:last-of-type': {
        marginBottom: 0
      },
      '& a': {
        textDecoration: 'none',
        color: props.theme.palette['Corp-1']
      }
    },
    '& ul': {
      margin: 0,
      marginTop: '10px',
      marginBottom: '20px',
      padding: 0,
      listStyle: 'none',

      '& li': {
        marginTop: '10px',
        marginBottom: '10px',
        position: 'relative',
        paddingLeft: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        color: props.theme.palette['Main-1'],

        '&:before': {
          position: 'absolute',
          left: 0,
          top: '8px',
          width: '14px',
          height: '1px',
          backgroundColor: props.theme.palette['Main-1'],
          content: "''"
        }
      }
    },
    '& ol': {
      margin: 0,
      marginTop: '10px',
      marginBottom: '20px',
      padding: 0,
      listStyle: 'none',
      counterКeset: 'my-awesome-counter',

      '& li': {
        marginTop: '10px',
        marginBottom: '10px',
        position: 'relative',
        paddingLeft: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        color: props.theme.palette['Main-1'],
        counterIncrement: 'my-awesome-counter',

        '&:before': {
          position: 'absolute',
          left: 0,
          top: 0,
          with: '20px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
          color: props.theme.palette['Main-1'],
          content: 'counter(my-awesome-counter)'
        }
      }
    },
    '& table': {
      width: 'calc(100% + 32px)',
      transform: 'translateX(-16px)',
      borderCollapse: 'collapse',
      border: 0,

      '& tr': {
        '&:nth-of-type(even)': {
          backgroundColor: 'rgba(239, 242, 245, 0.5)'
        }
      },

      '& td': {
        paddingTop: '10px',
        paddingRight: '16px',
        paddingBottom: '10px',
        paddingLeft: '16px',
        border: 'none',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        color: props.theme.palette['Sup-2']
      }
    },
    '& img': {
      display: 'block',
      marginTop: '20px',
      marginBottom: '10px',
      maxWidth: '100%',
      borderRadius: '10px',
      height: 'auto'
    },
    '& iframe': {
      display: 'block',
      marginTop: '20px',
      marginBottom: '10px',
      width: '100%',
      maxWidth: '100%',
      borderRadius: '10px'
    },
    '& figure': {
      margin: 0,
      marginTop: '20px',
      marginBottom: '20px',
      padding: 0,

      '& figcaption': {
        margin: 0,
        padding: 0,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '15px',
        color: props.theme.palette['Sup-2']
      }
    },
    '& .quote': {
      position: 'relative',
      marginTop: '20px',
      marginBottom: '20px',
      borderTop: `2px solid ${props.theme.palette['Corp-1']}`,
      borderBottom: `2px solid ${props.theme.palette['Corp-1']}`,
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '33px',

      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '20px',
        height: '14px'
      },

      '& p': {
        '&:first-of-type': {
          margin: 0,
          marginBottom: '20px',
          padding: 0,
          fontFamily: 'Inter',
          fontStyle: 'italic',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19px',
          color: props.theme.palette['Main-1']
        },
        '&:nth-of-type(2)': {
          margin: 0,
          padding: 0,
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '18px',
          color: props.theme.palette['Main-1']
        },
        '&:last-of-type': {
          margin: 0,
          padding: 0,
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '15px',
          color: props.theme.palette['Sup-2']
        }
      }
    }
  }
}))

export const Title = styled(Typography)(props => ({
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '19px',
  color: props.theme.palette['Main-1'],
  marginBottom: '5px'
}))

export const S_Date = styled(Typography)(props => ({
  fontWeight: 500,
  fontSize: '0.75rem',
  lineHeight: '15px',
  color: props.theme.palette['Sup-2']
}))

export const TitleDescription = styled(Typography)(props => ({
  fontWeight: 700,
  fontSize: '0.875rem',
  lineHeight: '18px',
  color: props.theme.palette['Main-1'],
  marginBottom: '10px'
}))
