// ** Import react
import { useState } from 'react'

// ** Import react-router
import { useNavigation, useNavigate, useLocation, Link } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography, Snackbar, Alert } from '@mui/material'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'
import { Offer } from './ui/Offer/Offer'
import { ReactComponent as PointIcon } from './point-icon.svg'
import { ReactComponent as CheckIcon } from './check-icon.svg'

// ** Import styles
import { S_Link, S_Points } from './Bonus.styled'

// ** Import utils
import { numberSpaceFormatter } from 'shared/utils'

// ** Import api
import { useGetBonusQuery } from 'entities/bonus'
import { useGetPointsQuery } from 'entities/point'

export const Bonus = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const location = useLocation() as {
    state: {
      _isRedirect: boolean
    }
  }

  const { data: bonusData, isSuccess: isBonusSuccess } = useGetBonusQuery()
  const { data: pointsData } = useGetPointsQuery()
  const [isPopUpActive, setIsPopUpActive] = useState<boolean>(location.state._isRedirect)

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Snackbar
        open={isPopUpActive}
        autoHideDuration={2000}
        onClose={() => {
          setIsPopUpActive(false)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<CheckIcon fontSize='inherit' />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          Вы успешно зарегистрировались в бонусной программе “УралБонус”!
        </Alert>
      </Snackbar>

      {isBonusSuccess ? (
        <>
          <TopNavigation
            header='Бонусный счет'
            background={theme.palette['Corp-1']}
            color={theme.palette['Main-2']}
            back={() => navigate('/more')}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: '1',
              backgroundColor: theme.palette['Corp-1']
            }}
          >
            <Box
              sx={{
                display: 'grid',
                paddingTop: '20px',
                paddingRight: '20px',
                paddingBottom: '20px',
                paddingLeft: '16px',
                gridTemplateColumns: '1fr 1fr',
                // gridTemplateRows: '1fr 1fr',
                gridGap: 8
              }}
            >
              {/* <S_Link component={Link} to='points'>
                <Typography
                  variant='Text-1-B'
                  component='p'
                  sx={{ color: theme.palette['Main-1'], paddingLeft: '10px', paddingRight: '5px' }}
                >
                  Мои баллы
                </Typography>
                <Typography
                  variant='Text-2-R'
                  component='p'
                  sx={{
                    color: theme.palette['Main-1'],
                    marginTop: '5px',
                    marginBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '5px'
                  }}
                >
                  1 балл = 1 ₽
                </Typography>

                <S_Points>
                  <Typography variant='Text-1-B' component='p' sx={{ color: theme.palette['Corp-1'] }}>
                    {pointsData ? numberSpaceFormatter(pointsData.points) : 'Считаем баллы...'}
                  </Typography>
                  <PointIcon />
                </S_Points>
              </S_Link> */}

              {/* <S_Link component={Link} to='/more/bonus/program' sx={{ paddingBottom: '13px' }}>
                <Typography
                  variant='Text-1-B'
                  component='p'
                  sx={{ color: theme.palette['Main-1'], paddingLeft: '10px', paddingRight: '5px' }}
                >
                  О программе
                </Typography>
                <Typography
                  variant='Text-2-R'
                  component='p'
                  sx={{
                    color: theme.palette['Main-1'],
                    marginTop: '5px',
                    marginBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '5px'
                  }}
                >
                  Как зарабатывать и как тратить бонусные баллы.
                </Typography>
              </S_Link> */}

              <S_Link
                component='a'
                href={bonusData.externalLink}
                sx={{ paddingBottom: '13px', gridColumnStart: '1', gridColumnEnd: '3' }}
              >
                <Typography
                  variant='Text-1-B'
                  component='p'
                  sx={{ color: theme.palette['Main-1'], paddingLeft: '10px', paddingRight: '5px' }}
                >
                  Urallshop
                </Typography>
                <Typography
                  variant='Text-2-R'
                  component='p'
                  sx={{
                    color: theme.palette['Main-1'],
                    marginTop: '5px',
                    marginBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '5px'
                  }}
                >
                  Покупайте наш лимитированный мерч за бонусные баллы.
                </Typography>
              </S_Link>
            </Box>

            <Box
              sx={{
                paddingTop: '16px',
                paddingRight: '16px',
                paddingBottom: '25px',
                paddingLeft: '16px',
                backgroundColor: theme.palette['Main-2'],
                borderRadius: '16px 16px 0 0',
                flexGrow: '1'
              }}
            >
              <Typography
                variant='Head-2-B'
                component='h2'
                sx={{
                  color: theme.palette['Main-1'],
                  marginBottom: '5px'
                }}
              >
                Как зарабатывать бонусные баллы
              </Typography>
              <Typography
                variant='Text-1-R'
                component='p'
                sx={{
                  color: theme.palette['Main-1'],
                  marginBottom: '20px'
                }}
              >
                Получайте дополнительные бонусные баллы за выполнение заданий.
              </Typography>

              {bonusData.offers.map((offer, index) => (
                <Offer key={index} header={offer.header} text={offer.text} icons={offer.icons} detail={offer.detail} />
              ))}
            </Box>
          </Box>
        </>
      ) : null}
    </>
  )
}
