import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const S_Bell = styled(Link, { shouldForwardProp: prop => prop !== 'isNotification' })<{
  isNotification: boolean
}>(props => ({
  position: 'relative',
  display: 'block',
  width: '20px',
  height: '20px',

  '&:before': {
    position: 'absolute',
    top: 0,
    right: 0,
    display: props.isNotification ? 'initial' : 'none',
    width: '5px',
    height: '5px',
    backgroundColor: props.theme.palette.False,
    borderRadius: '50%',
    content: '""'
  }
}))
