import { ENV } from 'env'
// ** Import React
import { SyntheticEvent, useState } from 'react'

// ** Import MUI components
import { useTheme, Box } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

// ** Import components
import { AntTabs, AntTab } from './TabDocument.styled'
import { DownloadPdf } from 'shared/ui'

type TabDocumentProps = {
  contracts?: {
    name: string
    src: string
    size: string
  }[]
  accompanying?: {
    name: string
    src: string
    size: string
  }[]
}

export const TabDocument = ({ contracts, accompanying }: TabDocumentProps): JSX.Element => {
  const theme = useTheme()
  const [value, setValue] = useState<string>('1')

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ background: theme.palette['Main-2'] }}>
      <TabContext value={value}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <AntTabs onChange={handleChange} aria-label='lab API tabs example' centered>
            {contracts ? <AntTab label='Договорные' value='1' /> : null}
            {accompanying ? <AntTab label='Сопровождающие' value='2' /> : null}
          </AntTabs>
        </Box>

        {contracts ? (
          <TabPanel value='1' sx={{ padding: '20px 0 0' }}>
            {contracts.map(doc => (
              <DownloadPdf
                key={doc.name}
                link={`${window.routes5[`media${ENV}`]}${doc.src}`}
                name={doc.name}
                weight={
                  Number(doc.size) > 1048576
                    ? `${Math.round((Number(doc.size) * 100) / 1048576) / 100} MB`
                    : Number(doc.size) > 1024
                    ? `${Math.round((Number(doc.size) * 100) / 1024) / 100} KB`
                    : Number(doc.size) === 0
                    ? undefined
                    : `${doc.size} B`
                }
              />
            ))}
          </TabPanel>
        ) : null}

        {accompanying ? (
          <TabPanel value='2' sx={{ padding: '20px 0 0' }}>
            {accompanying.map(doc => (
              <DownloadPdf
                key={doc.name}
                link={`${window.routes5[`media${ENV}`]}${doc.src}`}
                name={doc.name}
                weight={doc.size}
              />
            ))}
          </TabPanel>
        ) : null}
      </TabContext>
    </Box>
  )
}
