// ** react
import { Fragment } from 'react'

// ** react-router
import { useNavigate, useNavigation, useLoaderData, useLocation } from 'react-router-dom'

// ** Import formik
import { Formik, Form } from 'formik'

// ** MUI components
import {
  useTheme,
  Box,
  Typography,
  Divider,
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio
} from '@mui/material'

// ** components
import { TopNavigation, Loader, DealSlider, Points } from 'shared/ui'

// ** styles
import { S_Icon, S_CheckedIcon } from './RecommendationDetail.styled'

// ** utils
import { formatter } from 'shared/utils'

// ** API
import { useGetRecommendationQuery } from 'entities/recommendation'

export const RecommendationDetail = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const location = useLocation()
  const { id } = useLoaderData() as { id: string }
  const { data, isSuccess } = useGetRecommendationQuery(id)

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <>
          <TopNavigation
            header={data.name}
            back={() => {
              navigate('/catalog', { state: location.state })
            }}
          />

          {data.images.length !== 0 ? (
            <Box sx={{ backgroundColor: theme.palette['Main-2'] }}>
              <DealSlider slides={data.images} />
            </Box>
          ) : null}

          <Box
            sx={{
              backgroundColor: theme.palette['Main-2'],
              paddingTop: '20px',
              paddingRight: '16px',
              paddingLeft: '16px',
              paddingBottom: '20px',
              marginBottom: '8px'
            }}
          >
            <Typography variant='Head-3-B' component='h2' sx={{ paddingBottom: '5px' }}>
              {data.name}
            </Typography>
            <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
              {data.type}
            </Typography>

            {/* <Box
              sx={{
                display: 'flex',
                columnGap: '6px',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <Points points={20} />
              <Typography variant='Text-2-M' component='p'>
                получите за оформление заявки
              </Typography>
            </Box> */}

            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

            <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'], marginBottom: '5px' }}>
              Цена
            </Typography>

            <Typography variant='Head-3-R' component='p'>
              {formatter.format(data.minPrice)} - {formatter.format(data.maxPrice)}
            </Typography>

            {/* <Typography
              variant='Text-2-M'
              component='p'
              sx={{ color: theme.palette['Sup-2'], marginTop: '10px', marginBottom: '5px' }}
            >
              Платеж в месяц от
            </Typography>

            <Typography variant='Head-3-R' component='p'>
              {formatter.format(data.monthlyPayment)}
            </Typography> */}
          </Box>
          <Box
            sx={{
              backgroundColor: theme.palette['Main-2'],
              paddingTop: '20px',
              paddingRight: '16px',
              paddingLeft: '16px',
              paddingBottom: '25px'
            }}
          >
            <Typography variant='Head-3-B' component='h2'>
              Комплектации
            </Typography>

            <Formik
              initialValues={{
                id: id,
                min: data.options[0].startingPrice ? data.options[0].startingPrice : data.minPrice,
                max: data.maxPrice
              }}
              onSubmit={values => {
                navigate('/calculator', { state: { id: id, min: Number(values.min), max: Number(values.max) } })
              }}
            >
              {({ values, handleChange }) => (
                <Form>
                  <FormControl sx={{ width: '100%', marginBottom: '25px' }}>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      value={values.min}
                      onChange={handleChange}
                      name='min'
                    >
                      {data.options.map((option, index) => (
                        <Fragment key={index}>
                          <Box>
                            <FormControlLabel
                              value={option.startingPrice}
                              control={<Radio checkedIcon={<S_CheckedIcon />} icon={<S_Icon />} />}
                              label={option.name}
                              disableTypography
                            />
                            <Box sx={{ marginLeft: '25px' }}>
                              <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                                от {formatter.format(option.startingPrice)}
                              </Typography>
                            </Box>
                          </Box>

                          {index + 1 !== data.options.length ? (
                            <Divider sx={{ marginTop: '10px', marginLeft: '25px' }} />
                          ) : null}
                        </Fragment>
                      ))}
                    </RadioGroup>
                  </FormControl>

                  <Button variant='outlined' type='submit'>
                    Рассчитать лизинг
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </>
      ) : null}
    </>
  )
}
