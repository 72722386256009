import { ENV } from 'env'
import type { FaqResponse } from './types'
import { baseApi } from 'shared/api'

export const FaqApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getFaqData: build.query<FaqResponse['result'], void>({
      query: () => ({
        url: `${window.routes5.faq.requests[`url${ENV}`]}`,
        validateStatus: (response, result: FaqResponse) => response.status === 200 && result.status !== 'error'
      }),
      transformResponse: (response: FaqResponse) => response.result
    })
  })
})

export const { useGetFaqDataQuery } = FaqApi
