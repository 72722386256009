import type { CatalogBody } from 'entities/catalog/api/types'

// ** Import react-router
import { Link } from 'react-router-dom'

// ** Import MUI
import { useTheme, Box, Typography } from '@mui/material'

// ** Import utils
import { formatter } from 'shared/utils'

type CatalogCardProps = {
  id: string
  price: number
  trend: 'positive' | 'negative'
  name: string
  images: string[]
  link: string
  action?: () => void
  catalogState?: CatalogBody
}

export const CatalogCard = ({ price, trend, name, images, link, action, catalogState }: CatalogCardProps) => {
  const theme = useTheme()
  return (
    <Box>
      {images.length !== 0 ? (
        <Box
          sx={{
            marginBottom: '12px',
            height: '100px'
          }}
        >
          <img
            src={images[0]}
            // src='https://preprod.urall.fivecorners.ru/upload/iblock/f0f/v0jpvhtufqtrrqhz6hg0cgkcpjykukn3.jpg'
            alt='catalog car'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '8px'
            }}
          />
        </Box>
      ) : null}

      <Link
        to={link}
        state={catalogState}
        style={{ textDecoration: 'none', color: theme.palette['Main-1'] }}
        onClick={action}
      >
        <Box>
          <Box sx={{ display: 'flex', marginBottom: '2px' }}>
            <Typography variant='Head-3-B' component='p'>
              {formatter.format(price)}
            </Typography>
            {/* <Typography
              variant='Head-3-B'
              component='span'
              sx={{
                color: trend === 'positive' ? theme.palette.True : theme.palette.False
              }}
            >
              {trend === 'positive' ? '↑' : '↓'}
            </Typography> */}
          </Box>

          <Typography variant='Text-1-M' component='p'>
            {name}
          </Typography>
        </Box>
      </Link>
    </Box>
  )
}
