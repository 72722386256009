// ** Import react
import { useState, SyntheticEvent } from 'react'

// ** Import redux
import { useAppDispatch, useAppSelector } from '../../shared/hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'

// ** Import react-router
import { Link, useNavigation, useNavigate } from 'react-router-dom'

// ** Import swiper components
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

// ** Import MUI components
import { useTheme, Box, Button, Typography, Tab, SwipeableDrawer, Divider } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// ** Import components
import { InstaStories } from 'entities/stories'
import { CalculatorBanner } from './ui/CalculatorBanner/CalculatorBanner'
import { Empty, Deal, Recommendation, Loader, Advert } from 'shared/ui'
import { UserLinks } from './ui/UserLink/UserLink'
import { PartnerOffer } from './ui/PartnerOffer/PartnerOffer'
import { Bell } from 'entities/notification'

// ** Import api
import { useGetRequestsQuery } from 'entities/request'
import { useGetDealsQuery } from 'entities/deal'
import { useGetRecommendationsQuery } from 'entities/recommendation'
import { useGetProfileQuery } from 'entities/profile'
import { useGetAdvertsQuery, useGetAdvertQuery } from 'entities/advert'
import { setIsAdvertShown } from 'entities/meta'
import { useGetServicesQuery } from 'entities/services'

import { format } from 'date-fns'

export const Home = (): JSX.Element => {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isAuth = useAppSelector(state => state.meta.isAuth)
  const isAdvertShown = useAppSelector(state => state.meta.isAdvertShown)
  const defaultCompany = useAppSelector(state => state.profile.defaultCompany)
  const [value, setValue] = useState<string>('1')
  const { data: requestsData } = useGetRequestsQuery(
    isAuth && defaultCompany !== undefined ? defaultCompany.id : skipToken
  )
  const { data: dealsData } = useGetDealsQuery(isAuth && defaultCompany !== undefined ? defaultCompany.id : skipToken)
  const { data: catalogData } = useGetRecommendationsQuery()
  const { data: advertsData } = useGetAdvertsQuery()
  const { data: ServicesData } = useGetServicesQuery()
  const { data: activeAdvert, isSuccess: isActiveAdvertSuccess } = useGetAdvertQuery(undefined, {
    skip: isAdvertShown
  })
  const { data: profileData, isSuccess: isProfileSuccess } = useGetProfileQuery(undefined, { skip: !isAuth })

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}
      <Box
        sx={{
          pt: '20px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingBottom: '15px'
          }}
        >
          <Typography variant='Head-1-B' component='h1'>
            Главная
          </Typography>

          {isAuth ? <Bell to='/notifications' /> : null}
        </Box>

        <Box sx={{ display: 'flex', columnGap: '8px', paddingLeft: '16px' }}>
          <Box>
            {isAuth && isProfileSuccess ? (
              <UserLinks profileLink='/profile' phone='+78005000352' initials={profileData.initials} />
            ) : (
              <UserLinks authLink='/authentication' phone='+78005000352' />
            )}
          </Box>
          <InstaStories />
        </Box>

        <CalculatorBanner link='/calculator' />

        {catalogData !== undefined && catalogData.length > 0 ? (
          <Box
            sx={{
              background: theme.palette['Main-2'],
              padding: '20px 0 20px',
              marginBottom: '8px'
            }}
          >
            <Typography
              variant='Head-2-B'
              component='h2'
              sx={{
                paddingLeft: '16px',
                paddingRight: '16px',
                marginBottom: '20px'
              }}
            >
              Рекомендуем
            </Typography>

            {catalogData.slice(0, 10).map(recommendation => (
              <Recommendation
                {...recommendation}
                key={recommendation.id}
                link={`/recommendation-detail/${recommendation.id}`}
              />
            ))}

            {catalogData.length > 10 ? (
              <Button
                variant='outlined'
                component={Link}
                to='/catalog'
                sx={{
                  width: 'calc(100% - 32px)',
                  marginTop: '20px',
                  marginLeft: '16px'
                }}
              >
                Смотреть все
              </Button>
            ) : null}
          </Box>
        ) : null}

        {advertsData !== undefined && advertsData.length > 0 ? (
          <Box
            sx={{
              background: theme.palette['Main-2'],
              padding: '20px 0 20px',
              marginBottom: '8px'
            }}
          >
            <Typography
              variant='Head-2-B'
              component='h2'
              sx={{
                paddingLeft: '16px',
                paddingRight: '16px',
                marginBottom: '20px'
              }}
            >
              Акции
            </Typography>

            <Swiper
              slidesPerView={'auto'}
              spaceBetween={10}
              autoplay
              style={{ paddingRight: '16px', paddingLeft: '16px' }}
            >
              {advertsData.slice(0, 10).map(advert => (
                <SwiperSlide style={{ width: '98%' }} key={advert.id}>
                  <Advert
                    link={`/advert-detail/${advert.id}`}
                    image={advert.image}
                    text={advert.text}
                    from={advert.from}
                    to={advert.to}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            {advertsData.length > 10 ? (
              <Button
                variant='outlined'
                component={Link}
                to='/adverts'
                sx={{
                  width: 'calc(100% - 32px)',
                  marginTop: '20px',
                  marginLeft: '16px'
                }}
              >
                Смотреть все
              </Button>
            ) : null}
          </Box>
        ) : null}

        {ServicesData !== undefined && ServicesData.length > 0 ? (
          <Box
            sx={{
              background: theme.palette['Main-2'],
              padding: '20px 0 20px',
              marginBottom: '8px'
            }}
          >
            <Typography
              variant='Head-2-B'
              component='h2'
              sx={{
                paddingLeft: '16px',
                paddingRight: '16px',
                marginBottom: '20px'
              }}
            >
              Предложения от партнеров
            </Typography>

            <Swiper
              slidesPerView={'auto'}
              spaceBetween={10}
              autoplay
              style={{ paddingRight: '16px', paddingLeft: '16px' }}
            >
              {ServicesData.slice(0, 10).map(service => (
                <SwiperSlide style={{ width: '58%' }} key={service.id}>
                  <PartnerOffer
                    navigationRoute={`/service-detail/${service.id}`}
                    image={service.image}
                    header={service.header}
                    text={service.text}
                    points={service.points}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            {ServicesData.length > 10 ? (
              <Button
                variant='outlined'
                component={Link}
                to='/services'
                sx={{
                  width: 'calc(100% - 32px)',
                  marginTop: '20px',
                  marginLeft: '16px'
                }}
              >
                Смотреть все
              </Button>
            ) : null}
          </Box>
        ) : null}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette['Main-2'],
            flexGrow: 1,
            padding: '20px 0 25px'
          }}
        >
          <Typography
            variant='Head-2-B'
            component='h2'
            sx={{
              paddingLeft: '16px',
              paddingRight: '16px',
              marginBottom: '20px'
            }}
          >
            Мои сделки
          </Typography>
          <TabContext value={value}>
            <TabList onChange={handleChange} variant='fullWidth' sx={{ marginBottom: '15px' }}>
              <Tab
                sx={{
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  padding: '8px 15px',

                  '&.Mui-selected': {
                    color: theme.palette['Main-1']
                  }
                }}
                label='Заявки'
                value='1'
              />
              <Tab
                sx={{
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  padding: '8px 15px',

                  '&.Mui-selected': {
                    color: theme.palette['Main-1']
                  }
                }}
                label='Договоры'
                value='2'
              />
            </TabList>
            <TabPanel
              value='1'
              sx={{
                padding: 0,
                margin: 0
              }}
            >
              {isAuth ? (
                dealsData !== undefined && dealsData.length > 0 ? (
                  <>
                    {dealsData.slice(0, 3).map(deal => (
                      <Deal navigationRoute={`/deal-detail/${deal.id}`} type='Заявка' {...deal} key={deal.id} />
                    ))}

                    {dealsData.length > 3 ? (
                      <Button
                        variant='outlined'
                        component={Link}
                        to='/deals'
                        sx={{
                          width: 'calc(100% - 32px)',
                          marginTop: '25px',
                          marginLeft: '16px'
                        }}
                      >
                        Смотреть все
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1,
                      paddingLeft: '16px',
                      paddingRight: '16px'
                    }}
                  >
                    <Empty isAuth={isAuth} catalogLink='/catalog' />
                  </div>
                )
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    paddingLeft: '16px',
                    paddingRight: '16px'
                  }}
                >
                  <Empty isAuth={isAuth} guestLink='/authentication' />
                </div>
              )}
            </TabPanel>
            <TabPanel
              value='2'
              sx={{
                padding: 0,
                margin: 0
              }}
            >
              {isAuth ? (
                requestsData !== undefined && requestsData.length > 0 ? (
                  <>
                    {requestsData.slice(0, 3).map(request => (
                      <Deal key={request.id} navigationRoute={`/request-detail/${request.id}`} {...request} />
                    ))}

                    {requestsData.length > 3 ? (
                      <Button
                        variant='outlined'
                        component={Link}
                        to='/deals'
                        sx={{
                          width: 'calc(100% - 32px)',
                          marginTop: '25px',
                          marginLeft: '16px'
                        }}
                      >
                        Смотреть все
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1,
                      paddingLeft: '16px',
                      paddingRight: '16px'
                    }}
                  >
                    <Empty isAuth={isAuth} catalogLink='/catalog' />
                  </div>
                )
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    paddingLeft: '16px',
                    paddingRight: '16px'
                  }}
                >
                  <Empty isAuth={isAuth} guestLink='/authentication' />
                </div>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Box>

      {isActiveAdvertSuccess ? (
        <SwipeableDrawer
          open={isAdvertShown === false}
          onClose={() => {
            dispatch(setIsAdvertShown(true))
          }}
          onOpen={() => {}}
          disableBackdropTransition={true}
          disableSwipeToOpen={true}
          anchor='bottom'
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '12px 12px 0px 0px'
            }
          }}
        >
          <Divider
            sx={{
              position: 'absolute',
              top: '10px',
              left: 0,
              right: 0,
              width: '35px',
              height: '5px',
              border: '0',
              backgroundColor: theme.palette['Main-2'],
              opacity: 0.4,
              borderRadius: '5px',
              margin: 'auto'
            }}
          />

          <Box
            sx={{
              overflow: 'hidden',
              height: '200px',

              '& img': {
                display: 'block',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }
            }}
          >
            <img src={activeAdvert.image} alt='partner offer' />
          </Box>

          <Box
            sx={{
              paddingTop: '20px',
              paddingRight: '16px',
              paddingBottom: '25px',
              paddingLeft: '16px'
            }}
          >
            <Typography variant='Head-2-B' component='h2' sx={{ color: theme.palette['Main-1'] }}>
              {activeAdvert.header}
            </Typography>

            {activeAdvert.from ? (
              <Typography
                variant='Text-2-M'
                component='span'
                sx={{ marginTop: '10px', marginBottom: '10px', color: theme.palette['Sup-2'] }}
              >
                C {format(new Date(activeAdvert.from), 'dd.MM.yyyy')} 
              </Typography>
            ) : null}

            <Typography
              variant='Text-2-M'
              component='span'
              sx={{ marginTop: '10px', marginBottom: '10px', color: theme.palette['Sup-2'] }}
            >
              {activeAdvert.from ? 'по' : 'До'} {format(new Date(activeAdvert.to), 'dd.MM.yyyy')}
            </Typography>

            <Typography variant='Text-1-R' component='p'>
              {activeAdvert.text}
            </Typography>

            <Button
              variant='outlined'
              sx={{ marginTop: '20px' }}
              onClick={() => {
                dispatch(setIsAdvertShown(true))
                navigate(`/advert-detail/${activeAdvert.id}`)
              }}
            >
              Подробнее
            </Button>
          </Box>
        </SwipeableDrawer>
      ) : null}
    </>
  )
}
