import { ENV } from 'env'
import { baseApi } from 'shared/api'
import type { FiltersResponse, CatalogResponse, CatalogData, CatalogBody, AdsResponse } from './types'

export const CatalogApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getFilters: build.query<FiltersResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.catalog.requests.getFiltersData[`url${ENV}`]}`,
          params: {
            action: 'getFilterData'
          },
          credentials: 'include',
          validateStatus: (response, result: FiltersResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: FiltersResponse) => response.result
    }),
    getCatalog: build.mutation<CatalogData, CatalogBody>({
      query: values => {
        return {
          url: `${window.routes5.catalog.requests.getRecommendations[`url${ENV}`]}`,
          method: 'POST',
          credentials: 'include',
          body: values,
          validateStatus: (response, result: CatalogResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: CatalogResponse) => {
        const transformedResponse: CatalogData = {
          page: response.result.page,
          loadMore: response.result.loadMore,
          recommendations: [],
          ...(response.result.filterModel && { modelType: response.result.filterModel })
        }

        response.result.recommendations.forEach(recommendation => {
          transformedResponse.recommendations.push({
            id: recommendation.ID,
            price: recommendation.SUM,
            trend: recommendation.TREND,
            name: recommendation.NAME,
            images: recommendation.SLIDES
          })
        })

        return transformedResponse
      }
    }),
    getAd: build.query<AdsResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.catalog.requests.getAds[`url${ENV}`]}`,
          credentials: 'include',
          validateStatus: (response, result: AdsResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: AdsResponse) => response.result
    })
  })
})

export const { useGetFiltersQuery, useGetCatalogMutation, useGetAdQuery } = CatalogApi
