// ** react-router
import { Link } from 'react-router-dom'

// ** MUI Components
import { useTheme, Box, Typography } from '@mui/material'

type NotificationPreviewProps = {
  to: string
  image: string
  status: 'unread' | 'old'
  date: string
  header: string
}

export const NotificationPreview = ({ to, image, status, date, header }: NotificationPreviewProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Link
      to={to}
      style={{
        display: 'grid',
        gridTemplateColumns: '34px auto',
        columnGap: '10px',
        alignItems: 'flex-start',
        textDecoration: 'none',
        color: 'initial',
        paddingTop: '14px',
        paddingBottom: '14px'
      }}
    >
      <Box
        sx={{
          width: '34px',
          height: '34px',
          borderRadius: '50%',
          overflow: 'hidden'
        }}
      >
        <img
          src={image}
          alt='notification preview'
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </Box>

      <Box>
        <Typography variant='Text-2-M' component='p' sx={{ marginBottom: '8px', marginRight: '16px' }}>
          {status === 'unread' ? (
            <Typography variant='Text-2-M' component='span' sx={{ color: theme.palette['Corp-2'] }}>
              • 
            </Typography>
          ) : null}

          {header}
        </Typography>

        <Typography variant='Small-text-1-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
          {date}
        </Typography>
      </Box>
    </Link>
  )
}
