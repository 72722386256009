// ** redux
import { useAppSelector } from 'shared/hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'

// ** react-router
import { useNavigate, useNavigation, useLoaderData } from 'react-router-dom'

// ** MUI Components
import { useTheme, Box, Stepper, Step, StepLabel, Typography } from '@mui/material'

// ** Components
import { TopNavigation, Loader, DealPoint, DealSlider } from 'shared/ui'

// ** Styles
import { S_Dot, S_Connector } from './DealDetail.styled'

// ** utils

// ** API
import { useGetDealQuery } from 'entities/deal'

export const DealDetail = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const defaultCompany = useAppSelector(state => state.profile.defaultCompany)
  const { id } = useLoaderData() as { id: string }
  const { data, isSuccess } = useGetDealQuery(defaultCompany ? { id: id, companyId: defaultCompany.id } : skipToken)

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <>
          <TopNavigation
            header={`Заявка № ${data.treat}`}
            back={() => {
              navigate(-1)
            }}
          />
          <Box
            sx={{
              paddingTop: '20px',
              paddingBottom: '20px',
              backgroundColor: theme.palette['Main-2'],

              '& .styles-module_content__2jwZj': {
                backgroundColor: '#000000'
              }
            }}
          >
            <Box sx={{ paddingLeft: '16px', paddingRight: '16px', marginBottom: '10px' }}>
              <Typography
                variant='Small-text-1-M'
                component='span'
                sx={{
                  padding: '2px 8px',
                  background: data.status.color,
                  borderRadius: '4px'
                }}
              >
                {data.status.text}
              </Typography>
              {data.sum ? (
                <Typography
                  variant='Head-3-B'
                  component='h2'
                  sx={{
                    margin: '10px 0 5px'
                  }}
                >
                  {data.sum}
                </Typography>
              ) : null}

              {data.model ? (
                <Typography variant='Head-3-R' component='p' sx={{ color: theme.palette['Corp-1'], mb: '15px' }}>
                  {data.model}
                </Typography>
              ) : null}
            </Box>

            {data.images && data.images.length !== 0 ? <DealSlider slides={data.images} /> : null}

            <Box sx={{ paddingLeft: '16px', paddingRight: '16px', marginTop: '20px' }}>
              <DealPoint title='Заявка' description={`№ ${data.treat}`} />
              <DealPoint title='Дата создания' description={data.treat_date} />
              <DealPoint title='Срок лизинга' description={data.leasing_duration} />

              {data.general_price ? <DealPoint title='Стоимость имущества:' description={data.general_price} /> : null}

              {data.initial_deposit ? (
                <DealPoint title='Первоначальный взнос:' description={data.initial_deposit} />
              ) : null}

              {data.supplier ? <DealPoint title='Поставщик:' description={data.supplier} /> : null}
            </Box>
          </Box>

          {data.history && data.history.length > 0 ? (
            <Box
              sx={{
                marginTop: '8px',
                paddingTop: '20px',
                paddingBottom: '25px',
                paddingRight: '16px',
                paddingLeft: '16px',
                backgroundColor: theme.palette['Main-2']
              }}
            >
              <Typography variant='Head-2-B' component='h2' sx={{ marginBottom: '20px' }}>
                История статусов
              </Typography>

              <Stepper orientation='vertical' connector={<S_Connector />}>
                {data.history.map((step, index) => (
                  <Step key={index} sx={{ marginTop: '2px' }}>
                    <StepLabel
                      icon={<S_Dot last={index + 1 === data.history.length ? true : false} />}
                      sx={{ padding: 0, alignItems: 'flex-start' }}
                    >
                      <Box>
                        <Typography
                          variant='Text-2-M'
                          component='p'
                          sx={{
                            marginBottom: '2px',
                            color: index + 1 === data.history.length ? theme.palette['Main-1'] : theme.palette['Sup-2']
                          }}
                        >
                          {step.text}
                        </Typography>
                        <Typography variant='Small-text-1-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                          {step.date}
                        </Typography>
                      </Box>
                    </StepLabel>
                  </Step>
                ))}

                {/* <Step>
                <StepLabel icon={<S_Dot />} sx={{ padding: 0, alignItems: 'flex-start' }}>
                  <Box>
                    <Typography variant='Text-2-M' component='p' sx={{ marginBottom: '2px', color: theme.palette['Sup-2'] }}>
                      Исполнение сделки
                    </Typography>
                    <Typography variant='Small-text-1-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      28.01.2022
                    </Typography>
                  </Box>
                </StepLabel>
              </Step> */}
              </Stepper>
            </Box>
          ) : null}
        </>
      ) : null}
    </>
  )
}
