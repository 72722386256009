import { ENV } from 'env'
import type {
  RecommendationResponse,
  RecommendationData,
  RecommendationDetailResponse,
  RecommendationDetailData
} from './types.js'
import { baseApi } from 'shared/api'

export const RecommendationApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getRecommendations: build.query<RecommendationData, void>({
      query: () => {
        return {
          url: `${window.routes5.recommendations.requests.getRecommendations[`url${ENV}`]}`,
          params: {
            action: 'getRecommendations'
          },
          validateStatus: (response, result: RecommendationResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: RecommendationResponse) => {
        const transformedResponse: RecommendationData = []

        response.result.forEach(recommendation => {
          transformedResponse.push({
            id: recommendation.ID,
            price: recommendation.SUM,
            trend: recommendation.TREND,
            name: recommendation.NAME,
            images: recommendation.SLIDES
          })
        })

        return transformedResponse
      }
    }),
    getRecommendation: build.query<RecommendationDetailData, string>({
      query: id => {
        return {
          url: `${window.routes5.recommendations.requests.getRecommendationDetail[`url${ENV}`]}`,
          params: {
            action: 'getRecomendationDetail',
            id: id
          },
          credentials: 'include',
          validateStatus: (response, result: RecommendationDetailResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: RecommendationDetailResponse) => {
        const transformedResponse: RecommendationDetailData = {
          id: response.result.ID,
          images: response.result.IMAGES,
          name: response.result.NAME,
          type: response.result.TYPE,
          minPrice: response.result.MIN_PRICE,
          maxPrice: response.result.MAX_PRICE,
          monthlyPayment: response.result.MONTHLY_PAYMENT,
          options: []
        }

        response.result.OPTIONS.forEach(option => {
          transformedResponse.options.push({
            name: option.NAME,
            startingPrice: option.STARTING_PRICE
          })
        })

        return transformedResponse
      }
    })
  })
})

export const { useGetRecommendationsQuery, useGetRecommendationQuery } = RecommendationApi
