// ** Import React
import { useState } from 'react'

// ** Import MUI components
import {
  useTheme,
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button
} from '@mui/material'

// ** Import components
import { TableCellCustom } from './TablePayments.styled'
import { ModalTermsPrice } from '../ModalTermsPrice/ModalTermsPrice'
import { ReactComponent as ClockIcon } from './clock-icon.svg'

// ** Import utils
import { formatDate, formatter } from 'shared/utils'

const Row = ({
  num,
  date,
  payment,
  price,
  questionRoute
}: {
  num: number
  date: string
  payment: number
  price: number
  questionRoute: string
}) => {
  const theme = useTheme()
  const currentDate = new Date()

  const dataDate = new Date(formatDate(date))

  const isOld = currentDate > dataDate
  const isExpired = payment < price && isOld

  const diffTime = Math.abs(Number(currentDate) - Number(dataDate))
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const [open, setOpen] = useState<boolean>(isExpired)

  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCellCustom
          sx={{
            color: `${open ? theme.palette['Main-2'] : isOld ? theme.palette['Sup-2'] : ''}`,
            background: `${open ? theme.palette['Sup-3'] : ''}`
          }}
          component='th'
          scope='row'
        >
          {num}
        </TableCellCustom>
        <TableCellCustom
          sx={{
            color: `${open ? theme.palette['Main-2'] : isOld ? theme.palette['Sup-2'] : ''}`,
            background: `${open ? theme.palette['Sup-3'] : ''}`
          }}
          align='left'
        >
          {formatter.format(price)}
        </TableCellCustom>
        <TableCellCustom
          sx={{
            color: `${open ? theme.palette['Main-2'] : isOld ? theme.palette['Sup-2'] : ''}`,
            background: `${open ? theme.palette['Sup-3'] : ''}`
          }}
          align='left'
        >
          {date}
        </TableCellCustom>
        <TableCellCustom sx={{ background: `${open ? theme.palette['Sup-3'] : ''}` }} />
      </TableRow>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit sx={{ background: theme.palette['Sup-3'] }}>
            <Box sx={{ marginLeft: '20%', pb: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pb: '10px',
                  borderBottom: '1px solid rgba(255,255,255, .5)'
                }}
              >
                <ClockIcon />
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: '500',
                    lineHeight: '15px',
                    ml: '2px',
                    color: theme.palette['Main-2']
                  }}
                >
                  {diffDays} дн.
                </Typography>
              </Box>
              {/* <Button
                aria-label='more'
                variant='text'
                sx={{
                  color: theme.palette['Main-2'],
                  fontWeight: 500,
                  fontSize: '0.75rem',
                  lineHeight: '15px',
                  textTransform: 'capitalize',
                  pl: 0
                }}
                onClick={() => setOpenModal(!openModal)}
              >
                Подробнее
              </Button> */}
              <ModalTermsPrice openModal={openModal} setOpenModal={setOpenModal} questionRoute={questionRoute} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export const TablePayments = ({
  schedule,
  questionRoute
}: {
  schedule: {
    date: string
    price: number
    payment: number
  }[]
  questionRoute: string
}) => {
  return (
    <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none', marginBottom: '25px' }}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCellCustom align='left'>№</TableCellCustom>
            <TableCellCustom align='left'>Сумма</TableCellCustom>
            <TableCellCustom align='left'>Дата</TableCellCustom>
            <TableCellCustom />
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule.map((item: { date: string; price: number; payment: number }, index: number) => (
            <Row
              key={index}
              num={index + 1}
              date={item.date}
              price={item.price}
              payment={item.payment}
              questionRoute={questionRoute}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
