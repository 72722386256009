import { styled, Typography } from '@mui/material'

export const S_Container = styled('div')(props => ({
  margin: 0,
  padding: 0,
  paddingTop: '15px',
  paddingRight: '16px',
  paddingBottom: '20px',
  paddingLeft: '8px',
  display: 'grid',
  gridTemplateColumns: '24px auto 24px',
  columnGap: '5px',
  alignItems: 'center'
}))

export const S_Header = styled(Typography)(props => [
  {
    margin: 0,
    padding: 0,
    textAlign: 'center',
    gridColumnStart: 2,
    color: props.theme.palette['Main-1']
  }
]) as typeof Typography

export const S_Back = styled('button')(props => ({
  position: 'relative',
  width: '24px',
  height: '24px',

  '&:before': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    width: '9px',
    height: '9px',
    borderTop: `2px solid ${props.theme.palette['Main-1']}`,
    borderRight: `2px solid ${props.theme.palette['Main-1']}`,
    borderRadius: `2px solid ${props.theme.palette['Main-1']}`,
    transform: 'rotate(225deg)',
    content: "''"
  }
}))

export const S_LogoutLink = styled('button')({
  margin: 0,
  padding: 0,
  border: 'none',
  width: '24px',
  height: '24px',
  backgroundColor: 'transparent'
})
