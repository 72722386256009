import { styled, Typography, Snackbar, Link } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export const S_Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  maxWidth: '425px',
  margin: '0 auto',
  position: 'relative'
})

export const S_Container = styled('div')(props => ({
  postion: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'calc(100% - 58px)',
  paddingRight: '16px',
  paddingLeft: '16px',
  backgroundColor: props.theme.palette['Main-2']
}))

export const S_Heading = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '22px',
  padding: 0,
  textAlign: 'center',
  color: props.theme.palette['Main-1']
})) as typeof Typography

// 'Small-text-1-R'
// p
export const S_Legal = styled(Typography)(props => ({
  margin: 0,
  marginTop: '18px',
  padding: 0,
  color: props.theme.palette['Sup-2'],

  '& a': {
    color: props.theme.palette['Sup-2']
  }
})) as typeof Typography

export const S_Navigation = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'calc(100% - 32px)',
  position: 'absolute',
  bottom: '25px'
})

export const S_SkipLink = styled(Link)(props => [
  {
    margin: 0,
    marginTop: '20px',
    padding: 0,
    color: props.theme.palette['Main-1'],
    textDecoration: 'none'
  }
]) as typeof Link

export const S_Snackbar = styled(Snackbar)(props => ({
  bottom: '95px',
  '& .MuiAlert-root': {
    paddingTop: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    borderRadius: '10px',
    background: 'rgba(33, 33, 33, 0.8)',
    backdropFilter: 'blur(15px)',
    alignItems: 'center',

    '& .MuiAlert-icon': {
      padding: 0,
      marginRight: '8px',
      color: props.theme.palette['Main-2']
    }
  },

  '& .MuiAlert-message': {
    padding: 0,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '15px',
    color: props.theme.palette['Main-2']
  }
}))

export const S_ErrorOutlineIcon = styled(ErrorOutlineIcon)(props => ({
  position: 'absolute',
  top: '10px',
  left: '10px',
  width: '15px',
  height: '15px',
  fill: props.theme.palette['Main-2']
}))

export const S_SnackbarText = styled(Typography)(props => ({
  margin: 0,
  padding: 0,
  color: props.theme.palette['Main-2']
})) as typeof Typography

export const S_SnackbarLink = styled(Link)(props => ({
  margin: 0,
  padding: 0,
  color: props.theme.palette['Main-2'],
  textDecoration: 'none'
})) as typeof Link
