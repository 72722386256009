// ** Import react
import { SyntheticEvent } from 'react'

// ** Import redux
import { useAppDispatch, useAppSelector } from 'shared/hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'

// ** Import react-router
import { Link, useNavigation } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Button, Typography, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// ** Import components
import { Loader, Deal, Empty, Points } from 'shared/ui'
import { Bell } from 'entities/notification'

// ** Import api
import { useGetRequestsQuery } from 'entities/request'
import { useGetDealsQuery } from 'entities/deal'
import { setDealsPageTab } from 'entities/meta'

export const Deals = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const navigation = useNavigation()
  const activeTab = useAppSelector(state => state.meta.dealsPage)
  const defaultCompany = useAppSelector(state => state.profile.defaultCompany)
  const { data: dealsData } = useGetDealsQuery(defaultCompany !== undefined ? defaultCompany.id : skipToken)
  const { data: requestsData } = useGetRequestsQuery(defaultCompany !== undefined ? defaultCompany.id : skipToken)

  const handleChange = (event: SyntheticEvent, newValue: 'deals' | 'requests') => {
    dispatch(setDealsPageTab(newValue))
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          background: theme.palette['Sup-1'],
          paddingTop: '20px'
        }}
      >
        <Box
          sx={{
            paddingX: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px'
          }}
        >
          <Typography variant='Head-1-B' component='h1'>
            Сделки
          </Typography>
          <Bell to='/notifications' />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            background: theme.palette['Main-2'],
            padding: '0px 0px 25px'
          }}
        >
          <TabContext value={activeTab}>
            <TabList onChange={handleChange} variant='fullWidth' sx={{ marginBottom: '15px' }}>
              <Tab
                sx={{
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  padding: '8px 15px',

                  '&.Mui-selected': {
                    color: theme.palette['Main-1']
                  }
                }}
                label='Заявки'
                value='deals'
              />
              <Tab
                sx={{
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  padding: '8px 15px',

                  '&.Mui-selected': {
                    color: theme.palette['Main-1']
                  }
                }}
                label='Договоры'
                value='requests'
              />
            </TabList>

            <TabPanel
              value='deals'
              sx={{
                padding: 0,
                margin: 0
              }}
            >
              {dealsData !== undefined && dealsData.length > 0 ? (
                <>
                  {dealsData.map((deal, index) => (
                    <Deal navigationRoute={`/deal-detail/${deal.id}`} type='Заявка' {...deal} key={index} />
                  ))}

                  <Button
                    variant='outlined'
                    component={Link}
                    to='/calculator'
                    sx={{
                      width: 'calc(100% - 32px)',
                      marginTop: '25px',
                      marginLeft: '16px'
                    }}
                  >
                    + Новая заявка
                  </Button>

                  {/* <Box
                    sx={{
                      display: 'flex',
                      columnGap: '6px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '20px'
                    }}
                  >
                    <Points points={20} />
                    <Typography variant='Text-2-M' component='p'>
                      получите за оформление заявки
                    </Typography>
                  </Box> */}
                </>
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    paddingLeft: '16px',
                    paddingRight: '16px'
                  }}
                >
                  <Empty isAuth={true} catalogLink='/catalog' />
                </div>
              )}
            </TabPanel>
            <TabPanel
              value='requests'
              sx={{
                padding: 0,
                margin: 0
              }}
            >
              {requestsData !== undefined && requestsData.length > 0 ? (
                <>
                  {requestsData.map((request, index) => (
                    <Deal key={index} navigationRoute={`/request-detail/${request.id}`} {...request} />
                  ))}

                  <Button
                    variant='outlined'
                    component={Link}
                    to='/calculator'
                    sx={{
                      width: 'calc(100% - 32px)',
                      marginTop: '25px',
                      marginLeft: '16px'
                    }}
                  >
                    + Новая заявка
                  </Button>

                  {/* <Box
                    sx={{
                      display: 'flex',
                      columnGap: '6px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '20px'
                    }}
                  >
                    <Points points={20} />
                    <Typography variant='Text-2-M' component='p'>
                      получите за оформление заявки
                    </Typography>
                  </Box> */}
                </>
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    paddingLeft: '16px',
                    paddingRight: '16px'
                  }}
                >
                  <Empty isAuth={true} catalogLink='/catalog' />
                </div>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  )
}
