// ** Import react
import { useState } from 'react'

// ** Import redux
import { useAppSelector } from '../../shared/hooks'

// ** Import react-router-dom
import { useNavigate } from 'react-router-dom'

// ** Import formik
import { Formik, Form } from 'formik'

// ** Import MUI components
import { useTheme, Alert, Box, Snackbar } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// ** Import components
import { TopNavigation, Code, Loader } from 'shared/ui'

// ** Import styles
import { S_Container, S_Heading } from './CreatePin.styled'

// ** Import api
import { useSendNewPinMutation } from 'entities/auth'

export const CreatePin = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const userPhone = useAppSelector(state => state.meta.authPhone)
  const [sendNewPin, { isLoading, isSuccess, isError, error, reset }] = useSendNewPinMutation()

  const [codeStep, setCodeStep] = useState<1 | 2>(1)
  const [codeMismatch, setCodeMismatch] = useState<boolean>(false)

  return (
    <>
      {isLoading || isSuccess ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Snackbar
        open={codeMismatch}
        autoHideDuration={2000}
        onClose={() => {
          setCodeMismatch(false)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<ErrorOutlineIcon fontSize='inherit' sx={{ color: theme.palette['Main-2'] }} />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          Пин-коды не совпадают, попробуйте ещё раз!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isError}
        autoHideDuration={3000}
        onClose={() => {
          reset()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<ErrorOutlineIcon fontSize='inherit' sx={{ color: theme.palette['Main-2'] }} />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          {error as string}
        </Alert>
      </Snackbar>

      <TopNavigation
        header='Создать пин-код'
        back={() => {
          navigate(-1)
        }}
      />

      {userPhone ? (
        <S_Container>
          <S_Heading variant='Head-2-B' component='h2'>
            {codeStep === 1 ? 'Придумайте пин-код' : 'Повторите пин-код'}
          </S_Heading>

          <Formik
            initialValues={{ code: '', repeatCode: '', phone: userPhone }}
            validateOnBlur={false}
            onSubmit={(values, actions) => {
              if (values.code === values.repeatCode) {
                sendNewPin({ phone: userPhone, pin: values.repeatCode })
                  .unwrap()
                  .then(res => {
                    if (res === 'success') {
                      navigate('/pin')
                    }
                  })
                  .catch(() => {})
              } else {
                actions.resetForm()
                setCodeStep(1)
                setCodeMismatch(true)
              }
            }}
          >
            {({ setFieldValue, submitForm }) => (
              <>
                <Form noValidate>
                  {codeStep === 1 ? (
                    <Code
                      status={'error'}
                      pin={true}
                      onComplete={(value: string) => {
                        setFieldValue('code', value)
                        setCodeStep(2)
                      }}
                    />
                  ) : null}

                  {codeStep === 2 ? (
                    <Code
                      status={'error'}
                      pin={true}
                      onComplete={(value: string) => {
                        setFieldValue('repeatCode', value)
                        submitForm()
                      }}
                    />
                  ) : null}
                </Form>
              </>
            )}
          </Formik>
        </S_Container>
      ) : null}
    </>
  )
}
