import { ENV } from 'env'

// ** Import react
import { useState } from 'react'

// ** Import MUI
import { useTheme } from '@mui/material'

// ** Import swiper
import SwiperCore, { Thumbs, Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

// ** Import ImageViewer
import ImageViewer from 'react-simple-image-viewer'

// ** Styles
import { S_MainSlide, S_ThumbsSlide, S_ThumbsSwiper, S_MainSwiper } from './DealSlider.styled'

type DealSliderProps = {
  slides: string[]
}

export const DealSlider = ({ slides }: DealSliderProps): JSX.Element => {
  const theme = useTheme()
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>()
  const [currentImage, setCurrentImage] = useState<number | undefined>(undefined)
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false)

  const closeImageViewer = () => {
    setCurrentImage(undefined)
    setIsViewerOpen(false)
  }

  return (
    <>
      {isViewerOpen ? (
        <ImageViewer
          src={slides.map(item => `${window.routes5[`media${ENV}`]}${item}`)}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'Main-1',
            zIndex: 11
          }}
        />
      ) : null}

      <S_MainSwiper
        modules={[Thumbs, Pagination]}
        pagination={{
          type: 'fraction'
        }}
        thumbs={{ swiper: thumbsSwiper }}
        slidesPerView={1}
        style={{
          marginBottom: '3px'
        }}
      >
        {slides.map((image, index) => (
          <SwiperSlide key={index}>
            <S_MainSlide
              onClick={() => {
                setIsViewerOpen(true)
                setCurrentImage(index)
              }}
            >
              <img src={`${window.routes5[`media${ENV}`]}${image}`} alt='slide' />
            </S_MainSlide>
          </SwiperSlide>
        ))}
      </S_MainSwiper>

      <S_ThumbsSwiper
        modules={[Thumbs]}
        slidesPerView={'auto'}
        spaceBetween={3}
        watchSlidesProgress
        onSwiper={setThumbsSwiper}
      >
        {slides.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{
              width: '50px'
            }}
          >
            <S_ThumbsSlide>
              <img src={`${window.routes5[`media${ENV}`]}${image}`} alt='slide' />
            </S_ThumbsSlide>
          </SwiperSlide>
        ))}
      </S_ThumbsSwiper>
    </>
  )
}
