// ** Import React router
import { Link } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Dialog, Box, Typography, IconButton, Button } from '@mui/material'

// ** Import components
import { ReactComponent as CloseIcon } from './close-icon.svg'

type ModalTermsPriceProps = {
  openModal: boolean
  setOpenModal: (arg0: boolean) => void
  questionRoute: string
}

export const ModalTermsPrice = ({ openModal, setOpenModal, questionRoute }: ModalTermsPriceProps): JSX.Element => {
  const theme = useTheme()

  const handleClose = () => {
    setOpenModal(!openModal)
  }

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px'
        }
      }}
    >
      <Box sx={{ p: '20px', borderRadius: '10px', position: 'relative' }}>
        <Typography
          sx={{
            mr: '20px'
          }}
          variant='Head-3-B'
        >
          Условия начисления пени
        </Typography>
        <IconButton
          sx={{
            position: 'absolute',
            width: 18,
            height: 18,
            top: 8,
            right: 8,
            backgroundColor: theme.palette['Sup-1'],
            padding: '4px',

            '& svg': {
              fill: theme.palette['Sup-2']
            }
          }}
          onClick={handleClose}
        >
          <CloseIcon width={12} height={12} />
        </IconButton>

        <Typography
          sx={{
            m: '10px 0 25px'
          }}
          variant='Text-1-R'
          component='p'
        >
          Мы начислим пени со дня, следующего за днем оплаты согласно пункту 12.6 договора лизинга. Если у вас возникли
          дополнительные вопросы, задайте их своему менеджеру!
        </Typography>

        {/* /manager */}
        <Button aria-label='ask question' component={Link} to={questionRoute}>
          Задать вопрос
        </Button>
      </Box>
    </Dialog>
  )
}
