import { ENV } from 'env'
import { baseApi } from 'shared/api'
import { AllUsefulNewsResponse, AllUsefulNewsData, UsefulNewsDetailResponse, UsefulNewsDetailData } from './types'

export const UsefulNewsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getAllUsefulNews: build.query<AllUsefulNewsData[], void>({
      query: () => ({
        url: `${window.routes5.usefulNews.requests.getAllUsefulNews[`url${ENV}`]}`,
        params: {
          action: 'getAllData'
        }
      }),
      transformResponse: (response: AllUsefulNewsResponse) => {
        const nonFiltredNews: AllUsefulNewsData[] = Object.values(response.result)
        return nonFiltredNews
      }
    }),
    getUsefulNewsDetail: build.query<UsefulNewsDetailData, string>({
      query: id => ({
        url: `${window.routes5.usefulNews.requests.usefullNewsDetail[`url${ENV}`]}`,
        params: {
          action: 'getData',
          id
        }
      }),
      transformResponse: (response: UsefulNewsDetailResponse) => {
        const rejectData = response.result
        rejectData.otherNews = Object.values(response.result.OTHER)

        return rejectData
      }
    })
  })
})

export const { useGetAllUsefulNewsQuery, useGetUsefulNewsDetailQuery } = UsefulNewsApi
