import { styled, Typography, Box } from '@mui/material'

export const Container = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: '20px 16px',
  minHeight: '100px',
  '& .BugBounty': {
    margin: 0,
    padding: 0,
    maxWidth: '100%',
    '& h3': {
      margin: '0px 0px 10px 0px',
      padding: 0,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: props.theme.palette['Main-1']
    },
    '& h5': {
      margin: '10px 0px 20px 0px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px'
    },
    '& ul': {
      margin: '0px 0px 0px 0px',
      padding: '0px 0px 0px 13px',
      '& li': {
        margin: '10px 0px 10px 0px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        '& div': {
          marginLeft: '7px'
        }
      },
      'li::before': {
        left: '20px',
        position: 'relative'
      }
    },
    '& .hr': {
      background: 'rgba(239, 242, 245, 1)',
      height: '1px',
      margin: '20px 0px 20px 0px'
    },
    '& h4': {
      margin: '20px 0px 15px 0px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px'
    },
    '& .intro': {
      margin: '10px 0px px 0px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px'
    },
    '& p': {
      margin: '5px 0px 15px 0px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '15px'
    },
    '& span': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      color: 'rgba(129, 177, 62, 1)',
      '& b': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        color: 'rgba(129, 177, 62, 1)'
      }
    }
  }
}))
export const S_Heading = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '22px',
  padding: 0,
  color: props.theme.palette['Main-1']
})) as typeof Typography
export const S_Grid = styled('section')({
  margin: 0,
  padding: 0,
  display: 'grid',
  rowGap: '12px'
})
export const S_Container = styled('div')(props => ({
  postion: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'calc(100% - 58px)',
  width: '100%',
  backgroundColor: props.theme.palette['Main-2']
}))
export const S_FilePicker = styled('input')(props => ({
  opacity: 0,
  height: 0,
  width: 0,
  lineHeight: 0,
  overflow: 'hidden',
  padding: 0,
  margin: 0
}))
export const S_ButtonTypography = styled(Typography)(props => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  justifyContent: 'center',
  alignItems: 'center'
}))
export const S_TypographyComment = styled(Typography)(props => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '14px',
  color: 'rgba(142, 147, 153, 1)',
  marginLeft: '29px',
  marginBottom: '11px',
  alignItems: 'center'
}))
export const S_TypographyFile = styled(Typography)(props => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  color: props.theme.palette['Main-1'],
  marginBottom: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))
