// ** Import components
import PhoneIcon from './phone.svg'
import UserIcon from './user.svg'

// ** Import MUI
import { Box } from '@mui/material'

// ** Import styles
import { S_Link, S_Phone, S_Text } from './UsertLink.styled'

type UserLinkProps = {
  phone: string
}

type AuthUserLinkProps = UserLinkProps & {
  profileLink: string
  authLink?: never
  initials: string
}

type GuestUserLinkProps = UserLinkProps & {
  profileLink?: never
  authLink: string
  initials?: never
}

export const UserLinks = ({
  profileLink,
  authLink,
  phone,
  initials
}: AuthUserLinkProps | GuestUserLinkProps): JSX.Element => {
  return (
    <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: '1fr 1fr', rowGap: '8px' }}>
      {profileLink ? (
        <S_Link to={profileLink} profile={true}>
          <S_Text variant='Text-1-M' component='p'>
            {initials}
          </S_Text>
        </S_Link>
      ) : null}

      {authLink ? (
        <S_Link to={authLink} profile={false}>
          <S_Text variant='Text-1-M' component='p'>
            <img src={UserIcon} alt='callback' />
          </S_Text>
        </S_Link>
      ) : null}

      <S_Phone href={`tel:${phone}`} style={{ flexGrow: 1 }}>
        <img src={PhoneIcon} alt='callback' />
      </S_Phone>
    </Box>
  )
}
