// ** Import react-router
import { Link } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography } from '@mui/material'

import { format } from 'date-fns'

type AdvertProps = {
  link: string
  image: string
  text: string
  from?: string
  to: string
}

const TEXT_LENGTH = 40

export const Advert = ({ link, image, text, from, to }: AdvertProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Box>
      <Link to={link} style={{ textDecoration: 'none' }}>
        <Box
          sx={{
            borderRadius: '12px',
            overflow: 'hidden',
            height: '160px',

            '& img': {
              display: 'block',
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }
          }}
        >
          <img src={image} alt='advert' />
        </Box>
        <Typography
          variant='Text-1-M'
          component='p'
          sx={{ marginBottom: '5px', marginTop: '10px', color: theme.palette['Main-1'] }}
        >
          {text.length > TEXT_LENGTH ? `${text.slice(0, TEXT_LENGTH)}...` : text}
        </Typography>

        {from ? (
          <Typography variant='Text-2-M' component='span' sx={{ color: theme.palette['Sup-2'] }}>
            C {format(new Date(from), 'dd.MM.yyyy')} 
          </Typography>
        ) : null}

        <Typography variant='Text-2-M' component='span' sx={{ color: theme.palette['Sup-2'] }}>
          {from ? 'по' : 'До'} {format(new Date(to), 'dd.MM.yyyy')}
        </Typography>
      </Link>
    </Box>
  )
}
