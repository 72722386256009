// ** Import react
import { ChangeEvent, useEffect, useRef, useState } from 'react'

// ** Import react-router
import { useNavigation, useNavigate, useLoaderData } from 'react-router-dom'

// ** Import MUI components
import { Alert, Box, Button, Snackbar, TextField } from '@mui/material'

// ** Import components
import { Loader, PhoneInput, TopNavigation } from 'shared/ui'
import {
  Container,
  S_Heading,
  S_Container,
  S_FilePicker,
  S_ButtonTypography,
  S_TypographyComment,
  S_TypographyFile
} from './BugBounty.styled'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { phoneRegExp, textRegExp } from '../../shared/utils'
import ChooseFile from './choose-file.svg'
import OkIcon from './ok-icon.svg'
import WrongIcon from './wrong-icon.svg'
import { ReactComponent as CheckIcon } from './check-icon.svg'
import { ReactComponent as CloseButton } from './close-button.svg'
import { useSendFormBugBountyMutation } from 'entities/bugBounty'

const validationSchema = Yup.object().shape({
  phone: Yup.string().matches(phoneRegExp).required(),
  text: Yup.string().matches(textRegExp).required()
})

export const BugBounty = (): JSX.Element => {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data } = useLoaderData() as { data: string }
  const [fuc, setfuc] = useState<any>()
  const [files, setFiles] = useState<(File | null)[]>([])
  const [checkFile, setCheckFile] = useState<boolean>(true)
  const filepickerREF = useRef<HTMLInputElement>(null)
  const hendlerDropFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const wow = e.target.files[0]
      if (files.length < 3) setFiles(p => [...p, wow])
    }
  }
  const [hadlerSubmitForm, { data: submitData, reset }] = useSendFormBugBountyMutation()
  function createMarkup() {
    return { __html: data }
  }

  const handlerDeleteFile = (position: number) => {
    setFiles(p => p.filter((el, index) => index !== position))
  }
  useEffect(() => {
    if (filepickerREF.current !== null) {
      setfuc(
        (p: any) =>
          (p = () => {
            return filepickerREF.current?.click()
          })
      )
    }
    if (files.length > 0) {
      setCheckFile(p => (p = files.every(el => el && el.size < 10485760)))
    } else {
      setCheckFile(true)
    }
  }, [files])

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}
      <Box sx={{ background: '#FFFFFF' }}>
        <TopNavigation header='Bug Bounty' back={() => navigate('/more')} />
        <Container sx={{ background: '#fff' }}>
          <div className='BugBounty' dangerouslySetInnerHTML={createMarkup()} />

          <S_Container>
            <S_Heading variant='Head-2-B' component='h2'>
              Форма обратной связи
            </S_Heading>
            <Formik
              initialValues={{ phone: '', text: '' }}
              validationSchema={validationSchema}
              validateOnBlur={true}
              onSubmit={(values, { resetForm }) => {
                hadlerSubmitForm({ phone: values.phone, text: values.text.trim(), files })
                resetForm()
                setFiles([])
              }}
            >
              {({ values, errors, touched, dirty, isValid, handleChange, handleBlur, submitForm }) => (
                <>
                  <Form noValidate style={{ position: 'relative' }}>
                    <PhoneInput
                      mask='+7 (999) 999-99-99'
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='phone'
                      error={errors.phone && touched.phone ? true : false}
                    />

                    <TextField
                      value={values.text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='text'
                      label='Введите сообщение'
                      multiline
                      rows={4}
                      error={errors.text && touched.text ? true : false}
                      style={{ margin: '20px 0 20px 0', padding: 0 }}
                    />
                  </Form>
                  <S_FilePicker
                    onChange={hendlerDropFiles}
                    type='file'
                    accept='.png,.jpeg,.jpg,.gif,.pdf,.doc,.doxc'
                    ref={filepickerREF}
                  />
                  <Box
                    sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', height: '24px' }}
                    onClick={files.length < 3 ? fuc : null}
                  >
                    <img style={{ marginRight: '5px' }} src={ChooseFile} alt='choose-file' />
                    <S_ButtonTypography>
                      Прикрепить файлы <span style={{ color: 'rgba(142, 147, 153, 1)' }}>(не более 3-х)</span>
                    </S_ButtonTypography>
                  </Box>
                  <S_TypographyComment>
                    Допускаются файлы в формате: jpeg, gif, png, pdf, doc, docx; Размер не более 10 MB.
                  </S_TypographyComment>

                  {files &&
                    files.map(
                      (el, index) =>
                        el && (
                          <Box key={el?.name}>
                            <S_TypographyFile>
                              {el?.name}
                              <CloseButton onClick={() => handlerDeleteFile(index)} />
                            </S_TypographyFile>

                            <S_TypographyComment
                              sx={{
                                marginLeft: '0px',
                                display: 'flex',
                                color: el && (el?.size > 10485760 ? '#E52020' : 'rgba(142, 147, 153, 1)'),
                                marginBottom: index + 1 !== files.length ? '11px' : '20px'
                              }}
                            >
                              <img
                                style={{ marginRight: '5px' }}
                                src={el && el?.size < 10485760 ? OkIcon : WrongIcon}
                                alt='status-icon'
                              />
                              {el &&
                                (el?.size > 10485760
                                  ? 'Недопустимый файл'
                                  : el?.size > 1048576
                                  ? `${Math.round((el?.size * 100) / 1048576) / 100} MB`
                                  : el?.size > 1024
                                  ? `${Math.round((el?.size * 100) / 1024) / 100} KB`
                                  : `${el?.size} B`)}
                            </S_TypographyComment>

                            {index + 1 !== files.length ? (
                              <Box
                                sx={{
                                  background: 'rgba(239, 242, 245, 1)',
                                  height: '1px',
                                  margin: '11px 0px 11px 0px'
                                }}
                              />
                            ) : null}
                          </Box>
                        )
                    )}

                  <Box sx={{ mt: 'auto', marginTop: files.length > 0 ? '0px' : '9px' }}>
                    <Button
                      variant='outlined'
                      onClick={submitForm}
                      aria-label='send message'
                      disabled={!(isValid && dirty && checkFile)}
                    >
                      Отправить сообщение
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
            <Snackbar
              open={submitData === 'success' ? true : false}
              onClose={reset}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              sx={{
                bottom: '95px',
                '& .MuiPaper-root': {
                  borderRadius: '10px',
                  background: 'rgba(33, 33, 33, 0.8)',
                  backdropFilter: 'blur(7.5px)',
                  alignItems: 'center'
                },

                '& .MuiAlert-icon': {
                  marginRight: '8px'
                },

                '& .MuiSnackbarContent-root': {
                  minWidth: 'unset'
                },
                '& .MuiSnackbarContent-message': {
                  margin: '0 auto'
                }
              }}
            >
              <Alert
                icon={<CheckIcon fontSize='inherit' />}
                sx={{
                  '& .MuiAlert-message': {
                    color: '#FFFFFF',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '15px'
                  }
                }}
              >
                Сообщение отправлено!
              </Alert>
            </Snackbar>
          </S_Container>
        </Container>
      </Box>
    </>
  )
}
