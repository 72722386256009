import { ENV } from 'env'
import type { ServicesResponse, ServicesDetailResponse, ServicesDetailData, ServiceForm } from './types.js'
import { baseApi } from 'shared/api'

export const ServicesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getServices: build.query<ServicesResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.services.requests.getServices[`url${ENV}`]}`,
          params: {
            action: 'getAllServices'
          },
          credentials: 'include',
          validateStatus: (response, result: ServicesResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: ServicesResponse) => response.result
    }),
    getDetailService: build.query<ServicesDetailData, string>({
      query: id => {
        return {
          url: `${window.routes5.services.requests.getDetailService[`url${ENV}`]}`,
          params: {
            action: 'getDetailService',
            id: id
          },
          credentials: 'include',
          validateStatus: (response, result: ServicesDetailResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: ServicesDetailResponse) => {
        const transformedResponse = {} as ServicesDetailData

        transformedResponse.id = response.result.ID
        transformedResponse.header = response.result.PROP.HEADER
        transformedResponse.title = response.result.PROP.TITLE
        transformedResponse.points = response.result.PROP.POINTS
        transformedResponse.image = response.result.PROP.IMAGE
        transformedResponse['description-1'] = response.result.PROP['DESCRIPTION-1']
        transformedResponse['description-2'] = response.result.PROP['DESCRIPTION-2']
        transformedResponse.features = response.result.PROP.FEATURES

        if (response.result.PROP.LINK) {
          transformedResponse.link = response.result.PROP.LINK
        }

        if (response.result.PROP.PHONE) {
          transformedResponse.phone = response.result.PROP.PHONE
        }

        return transformedResponse
      }
    }),
    sendServiceForm: build.mutation<string, ServiceForm>({
      query: body => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'sendServiceForm')
        bodyFormData.append('id', body.id)
        bodyFormData.append('phone', body.phone)
        bodyFormData.append('message', body.text)
        if (body.files) {
          for (let i = 0; i < body.files.length; i++) {
            bodyFormData.append(`file${i}`, body.files[i])
          }
        }
        return {
          url: `${window.routes5.services.requests.sendServiceForm[`url${ENV}`]}`,
          method: 'POST',
          credentials: 'include',
          body: bodyFormData
        }
      },
      transformResponse: (response: { status: 'success' | 'error' }) => response.status
    })
  })
})

export const { useGetServicesQuery, useGetDetailServiceQuery, useSendServiceFormMutation } = ServicesApi
