import { ENV } from 'env'
import type { DealData, DealResponse, DealDetailResponse, DealDetailData } from './types.js'
import { baseApi } from 'shared/api'

import { formatter } from 'shared/utils'
export const DealApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getDeals: build.query<DealData[], number>({
      query: companyId => {
        return {
          url: `${window.routes5.deals.requests.getDeals[`url${ENV}`]}`,
          params: {
            action: 'getAllDeals',
            company_id: companyId
          },
          credentials: 'include',
          validateStatus: (response, result: DealResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: DealResponse) => {
        const transformedResponse: DealData[] = []

        response.result.forEach(deal => {
          transformedResponse.push({
            id: deal.ID,
            statusText: deal.STATUS_DISAPPEARS.UF_NAME,
            statusColor: deal.STATUS_DISAPPEARS.UF_COLOR,
            price: formatter.format(Number(deal.SUM.replace(',', '.'))),
            duration: deal.LEASING_DURATION,
            date: deal.DATE_TREAT,
            contract: deal.NUMBER_TREAT,
            images: deal.SLIDES,
            ...(deal.MODEL_AND_MARK && { model: deal.MODEL_AND_MARK.UF_NAME })
          })
        })

        return transformedResponse
      }
    }),
    getDeal: build.query<DealDetailData, { id: string; companyId: number }>({
      query: data => {
        return {
          url: `${window.routes5.deals.requests.getDeal[`url${ENV}`]}`,
          params: {
            action: 'getDetailData',
            id: data.id,
            company_id: data.companyId
          },
          credentials: 'include',
          validateStatus: (response, result: DealDetailResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: DealDetailResponse) => {
        const transformedResponse = {} as DealDetailData

        transformedResponse.id = response.result.ID
        transformedResponse.status = {
          color: `#${response.result.PROP.STATUS_DISAPPEARS.UF_COLOR}`,
          text: response.result.PROP.STATUS_DISAPPEARS.UF_NAME
        }

        if (response.result.PROP.SUM) {
          transformedResponse.sum = formatter.format(Number(response.result.PROP.SUM.replace(',', '.')))
        }

        if (response.result.PROP.MODEL_AND_MARK) {
          transformedResponse.model = response.result.PROP.MODEL_AND_MARK.UF_NAME
        }
        transformedResponse.treat = response.result.PROP.NUMBER_TREAT
        transformedResponse.treat_date = response.result.PROP.DATE_TREAT
        transformedResponse.leasing_duration = response.result.PROP.LEASING_DURATION

        if (response.result.PROP.GENERAL_PRICE) {
          transformedResponse.general_price = formatter.format(
            Number(response.result.PROP.GENERAL_PRICE.replace(',', '.'))
          )
        }

        if (response.result.PROP.INITIAL_DEPOSIT) {
          transformedResponse.initial_deposit = formatter.format(
            Number(response.result.PROP.INITIAL_DEPOSIT.replace(',', '.'))
          )
        }

        if (response.result.PROP.SUPPLIER) {
          transformedResponse.supplier = response.result.PROP.SUPPLIER.UF_NAME
        }

        if (response.result.PROP.IMAGES) {
          transformedResponse.images = response.result.PROP.IMAGES
        }

        if (response.result.PROP.HISTORY) {
          transformedResponse.history = []

          response.result.PROP.HISTORY.forEach(item => {
            transformedResponse.history.push({
              date: item.DATE,
              text: item.TEXT
            })
          })
        }

        return transformedResponse
      }
    })
  })
})

export const { useGetDealsQuery, useGetDealQuery } = DealApi
