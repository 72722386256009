// ** Import components
import { MenuItem } from '../MenuItem/MenuItem'

// ** Import styles
import { S_Menu } from './Menu.styled'

type MenuType = 'main' | 'deals' | 'catalog' | 'manager' | 'more'

export type MenuLink = {
  id: number
  href: string
  icon: MenuType
  name: string
}

type MenuPropsType = {
  links: MenuLink[]
}

export const Menu = ({ links }: MenuPropsType): JSX.Element => {
  return (
    <S_Menu>
      {links.map(item => (
        <MenuItem key={item.id} {...item} />
      ))}
    </S_Menu>
  )
}
