import { createTheme } from '@mui/material'
import InterBold from 'shared/fonts/Inter/Inter-Bold.woff2'
import InterMedium from 'shared/fonts/Inter/Inter-Medium.woff2'
import InterRegular from 'shared/fonts/Inter/Inter-Regular.woff2'

const baseTheme = createTheme({
  typography: {
    fontFamily: 'Inter',
    'Head-1-R': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '1.375rem',
      lineHeight: '1.6875rem'
    },
    'Head-1-B': {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '1.375rem',
      lineHeight: '1.6875rem'
    },
    'Head-2-B': {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '1.125rem',
      lineHeight: '1.375rem'
    },
    'Head-3-R': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '1rem',
      lineHeight: '1.1875rem'
    },
    'Head-3-B': {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '1rem',
      lineHeight: '1.1875rem'
    },
    'Text-1-R': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '1.125rem'
    },
    'Text-1-M': {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '0.875rem',
      lineHeight: '1.125rem'
    },
    'Text-1-B': {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '0.875rem',
      lineHeight: '1.125rem'
    },
    'Text-2-R': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.75rem',
      lineHeight: '0.9375rem'
    },
    'Text-2-M': {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '0.75rem',
      lineHeight: '0.9375rem'
    },
    'Text-2-B': {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '0.75rem',
      lineHeight: '0.9375rem'
    },
    'Small-text-1-R': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.625rem',
      lineHeight: '0.875rem'
    },
    'Small-text-1 R-link': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.625rem',
      lineHeight: '0.875rem',
      textDecorationLine: 'undeline'
    },
    'Small-text-1-M': {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '0.625rem',
      lineHeight: '0.875rem'
    },
    'Small-text-2-R': {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '0.5rem',
      lineHeight: '0.625rem'
    }
  },
  palette: {
    'Main-1': '#111111',
    'Main-2': '#FFFFFF',
    'Sup-1': '#F5F5F5',
    'Sup-2': '#949495',
    'Sup-3': '#9C9EA0',
    'Corp-1': '#000000',
    'Corp-2': '#FEAE1E',
    'Corp-3': '#F0AA3C',
    'Corp-4': '#C0321C',
    copper: '#C4773A',
    True: '#81B13E',
    False: '#E52020',
    Green: '#DAE8C6'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${InterBold}) format('woff2');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${InterMedium}) format('woff2');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${InterRegular}) format('woff2');
        }
      `
    }
  }
})

export const theme = createTheme(baseTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingTop: '17px',
          paddingBottom: '17px',
          borderRadius: '12px',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '0.875rem',
          lineHeight: '1.125rem',
          textTransform: 'none',
          boxShadow: 'none',
          color: baseTheme.palette['Main-2'],

          '&:active': {
            boxShadow: 'none'
          },

          '&.Mui-disabled': {
            backgroundColor: baseTheme.palette['Sup-1'],
            borderColor: baseTheme.palette['Sup-1'],
            color: baseTheme.palette['Sup-2']
          },
          '&.Mui-focusVisible': {
            boxShadow: 'none'
          }
        },
        contained: {
          backgroundColor: baseTheme.palette['Corp-1'],

          '&:hover': {
            boxShadow: 'none',
            backgroundColor: baseTheme.palette['Corp-1']
          }
        },
        outlined: {
          backgroundColor: 'transparent',
          color: baseTheme.palette['Corp-1'],
          borderColor: baseTheme.palette['Corp-1'],

          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderColor: baseTheme.palette['Sup-2']
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: baseTheme.palette['Sup-2']
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: 'transparent',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '1rem',
          lineHeight: '1.1875rem',
          color: baseTheme.palette['Main-1'],

          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${baseTheme.palette['copper']}`
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${baseTheme.palette['copper']}`
            }
          },
          '&.Mui-error': {
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: baseTheme.palette.False
              }
            }
          }
        },
        input: {
          paddingTop: '15px',
          paddingBottom: '16px',
          paddingLeft: '20px'
        },
        notchedOutline: {
          border: `1px solid ${baseTheme.palette['Sup-1']}`,
          borderRadius: '12px'
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: baseTheme.palette['copper'],
          height: '2px',
          padding: 0
        },
        rail: {
          opacity: 0.3,
          background: baseTheme.palette['Corp-1'],
          borderRadius: '8px'
        },
        mark: {
          height: '6px',
          opacity: 0.3,
          color: baseTheme.palette['Corp-1'],
          '&.MuiSlider-markActive': {
            backgroundColor: baseTheme.palette['copper'],
            opacity: 1
          }
        },
        track: {
          border: 0,
          backgroundColor: baseTheme.palette['copper']
        },
        thumb: {
          width: '10px',
          height: '16px',
          borderRadius: '10px',
          border: `2px solid ${baseTheme.palette['copper']}`,
          backgroundColor: baseTheme.palette['Main-2'],

          '&:after': {
            width: 32,
            height: 32
          }

          // '&:hover': {
          //   boxShadow: 'none'
          // },
          // '&.Mui-focusVisible': {
          //   boxShadow: 'none'
          // },
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          zIndex: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '-9px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
          color: baseTheme.palette['Main-1']
        },
        filled: {
          height: '30px',
          backgroundColor: baseTheme.palette['Sup-1'],

          '&.MuiChip-deletable': {
            backgroundColor: 'rgba(8, 94, 219, 0.2)',
            color: baseTheme.palette['Corp-1'],

            '&:hover': {
              backgroundColor: 'rgba(8, 94, 219, 0.2)'
            },

            '& .MuiChip-deleteIcon': {
              marginRight: '10px'
            }
          },

          '&:hover': {
            backgroundColor: baseTheme.palette['Sup-1']
          }
        },
        outlined: {
          borderColor: baseTheme.palette['Corp-1']
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          transform: 'translate(15px, -40px) !important'
        },
        tooltip: {
          width: '288px',
          padding: '10px',
          backgroundColor: baseTheme.palette['Sup-2'],
          boxShadow: '0px 8px 25px rgba(1, 26, 64, 0.35)',
          borderRadius: '10px'
        },
        arrow: {
          color: baseTheme.palette['Sup-2'],
          left: '-15px !important'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '34px',
          height: '18px',
          padding: '0',
          borderRadius: '20px'
        },
        switchBase: {
          padding: '0',
          top: '2px',
          left: '2px',
          color: baseTheme.palette['Main-2'],

          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: baseTheme.palette['Main-2'],

            '& + .MuiSwitch-track': {
              opacity: '1',
              backgroundColor: baseTheme.palette['copper']
            }
          }
        },
        thumb: {
          padding: '0',
          width: '14px',
          height: '14px',
          boxShadow: 'none'
        },
        track: {
          backgroundColor: baseTheme.palette['Sup-2']
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: baseTheme.palette.copper
        }
      }
    }
  }
})
