import { ENV } from 'env'
import type { PointResponse } from './types.js'
import { baseApi } from 'shared/api'

export const PointApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getPoints: build.query<PointResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.points.requests.getPoints[`url${ENV}`]}`,
          params: {
            action: 'getPoints'
          },
          credentials: 'include',
          validateStatus: (response, result: PointResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PointResponse) => response.result
    })
  })
})

export const { useGetPointsQuery } = PointApi
