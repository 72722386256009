import { styled, Typography } from '@mui/material'

export const S_Container = styled('div')(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'calc(100% - 60px)',
  paddingRight: '16px',
  paddingLeft: '16px',
  backgroundColor: props.theme.palette['Main-2']
}))

export const S_Heading = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '10px',
  padding: 0,
  textAlign: 'center',
  color: props.theme.palette['Main-1']
})) as typeof Typography

export const S_Sended = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '45px',
  padding: 0,
  color: props.theme.palette['Main-1'],
  textAlign: 'center',
  whiteSpace: 'pre-line'
})) as typeof Typography

export const S_Navigation = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'calc(100% - 32px)',
  position: 'absolute',
  bottom: '25px'
})

export const S_Seconds = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '20px',
  padding: 0,
  color: props.theme.palette['Sup-2']
})) as typeof Typography
