export const phoneRegExp = /^((8|\+7)[ \- ]?)?(\(?\d{3}\)?[ \- ]?)?[\d\- ]{7,10}$/
export const textRegExp = /^./g
export const formatDate = (value: string) => {
  const pattern = /(\d{1,2})\.(\d{1,2})\.(\d{1,4})/

  return value.replace(pattern, '$2/$1/$3')
}

export const getHumanMonth = (value: number) => {
  switch (value) {
    case 0:
      return 'января'
    case 1:
      return 'февраля'
    case 2:
      return 'марта'
    case 3:
      return 'апреля'
    case 4:
      return 'мая'
    case 5:
      return 'июня'
    case 6:
      return 'июля'
    case 7:
      return 'августа'
    case 8:
      return 'сентября'
    case 9:
      return 'октября'
    case 10:
      return 'ноября'
    case 11:
      return 'декабря'
  }
}

export const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  maximumFractionDigits: 0
})

export const numberSpaceFormatter = (number: number): string => number.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')

export const toNumber = (value: string | number) => {
  if (typeof value === 'number') return value
  return parseInt(value.replace(/[^\d]+/g, ''))
}

export const getRandomNumber = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
