// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography, Divider } from '@mui/material'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'

// ** Import api
import { useGetPointsQuery } from 'entities/point'

// ** Import utils
import { numberSpaceFormatter, getHumanMonth } from 'shared/utils'
import { isToday, isYesterday } from 'date-fns'

// ** Import components
import { ReactComponent as PointIcon } from './point-icon.svg'

const pronouncePoints = (value: number) => {
  switch (value) {
    case 1:
      return 'балл'
    case 2:
    case 3:
    case 4:
      return 'балла'
    default:
      return 'баллов'
  }
}

const pronounceDate = (value: string) => {
  const date = new Date(value)

  if (isToday(date)) {
    return 'Сегодня'
  }

  if (isYesterday(date)) {
    return 'Вчера'
  }

  return `${date.getDate()} ${getHumanMonth(date.getMonth())} ${date.getFullYear()}`
}

export const Points = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()

  const { data, isSuccess } = useGetPointsQuery()

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <>
          <TopNavigation header='Мои баллы' background={theme.palette['copper']} back={() => navigate(-1)} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: '1',
              backgroundColor: theme.palette['copper']
            }}
          >
            <Box
              sx={{
                paddingTop: '20px',
                paddingRight: '16px',
                paddingLeft: '16px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  border: `1px solid ${theme.palette['Main-1']}`,
                  borderRadius: '12px',
                  paddingRight: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: theme.palette['Main-2']
                }}
              >
                <Typography
                  variant='Text-1-B'
                  component='p'
                  sx={{
                    position: 'relative',
                    left: '-2px',
                    color: theme.palette['Main-2'],
                    backgroundColor: theme.palette['Main-1'],
                    paddingTop: '16px',
                    paddingRight: '10px',
                    paddingBottom: '16px',
                    paddingLeft: '10px',
                    borderRadius: '12px 0 0 12px'
                  }}
                >
                  Мои баллы
                </Typography>

                <Box sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}>
                  <Typography variant='Text-1-B' component='p' sx={{ color: theme.palette['Main-1'] }}>
                    {numberSpaceFormatter(data.points)}
                  </Typography>
                  <PointIcon />
                </Box>
              </Box>
            </Box>

            <Typography
              variant='Text-1-M'
              component='p'
              sx={{
                margin: '0 auto',
                marginTop: '15px',
                marginBottom: '15px',
                color: theme.palette['Corp-1'],
                opacity: '0.6'
              }}
            >
              {data.due.amount} {pronouncePoints(data.due.amount)} сгорит 
              {new Date(data.due.date).getDay() >= 10
                ? new Date(data.due.date).getDay()
                : `0${new Date(data.due.date).getDay()}`}
              .
              {new Date(data.due.date).getMonth() >= 10
                ? new Date(data.due.date).getMonth()
                : `0${new Date(data.due.date).getMonth()}`}
              .{new Date(data.due.date).getFullYear()}
            </Typography>

            <Box
              sx={{
                paddingTop: '16px',
                paddingRight: '16px',
                paddingBottom: '25px',
                paddingLeft: '16px',
                backgroundColor: theme.palette['Main-2'],
                borderRadius: '16px 16px 0 0',
                flexGrow: '1'
              }}
            >
              <Typography
                component='p'
                variant='Head-2-B'
                sx={{ color: theme.palette['Main-1'], marginBottom: '20px' }}
              >
                История
              </Typography>

              {data.history.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: '15px'
                  }}
                >
                  <Box>
                    <Typography
                      variant='Text-1-B'
                      component='p'
                      sx={{ color: theme.palette['Main-1'], marginBottom: '15px' }}
                    >
                      {pronounceDate(item.date)}
                    </Typography>
                  </Box>

                  {item.operations.map((operation, indx) => (
                    <Box
                      key={indx}
                      sx={{
                        position: 'relative',
                        paddingLeft: '15px',
                        marginBottom: '20px',
                        '&:before': {
                          position: 'absolute',
                          left: '0',
                          top: '8px',
                          width: '7px',
                          height: '1px',
                          backgroundColor: theme.palette['Main-1'],
                          content: '""'
                        }
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                          variant='Text-1-M'
                          component='p'
                          sx={{ color: theme.palette['Main-1'], marginBottom: '2px' }}
                        >
                          {operation.name}
                        </Typography>

                        <Typography
                          variant='Text-1-R'
                          component='p'
                          sx={{ color: operation.type === 'top-up' ? theme.palette.True : theme.palette['Main-1'] }}
                        >
                          {operation.type === 'top-up' ? '+' : '-'}
                          {operation.amount}
                        </Typography>
                      </Box>

                      <Typography variant='Text-2-R' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                        {operation.category}
                      </Typography>
                    </Box>
                  ))}

                  <Divider />
                </Box>
              ))}
            </Box>
          </Box>
        </>
      ) : null}
    </>
  )
}
