import { styled, Typography } from '@mui/material'

export const S_Container = styled('section')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px',
  marginRight: '16px',
  marginLeft: '16px',
  marginBottom: '20px',
  padding: 0
})

export const S_Manager = styled('section')({
  margin: 0,
  padding: 0,
  marginLeft: '10px'
})

export const S_ManagerName = styled(Typography)(props => ({
  margin: 0,
  padding: 0,
  color: props.theme.palette['Main-1']
})) as typeof Typography

export const S_ManagerMail = styled(Typography)(props => ({
  margin: 0,
  padding: 0,
  color: props.theme.palette['Sup-2'],
  textDecoration: 'none'
})) as typeof Typography

export const S_Copy = styled('button')({
  width: '18px',
  height: '18px',
  margin: 0,
  marginLeft: 'auto',
  padding: 0,
  border: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',

  '& svg': {
    display: 'block',
    width: '100%',
    height: 'auto'
  }
})

export const S_Slider = styled('div')(props => ({
  margin: 0,
  marginLeft: '16px',
  padding: 0,

  '& .swiper-pagination-bullet': {
    backgroundColor: props.theme.palette['Sup-2'],
    opacity: 1
  },
  '& .swiper-pagination-bullet-active': {
    backgroundColor: props.theme.palette['Corp-1']
  }
}))

export const S_Slide = styled('section')(props => ({
  margin: 0,
  padding: '15px',
  borderRadius: '10px',
  backgroundColor: props.theme.palette['Main-2']
}))

export const S_Text = styled(Typography)(props => ({
  margin: 0,
  padding: 0,
  color: props.theme.palette['Main-1']
})) as typeof Typography

export const S_Buttons = styled('div')({
  display: 'grid',
  rowGap: '10px',
  margin: 0,
  marginTop: '30px',
  padding: 0
})
