// ** react
import { useState } from 'react'

// ** react-router
import { useNavigate, useNavigation, useLoaderData } from 'react-router-dom'

// ** formik
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

// ** MUI Components
import {
  useTheme,
  Box,
  Typography,
  Button,
  Link,
  Dialog,
  DialogActions,
  TextField,
  Divider,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// ** Components
import { TopNavigation, Loader, DealPoint, PhoneInput } from 'shared/ui'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ChooseFile from './choose-file.svg'
import OkIcon from './ok-icon.svg'
import WrongIcon from './wrong-icon.svg'
import { ReactComponent as CheckIcon } from './check-icon.svg'

// ** API
import { useGetDetailAdvertQuery, useSendAdvertFormMutation } from 'entities/advert'

// ** Import utils
import { format } from 'date-fns'
import { phoneRegExp, textRegExp } from '../../shared/utils'

type FileFormInitialValues = {
  id: string
  phone: string
  text: string
  files: File[]
}

const validationSchema = Yup.object().shape({
  phone: Yup.string().matches(phoneRegExp).required(),
  text: Yup.string().matches(textRegExp).required(),
  files: Yup.mixed()
    .test('fileAmount', 'Too many files', value => {
      if (value && value.length > 3) {
        return false
      }
      return true
    })
    .test('fileSize', 'File Size is too large', value => {
      if (value && value?.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size > 10485760) {
            return false
          }
        }
      }
      return true
    })
  // .test('fileType', 'Unsupported File Format', value => {
  //   if (value && value.length > 0) {
  //     for (let i = 0; i < value.length; i++) {
  //       if (value[i].type !== 'image/png' && value[i].type !== 'image/jpg' && value[i].type !== 'image/jpeg') {
  //         return false
  //       }
  //     }
  //   }
  //   return true
  // })
})

const TEXT_LENGTH = 20

export const AdvertDetail = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { id } = useLoaderData() as { id: string }
  const { data, isSuccess } = useGetDetailAdvertQuery(id)
  const [sendData, { reset, data: submitData }] = useSendAdvertFormMutation()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openForm, setOpenForm] = useState<boolean>(false)

  const initialValues: FileFormInitialValues = {
    id: id,
    phone: '',
    text: '',
    files: []
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <>
          <TopNavigation
            header={data.header.length > TEXT_LENGTH ? `${data.header.slice(0, TEXT_LENGTH)}...` : data.header}
            back={() => {
              navigate(-1)
            }}
          />
          <Box
            sx={{
              paddingTop: '20px',
              paddingBottom: '20px',
              backgroundColor: theme.palette['Main-2'],

              '& .styles-module_content__2jwZj': {
                backgroundColor: '#000000'
              }
            }}
          >
            <Box sx={{ paddingLeft: '16px', paddingRight: '16px', marginBottom: '20px' }}>
              <Typography
                variant='Head-3-B'
                component='h2'
                sx={{
                  color: theme.palette['Main-1']
                }}
              >
                {data.header}
              </Typography>
            </Box>

            <Box
              sx={{
                height: '190px',
                overflow: 'hidden'
              }}
            >
              <img
                src={data.image}
                alt={'notification preview'}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </Box>

            <Box sx={{ paddingLeft: '16px', paddingRight: '16px', marginTop: '20px' }}>
              <DealPoint
                title='Срок действия акции:'
                description={`${data.from ? `C ${format(new Date(data.from), 'dd.MM.yyyy')}` : null} ${
                  data.from ? '\n по' : 'До'
                } ${format(new Date(data.to), 'dd.MM.yyyy')}`}
              />
              <DealPoint title='Могут участвовать:' description={data.viable} />
              <DealPoint title='Условие:' description={data['condition-1']} />
              <DealPoint title='Условие:' description={data['condition-2']} />
            </Box>

            <Typography
              variant='Text-1-R'
              component='p'
              dangerouslySetInnerHTML={{ __html: data.description }}
              sx={{
                color: theme.palette['Main-1'],
                marginTop: '20px',
                marginBottom: '20px',
                paddingRight: '16px',
                paddingLeft: '16px'
              }}
            />

            {data.link ? (
              <Button
                variant='outlined'
                component='a'
                href={data.link}
                sx={{
                  width: 'calc(100% - 32px)',
                  marginLeft: '16px'
                }}
              >
                Подробнее
              </Button>
            ) : null}

            {data.phone ? (
              <>
                <Button
                  variant='outlined'
                  sx={{
                    width: 'calc(100% - 32px)',
                    marginLeft: '16px'
                  }}
                  onClick={() => {
                    setOpenModal(true)
                  }}
                >
                  Оставить заявку
                </Button>

                <Typography
                  variant='Text-1-R'
                  component='p'
                  sx={{ color: theme.palette['Sup-2'], marginTop: '15px', marginBottom: '10px', textAlign: 'center' }}
                >
                  или
                </Typography>

                <Typography
                  variant='Text-1-R'
                  component='p'
                  sx={{ color: theme.palette['Main-1'], textAlign: 'center' }}
                >
                  Позвонить по телефону:
                </Typography>
                <Link href={`tel:+${data.phone.replace(/\D/g, '')}`} sx={{ textDecoration: 'none' }}>
                  <Typography
                    variant='Text-1-R'
                    component='p'
                    sx={{ color: theme.palette['Corp-1'], textAlign: 'center' }}
                  >
                    {data.phone}
                  </Typography>
                </Link>
              </>
            ) : null}
          </Box>
        </>
      ) : null}

      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '10px',
            margin: '16px'
          }
        }}
      >
        <Box sx={{ p: '20px' }}>
          <Typography
            sx={{
              mb: '20px'
            }}
            variant='Head-3-B'
            component='p'
          >
            Вы точно хотите оставить заявку?
          </Typography>

          <DialogActions sx={{ p: 0 }}>
            <Button
              aria-label='no'
              onClick={() => {
                setOpenModal(false)
              }}
              variant='outlined'
            >
              Нет
            </Button>
            <Button
              aria-label='yes'
              onClick={() => {
                setOpenModal(false)
                setOpenForm(true)
              }}
              variant='outlined'
            >
              Да
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={openForm}
        onClose={() => {
          setOpenForm(false)
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '10px',
            margin: '16px'
          }
        }}
      >
        <Box sx={{ p: '20px' }}>
          <IconButton
            aria-label='close'
            onClick={() => {
              setOpenForm(false)
            }}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '18px',
              height: '18px',
              backgroundColor: theme.palette['Sup-1'],

              '& .MuiSvgIcon-root': {
                fontSize: '0.6rem',
                color: theme.palette['Sup-2']
              }
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>

          <Typography
            sx={{
              mb: '20px'
            }}
            variant='Head-3-B'
            component='p'
          >
            Форма обратной связи
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={values => {
              sendData(values)
              setOpenForm(false)
            }}
          >
            {({ values, errors, touched, dirty, isValid, handleChange, handleBlur, setFieldValue, submitForm }) => (
              <>
                <Form noValidate style={{ position: 'relative' }}>
                  <PhoneInput
                    mask='+7 (999) 999-99-99'
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='phone'
                    error={errors.phone && touched.phone ? true : false}
                  />

                  <TextField
                    value={values.text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='text'
                    label='Введите сообщение'
                    multiline
                    rows={4}
                    error={errors.text && touched.text ? true : false}
                    style={{ margin: '20px 0 20px 0', padding: 0 }}
                  />
                </Form>

                <label
                  htmlFor='files'
                  style={{ display: 'flex', alignItems: 'flex-start', columnGap: '5px', marginBottom: '16px' }}
                >
                  <img src={ChooseFile} alt='choose-file' />
                  <Box>
                    <Typography
                      variant='Text-1-R'
                      component='p'
                      sx={{
                        color: theme.palette['Main-1']
                      }}
                    >
                      Прикрепить файлы <span style={{ color: theme.palette['Sup-2'] }}>(не более 3-х)</span>
                    </Typography>
                    <Typography variant='Small-text-1-R' component='p' sx={{ color: 'Sup-2' }}>
                      Допускаются файлы в формате: jpeg, gif, png, pdf, doc, docx; Размер не более 10 MB.
                    </Typography>
                  </Box>
                </label>
                <input
                  style={{ display: 'none' }}
                  id='files'
                  name='files'
                  type='file'
                  multiple
                  accept='.png,.jpeg,.gif,.pdf,.doc,.doxc,.docx'
                  onChange={event => {
                    if (event.currentTarget.files) {
                      const fileArray = Array.from(event.currentTarget.files)

                      const newFiles: File[] = []
                      fileArray.forEach((file: File) => {
                        if (values.files.findIndex((item: File) => item.name === file.name) === -1) {
                          newFiles.push(file)
                        }
                      })
                      setFieldValue('files', values.files.concat(newFiles))
                    }
                  }}
                />

                {values.files.length !== 0
                  ? values.files.map((file, index) => (
                      <Box key={index}>
                        {index !== 0 ? <Divider sx={{ marginTop: '10px' }} /> : null}

                        <Box
                          sx={{
                            display: 'flex',
                            columnGap: '10px',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            marginTop: '10px'
                          }}
                        >
                          <Box>
                            <Typography variant='Text-2-R' component='p' sx={{ color: theme.palette['Main-1'] }}>
                              {file.name}
                            </Typography>

                            <Box sx={{ display: 'flex', columnGap: '5px' }}>
                              <img src={file.size < 10485760 ? OkIcon : WrongIcon} alt='status-icon' />
                              <Typography
                                variant='Small-text-1-R'
                                component='p'
                                sx={{ color: file.size > 10485760 ? theme.palette.False : theme.palette['Sup-2'] }}
                              >
                                {file.size > 10485760
                                  ? 'Недопустимый файл'
                                  : file.size > 1048576
                                  ? `${Math.round((file.size * 100) / 1048576) / 100} MB`
                                  : file.size > 1024
                                  ? `${Math.round((file.size * 100) / 1024) / 100} KB`
                                  : `${file.size} B`}
                              </Typography>
                            </Box>
                          </Box>
                          <IconButton
                            size='small'
                            onClick={() => {
                              const updatedFiles = [...values.files].filter(item => file.name !== item.name)
                              setFieldValue('files', updatedFiles)
                            }}
                          >
                            <CloseRoundedIcon fontSize='inherit' />
                          </IconButton>
                        </Box>
                      </Box>
                    ))
                  : null}

                <Box sx={{ mt: 'auto', marginTop: values.files.length > 0 ? '0px' : '9px' }}>
                  <Button
                    variant='outlined'
                    onClick={submitForm}
                    aria-label='send message'
                    disabled={!(isValid && dirty)}
                    sx={{ marginTop: '20px' }}
                  >
                    Отправить сообщение
                  </Button>
                </Box>
              </>
            )}
          </Formik>
        </Box>
      </Dialog>

      <Snackbar
        open={submitData === 'success' ? true : false}
        onClose={reset}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<CheckIcon fontSize='inherit' />}
          sx={{
            '& .MuiAlert-message': {
              color: '#FFFFFF',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          Сообщение отправлено!
        </Alert>
      </Snackbar>
    </>
  )
}
