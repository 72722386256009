// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import Formik
import { Formik, Form } from 'formik'

// ** Import MUI components
import { useTheme, Box, Typography, Divider, Switch } from '@mui/material'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'

// ** Import api
import { useGetSettingsQuery, useSendSettingsMutation } from 'entities/settings'

export const Settings = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { data, isSuccess } = useGetSettingsQuery()
  const [sendSettings] = useSendSettingsMutation()

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <Box
          sx={{
            background: theme.palette['Main-2'],
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            paddingRight: '16px',
            paddingLeft: '16px'
          }}
        >
          <TopNavigation header='Уведомления' back={() => navigate(-1)} />

          <Formik
            initialValues={{
              dealsPush: data.deals.push,
              dealsEmail: data.deals.email,
              dealsSms: data.deals.sms,
              chatPush: data.chat.push,
              chatEmail: data.chat.email,
              chatSms: data.chat.sms,
              categoryPush: data.category.push,
              categoryEmail: data.category.email,
              categorySms: data.category.sms
            }}
            onSubmit={values => {
              sendSettings(values)
            }}
          >
            {({ values, setFieldValue, submitForm }) => (
              <Form>
                <Typography variant='Head-2-B' component='p' sx={{ color: theme.palette['Main-1'], marginTop: '20px' }}>
                  Уведомления по сделкам
                </Typography>

                <Divider sx={{ marginTop: '20px' }} />

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    Push-уведомления
                  </Typography>
                  <Switch
                    checked={values.dealsPush}
                    onChange={() => {
                      setFieldValue('dealsPush', !values.dealsPush)
                      submitForm()
                    }}
                  />
                </Box>

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    E-mail
                  </Typography>
                  <Switch
                    checked={values.dealsEmail}
                    onChange={() => {
                      setFieldValue('dealsEmail', !values.dealsEmail)
                      submitForm()
                    }}
                  />
                </Box>

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    СМС
                  </Typography>
                  <Switch
                    checked={values.dealsSms}
                    onChange={() => {
                      setFieldValue('dealsSms', !values.dealsSms)
                      submitForm()
                    }}
                  />
                </Box>

                <Typography variant='Head-2-B' component='p' sx={{ color: theme.palette['Main-1'], marginTop: '20px' }}>
                  Ответы в чате
                </Typography>

                <Divider sx={{ marginTop: '20px' }} />

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    Push-уведомления
                  </Typography>
                  <Switch
                    checked={values.chatPush}
                    onChange={() => {
                      setFieldValue('chatPush', !values.chatPush)
                      submitForm()
                    }}
                  />
                </Box>

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    E-mail
                  </Typography>
                  <Switch
                    checked={values.chatEmail}
                    onChange={() => {
                      setFieldValue('chatEmail', !values.chatEmail)
                      submitForm()
                    }}
                  />
                </Box>

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    СМС
                  </Typography>
                  <Switch
                    checked={values.chatSms}
                    onChange={() => {
                      setFieldValue('chatSms', !values.chatSms)
                      submitForm()
                    }}
                  />
                </Box>

                <Typography variant='Head-2-B' component='p' sx={{ color: theme.palette['Main-1'], marginTop: '20px' }}>
                  Наименование категории
                </Typography>

                <Divider sx={{ marginTop: '20px' }} />

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    Push-уведомления
                  </Typography>
                  <Switch
                    checked={values.categoryPush}
                    onChange={() => {
                      setFieldValue('categoryPush', !values.categoryPush)
                      submitForm()
                    }}
                  />
                </Box>

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    E-mail
                  </Typography>
                  <Switch
                    checked={values.categoryEmail}
                    onChange={() => {
                      setFieldValue('categoryEmail', !values.categoryEmail)
                      submitForm()
                    }}
                  />
                </Box>

                <Box sx={{ display: 'flex', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between' }}>
                  <Typography variant='Text-1-R' component='p'>
                    СМС
                  </Typography>
                  <Switch
                    checked={values.categorySms}
                    onChange={() => {
                      setFieldValue('categorySms', !values.categorySms)
                      submitForm()
                    }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      ) : null}
    </>
  )
}
