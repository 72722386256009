import { configureStore, combineReducers } from '@reduxjs/toolkit'

import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

// reducers
import { metaReducer } from 'entities/meta'
import { profileReducer } from 'entities/profile'

//middlewares
import { redirectMiddleware } from './redirectMiddleware'

// base api
import { baseApi } from 'shared/api'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: [baseApi.reducerPath]
}

const rootReducer = combineReducers({
  meta: metaReducer,
  profile: profileReducer,
  [baseApi.reducerPath]: baseApi.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(redirectMiddleware, baseApi.middleware)
})

export type AppStoreType = typeof store

export const persistor = persistStore(store)
