import { ENV } from 'env'
import type { NotificationResponse, NotificationData } from './types.js'
import { baseApi } from 'shared/api'

import { formatDistanceToNow } from 'date-fns'
import { ru } from 'date-fns/locale'

export const NotificationApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getNotificationData: build.query<NotificationData[], void>({
      query: () => ({
        url: `${window.routes5.notification.requests.getNotifications[`url${ENV}`]}`,
        params: {
          action: 'getNotifications'
        },
        credentials: 'include',
        validateStatus: (response, result: NotificationResponse) => response.status === 200 && result.status !== 'error'
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'Notifications' as const, id })), { type: 'Notifications', id: 'LIST' }]
          : [{ type: 'Notifications', id: 'LIST' }],
      transformResponse: (response: NotificationResponse) => {
        const transformedResponse = [] as NotificationData[]

        response.result.forEach(notification => {
          notification.date = formatDistanceToNow(new Date(notification.date), {
            locale: ru,
            includeSeconds: true,
            addSuffix: true
          })

          transformedResponse.push(notification)
        })

        return transformedResponse
      }
    }),
    markAsRead: build.mutation<NotificationData[], string>({
      query: id => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'update_notifications')
        bodyFormData.append('id', id)
        return {
          url: `${window.routes5.notification.requests.markAsRead[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: NotificationResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      invalidatesTags: [{ type: 'Notifications', id: 'LIST' }]
      // transformResponse: (response: NotificationResponse) => {
      //   const transformedResponse = [] as NotificationData[]

      //   response.result.forEach(notification => {
      //     notification.date = formatDistanceToNow(new Date(notification.date), {
      //       locale: ru,
      //       includeSeconds: true,
      //       addSuffix: true
      //     })

      //     transformedResponse.push(notification)
      //   })

      //   return transformedResponse
      // }
    }),
    markAllAsRead: build.mutation<NotificationData[], void>({
      query: () => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'update_all_notifications')
        return {
          url: `${window.routes5.notification.requests.markAllAsRead[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: NotificationResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      invalidatesTags: [{ type: 'Notifications', id: 'LIST' }]
      // transformResponse: (response: NotificationResponse) => {
      //   const transformedResponse = [] as NotificationData[]

      //   response.result.forEach(notification => {
      //     notification.date = formatDistanceToNow(new Date(notification.date), {
      //       locale: ru,
      //       includeSeconds: true,
      //       addSuffix: true
      //     })

      //     transformedResponse.push(notification)
      //   })

      //   return transformedResponse
      // }
    })
  })
})

export const { useGetNotificationDataQuery, useMarkAsReadMutation, useMarkAllAsReadMutation } = NotificationApi
