// ** Import MUI components
import { Box } from '@mui/material'

// ** Import components
import { PictureSlider } from '../PictureSlider/PictureSlider'

// ** Import styles
import { S_Container, S_Status, S_Price, S_Name, S_Meta } from './Deal.styled'

export type DealProps = {
  id: string
  contract: string
  model?: string
  price: string
  duration: string
  date: string
  statusText: string
  statusColor: string
  images: string[]
  navigationRoute: string
  type?: 'Заявка' | 'Договор'
}

export const Deal = ({
  id,
  contract,
  model,
  price,
  duration,
  date,
  statusText,
  statusColor,
  images,
  navigationRoute,
  type = 'Договор'
}: DealProps): JSX.Element => {
  return (
    <S_Container to={navigationRoute}>
      <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <S_Status variant='Small-text-1-M' component='p' color={`#${statusColor}`}>
          {statusText}
        </S_Status>
        <S_Price variant='Head-3-B' component='p'>
          {price}
        </S_Price>
        {model ? (
          <S_Name variant='Text-1-M' component='p'>
            {model}
          </S_Name>
        ) : null}

        <S_Meta variant='Text-2-M' component='p'>
          {duration}
        </S_Meta>
        <S_Meta variant='Text-2-M' component='p'>
          {type} № {contract} от {date}
        </S_Meta>
      </Box>

      {images && images.length !== 0 ? (
        <Box
          sx={{
            marginTop: '10px',
            paddingLeft: '16px'
          }}
        >
          <PictureSlider slides={images} isViewer={false} />
        </Box>
      ) : null}
    </S_Container>
  )
}
