import { Box, styled, Typography } from '@mui/material'

export const S_PointWrapp = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',

  '&:last-child': {
    marginBottom: 0
  }
})

export const S_PointTitle = styled(Typography)(props => ({
  color: props.theme.palette['Sup-2'],
  width: '60%',
  marginRight: '20px',
  whiteSpace: 'nowrap'
})) as typeof Typography

export const S_PointDescription = styled(Typography)(props => ({
  color: props.theme.palette['Main-1'],
  width: '40%',
  whiteSpace: 'pre-line'
})) as typeof Typography

export const S_PointLink = styled('a')(props => ({
  color: props.theme.palette['Corp-1'],
  width: '40%',
  whiteSpace: 'nowrap',
  textDecoration: 'none '
}))
