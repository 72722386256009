import { ENV } from 'env'
import type { PercentagesResponse, CalculatorData } from './types.js'
import { baseApi } from 'shared/api'

export const CalculatorApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getPercentages: build.query<PercentagesResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.calculator.requests.getPercentages[`url${ENV}`]}`,
          params: {
            action: 'getPercentages'
          },
          validateStatus: (response, result: PercentagesResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PercentagesResponse) => response.result
    }),
    sendCalculationRequest: build.mutation<'error' | 'success', CalculatorData>({
      query: values => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'calculationRequest')
        bodyFormData.append('totalPrice', values.totalPrice.toString())
        bodyFormData.append('initialDeposit', values.initialDeposit.toString())
        bodyFormData.append('initialDepositPercentage', values.initialDepositPercentage.toString())
        bodyFormData.append('leasingDuration', values.leasingDuration.toString())
        bodyFormData.append('interestRateOne', values.interestRateOne.toString())
        bodyFormData.append('interestRateTwo', values.interestRateTwo.toString())
        bodyFormData.append('interestRateThree', values.interestRateThree.toString())
        bodyFormData.append('monthlyPayment', values.monthlyPayment.toString())
        bodyFormData.append('VATpart', values.VATpart.toString())
        bodyFormData.append('yearAppreciation', values.yearAppreciation.toString())
        bodyFormData.append('id', values.id ? values.id.toString() : 'null')
        bodyFormData.append('company_id', `${values.defaultCompanyID}`)
        bodyFormData.append('inn', values.inn)
        bodyFormData.append('name', values.name)
        bodyFormData.append('phone', values.phone)
        bodyFormData.append('email', values.email)

        return {
          url: `${window.routes5.calculator.requests.sendCalculationRequest[`url${ENV}`]}`,
          method: 'POST',
          credentials: 'include',
          body: bodyFormData,
          validateStatus: (response, result: { status: 'success' | 'innFindInUser' }) =>
            response.status === 200 && result.status !== 'innFindInUser'
        }
      },
      transformResponse: (response: { status: 'error' | 'success' }) => response.status
    })
  })
})

export const { useGetPercentagesQuery, useSendCalculationRequestMutation } = CalculatorApi
