// ** Import react-input-mask
import InputMask, { Props } from 'react-input-mask'

// ** Import MUI components
import { TextField } from '@mui/material'

interface ExtendedProps extends Props {
  error: boolean
  readOnly?: boolean
  disabled?: boolean
}

export const PhoneInput = (props: ExtendedProps): JSX.Element => {
  return (
    <InputMask
      mask='+7 999 999-99-99'
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      readOnly={props.readOnly}
    >
      <TextField
        error={props.error}
        InputProps={{
          readOnly: props.readOnly,
          disabled: props.disabled,
          inputMode: 'numeric',
          type: 'tel'
        }}
        label='Номер телефона'
      />
    </InputMask>
  )
}
