// ** Import react
import { useState } from 'react'

// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'

// ** Import api
import { useGetProgramQuery } from 'entities/program'

export const Program = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { data, isSuccess } = useGetProgramQuery()

  const [expanded, setExpanded] = useState<string | false>(data ? data[0].header : false)

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <>
          <TopNavigation header='О программе' back={() => navigate(-1)} />{' '}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: '1',
              backgroundColor: theme.palette['Main-2'],
              paddingTop: '20px',
              paddingRight: '16px',
              paddingLeft: '16px'
            }}
          >
            <Typography variant='Text-2-R' component='p' sx={{ color: theme.palette['Main-1'], marginBottom: '10px' }}>
              Настоящая Программа предназначена для повышения лояльности Клиентов к продуктам Компании, мотивации
              Клиентов на совершение большего количества операций по оформлению заявок на лизинг.
            </Typography>

            <Typography variant='Text-2-R' component='p' sx={{ color: theme.palette['Main-1'], marginBottom: '20px' }}>
              В рамках Программы Компания начисляет Клиенту бонусы за совершенные активности с использованием мобильного
              приложения. Бонусы начисляются в виртуальной валюте в формате: <b>1 Бонус = 1 виртуальный рубль.</b>
            </Typography>

            {data.map((question, index) => (
              <Accordion
                key={index}
                expanded={expanded === question.header}
                onChange={handleAccordionChange(question.header)}
                square={true}
                sx={{
                  padding: 0,
                  boxShadow: 'none',
                  borderBottom: `1px solid ${theme.palette['Sup-1']}`,

                  '&.Mui-expanded': {
                    borderBottom: `1px solid ${theme.palette['Corp-1']}`,
                    marginBottom: 0
                  },

                  '&:before': {
                    display: 'none'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    padding: 0,
                    minHeight: '28px',
                    color: theme.palette['Main-1'],

                    '& .MuiSvgIcon-root': {
                      fill: theme.palette['Main-1']
                    },

                    '& .MuiAccordionSummary-content': {
                      marginTop: '15px',
                      marginBottom: '15px'
                    },

                    '&.Mui-expanded': {
                      color: theme.palette['Corp-1'],
                      minHeight: '28px',

                      '& .MuiSvgIcon-root': {
                        fill: theme.palette['Corp-1']
                      },

                      '& .MuiAccordionSummary-content': {
                        margin: '12px 0'
                      }
                    }
                  }}
                >
                  <Typography variant='Text-1-B'>{question.header}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingTop: '0px',
                    paddingRight: '30px',
                    paddingBottom: '15px',
                    paddingLeft: '0px'
                  }}
                >
                  <Typography>{question.text}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </>
      ) : null}
    </>
  )
}
