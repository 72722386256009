import { ENV } from 'env'
import type { ProgramResponse } from './types'
import { baseApi } from 'shared/api'

export const ProgramApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getProgram: build.query<ProgramResponse['result'], void>({
      query: () => ({
        url: `${window.routes5.program.requests.getProgram[`url${ENV}`]}`,
        params: {
          action: 'getProgram'
        },
        validateStatus: (response, result: ProgramResponse) => response.status === 200 && result.status !== 'error'
      }),
      transformResponse: (response: ProgramResponse) => response.result
    })
  })
})

export const { useGetProgramQuery } = ProgramApi
