import { styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const S_Container = styled(Link)(props => ({
  position: 'relative',
  display: 'block',
  margin: 0,
  padding: 0,
  backgroundColor: `1px solid ${props.theme.palette['Main-2']}`,
  textDecoration: 'none',

  '&:not(:first-of-type)': {
    position: 'relative',
    marginTop: '20px',
    paddingTop: '15px',

    '&:after': {
      position: 'absolute',
      top: '0',
      left: '16px',
      width: 'calc(100% - 32px)',
      height: '1px',
      backgroundColor: props.theme.palette['Sup-1'],
      transform: 'rotate(0)',
      content: '""'
    }
  },

  '&:first-of-type': {
    borderTop: 0
  },
  '&:last-of-type': {
    borderBottom: 0
  },
  '&:before': {
    position: 'absolute',
    top: '25px',
    right: '20px',
    width: '9px',
    height: '9px',
    borderTop: `2px solid ${props.theme.palette['Main-1']}`,
    borderRight: `2px solid ${props.theme.palette['Main-1']}`,
    transform: 'rotate(45deg)',
    content: "''"
  }
}))

export const S_Status = styled(Typography, { shouldForwardProp: prop => prop !== 'color' })<{ color: string }>(
  props => ({
    display: 'inline-block',
    margin: 0,
    marginBottom: '10px',
    padding: 0,
    paddingTop: '2px',
    paddingRight: '8px',
    paddingBottom: '2px',
    paddingLeft: '8px',
    borderRadius: '4px',
    backgroundColor: props.color,
    color: props.theme.palette['Main-1']
  })
) as typeof Typography

export const S_Price = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '2px',
  padding: 0,
  color: props.theme.palette['Main-1']
})) as typeof Typography

export const S_Name = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '4px',
  padding: 0,
  color: props.theme.palette['Main-1']
})) as typeof Typography

export const S_Meta = styled(Typography)(props => ({
  margin: 0,
  marginTop: '4px',
  marginBottom: '4px',
  padding: 0,
  color: props.theme.palette['Sup-2'],

  '&:last-of-type': {
    marginBottom: 0
  }
})) as typeof Typography
