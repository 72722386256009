// ** Import react-router
import { useNavigate, useNavigation } from 'react-router-dom'

// ** Import yup
import * as Yup from 'yup'

// ** Import Formik
import { Formik, Form } from 'formik'

// ** Import MUI components
import { useTheme, FormControl, Box, Alert, Button, TextField } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'

// ** Import components
import { TopNavigation, PhoneInput, Loader } from 'shared/ui'
import { ReactComponent as CheckIcon } from './check-icon.svg'

// ** Import utils
import { phoneRegExp } from 'shared/utils'

// ** Import api
import { useSendFeedbackMessageMutation } from 'features/feedback'

const validationSchema = Yup.object().shape({
  phone: Yup.string().matches(phoneRegExp).required(),
  text: Yup.string().required().min(10).max(1000)
})

export const ManagerMessage = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const [sendFeedBackMessage, { isLoading, isSuccess, isError, reset }] = useSendFeedbackMessageMutation()

  return (
    <>
      {navigation.state === 'loading' || isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <TopNavigation
        header='Сообщение менеджеру'
        back={() => {
          navigate(-1)
        }}
      />
      <FormControl
        sx={{
          background: theme.palette['Main-2'],
          padding: '20px 16px',
          height: 'calc(100% - 60px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Formik
          initialValues={{ phone: '', text: '' }}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={(values, { resetForm }) => {
            sendFeedBackMessage({ phone: values.phone, text: values.text })
              .unwrap()
              .then(payload => {
                resetForm()
              })
              .catch(() => {})
          }}
        >
          {({ values, errors, touched, dirty, isValid, handleChange, submitForm }) => (
            <>
              <Form noValidate style={{ position: 'relative' }}>
                <PhoneInput
                  mask='+7 (999) 999-99-99'
                  value={values.phone}
                  onChange={handleChange}
                  name='phone'
                  error={errors.phone && touched.phone ? true : false}
                />

                <TextField
                  value={values.text}
                  onChange={handleChange}
                  name='text'
                  label='Сообщение'
                  multiline
                  rows={8}
                  error={errors.text && touched.text ? true : false}
                  style={{ marginTop: '13px' }}
                />
              </Form>

              <Box sx={{ mt: 'auto' }}>
                <Button
                  variant='outlined'
                  onClick={submitForm}
                  aria-label='send message'
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Отправить сообщение
                </Button>
              </Box>
            </>
          )}
        </Formik>
      </FormControl>

      <Snackbar
        open={isSuccess}
        onClose={reset}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<CheckIcon fontSize='inherit' />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          Сообщение отправлено!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isError}
        onClose={reset}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={false}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          Произошла ошибка!
        </Alert>
      </Snackbar>
    </>
  )
}
