import type { MoreProps } from 'shared/@types'

// ** Import react
import { useEffect } from 'react'

// ** Import react-router
import { useNavigate, useNavigation } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box } from '@mui/material'

// ** Import components
import { Loader, MoreItem, TopNavigation } from 'shared/ui'

export const HelpArray = [
  // { id: 1, href: '/more/help/useful-info', icon: 'empty', title: 'Полезные статьи' },
  { id: 2, href: '/more/help/faq', icon: 'empty', title: 'Вопрос/Ответ' }
] as MoreProps[]

export const Help = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()

  useEffect(() => {
    const jivoChat = document.createElement('script')
    jivoChat.src = '//code.jivo.ru/widget/94kOqhJVct'
    // jivoChat.async = true
    document.body.appendChild(jivoChat)
    return () => {
      document.body.removeChild(jivoChat)
      document.querySelector('#jivo-iframe-container')?.remove()
      document.querySelector('jdiv')?.remove()
    }
  }, [])

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <TopNavigation
        header='Помощь'
        back={() => {
          navigate(-1)
        }}
      />

      <Box sx={{ background: theme.palette['Main-2'], padding: '20px 16px 0 20px', height: 'calc(100% - 60px)' }}>
        {HelpArray.map(item => (
          <MoreItem key={item.id} {...item} />
        ))}
      </Box>
    </>
  )
}
