// ** Import MUI components
import { Box, styled, Typography } from '@mui/material'

// ** Import react-router-dom
import { Link } from 'react-router-dom'

export const CardWrapp = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '20px',
  '&:last-child': {
    marginBottom: 0
  }
})

export const CardContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column'
})

export const CardLink = styled(Link)({
  width: '100%',
  textDecoration: 'none',
  color: 'inherit'
})

export const CardTitle = styled(Typography)(props => ({
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '19px',
  color: props.theme.palette['Main-1'],
  marginBottom: '5px'
})) as typeof Typography

export const CardDate = styled(Typography)(props => ({
  fontWeight: 500,
  fontSize: '0.75rem',
  lineHeight: '15px',
  color: props.theme.palette['Sup-2']
})) as typeof Typography
