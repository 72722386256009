// ** Import redux
import { useAppDispatch } from '../../shared/hooks'

// ** Import react-router-dom
import { Link, useNavigate, useNavigation } from 'react-router-dom'

// ** Import formik
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// ** Import MUI components
import { Alert, Typography, Button, Box, Snackbar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

// ** Import components
import { PhoneInput, TopNavigation, Loader } from 'shared/ui'

// ** Import styles
import {
  S_Container,
  S_Heading,
  S_Legal,
  S_Navigation,
  S_SkipLink,
  S_Wrapper,
  S_Snackbar,
  S_ErrorOutlineIcon,
  S_SnackbarText,
  S_SnackbarLink
} from './Authentication.styled'

// ** Import api
import { useSendPhoneMutation } from 'entities/auth'
import { setAuthPhone } from 'entities/meta'

// ** Import utils
import { phoneRegExp } from '../../shared/utils'

const validationSchema = Yup.object().shape({
  phone: Yup.string().matches(phoneRegExp).required()
})

export const Authentication = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const [sendPhone, { data, error, reset, isLoading, isError }] = useSendPhoneMutation()

  return (
    <>
      {isLoading || navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <S_Wrapper>
        <S_Snackbar
          autoHideDuration={3000}
          onClose={reset}
          open={isError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity='error'>
            {error as string}{' '}
            <S_SnackbarText variant='Text-2-R' component='p'>
              Номер телефона менеджера 
              <S_SnackbarLink href='tel:+78005000352'>8 800 500 03 52</S_SnackbarLink>
            </S_SnackbarText>
          </Alert>
        </S_Snackbar>

        <TopNavigation header='Авторизация' />

        <S_Container>
          <S_Heading variant='Head-2-B' component='h2'>
            Укажите телефон
          </S_Heading>

          <Formik
            initialValues={{ phone: '' }}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={values => {
              dispatch(setAuthPhone(values.phone))
              sendPhone(values.phone)
                .unwrap()
                .then(res => {
                  if (res.type === 'sent_sms') {
                    navigate('/sms')
                  } else if (res.type === 'auth_by_pin') {
                    navigate('/pin')
                  }
                })
            }}
          >
            {({ handleChange, submitForm, values, touched, errors, dirty, isValid }) => (
              <>
                <Form noValidate>
                  <PhoneInput
                    mask='+7 (999) 999-99-99'
                    value={values.phone}
                    onChange={handleChange}
                    name='phone'
                    error={errors.phone && touched.phone ? true : false}
                  />
                </Form>

                <S_Legal variant='Small-text-1-R' component='p'>
                  Продолжая, вы соглашаетесь с <Link to='/more/info'>обработкой персональных данных</Link>
                </S_Legal>

                <S_Navigation>
                  <Button
                    disabled={!(isValid && dirty)}
                    type='submit'
                    variant='outlined'
                    onClick={submitForm}
                    aria-label='send code'
                  >
                    Получить код
                  </Button>

                  <S_SkipLink to='/' component={Link}>
                    <Typography variant='Text-1-M'>Пропустить авторизацию</Typography>
                  </S_SkipLink>
                </S_Navigation>
              </>
            )}
          </Formik>
        </S_Container>
      </S_Wrapper>

      <Snackbar
        open={data?.type === 'reg'}
        action={
          <div
            style={{
              margin: 0,
              padding: 0,
              border: 0
            }}
          >
            <IconButton
              aria-label='close'
              color='inherit'
              onClick={reset}
              sx={{
                width: '18px',
                height: '18px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',

                '& .MuiSvgIcon-root': {
                  fontSize: '0.6rem'
                }
              }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
        }
        message={
          <>
            <S_ErrorOutlineIcon />
            <S_SnackbarText variant='Text-2-R' component='p'>
              <span style={{ display: 'block' }}>Номер не найден!</span>
              Для регистрации в приложении свяжитесь с менеджером по номеру 
              <S_SnackbarLink href='tel:+78005000352'>8 800 500 03 52</S_SnackbarLink>
            </S_SnackbarText>
          </>
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          bottom: '135px',
          maxWidth: '425px',
          '& .MuiPaper-root': {
            position: 'relative',
            borderRadius: '10px',
            paddingTop: '10px',
            paddingRight: '42px',
            paddingBottom: '15px',
            paddingLeft: '36px',
            backgroundColor: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(15px)'
          },

          '& .MuiSnackbarContent-message': {
            padding: '0'
          },

          '& .MuiSnackbarContent-action': {
            paddingLeft: 0,
            marginRight: 0,
            position: 'absolute',
            top: '8px',
            right: '8px'
          }
        }}
      />
    </>
  )
}
