// ** Import MUI components
import { useTheme, Dialog, Box, Typography, DialogActions, Alert, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

// ** Import components
import { Loader } from 'shared/ui'
import { ReactComponent as CheckIcon } from './check-icon.svg'

// ** Import styles
import { S_Snackbar } from './ModalInsurance.styled'

// ** Import api
import { useSendInsuranceCaseMutation } from 'entities/request'

type ModalInsuranceProps = {
  openModal: boolean
  setOpenModal: (arg0: boolean) => void
  id: string
  companyId: number
}

export const ModalInsurance = ({ openModal, setOpenModal, id, companyId }: ModalInsuranceProps): JSX.Element => {
  const theme = useTheme()
  const [sendInsuranceCase, { isError, isSuccess, isLoading, reset }] = useSendInsuranceCaseMutation()

  const handleClose = () => {
    setOpenModal(!openModal)
  }

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <S_Snackbar
        open={isError || isSuccess}
        autoHideDuration={3000}
        onClose={reset}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <div
            style={{
              margin: 0,
              padding: 0,
              border: 0
            }}
          >
            <IconButton
              aria-label='close'
              color='inherit'
              onClick={() => {}}
              sx={{
                width: '24px',
                height: '24px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',

                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
        }
      >
        <Alert
          icon={<CheckIcon fontSize='inherit' />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          {isSuccess ? 'Сообщение отправлено!' : null}
          {isError ? 'Произошла ошибка' : null}
        </Alert>
      </S_Snackbar>

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '10px',
            margin: '16px'
          }
        }}
      >
        <Box sx={{ p: '20px' }}>
          <Typography
            sx={{
              mb: '20px'
            }}
            variant='Head-3-B'
            component='p'
          >
            Вы точно хотите сообщить о страховом случае?
          </Typography>

          <DialogActions sx={{ p: 0 }}>
            <Button aria-label='no' onClick={handleClose} variant='outlined'>
              Нет
            </Button>
            <Button
              aria-label='yes'
              onClick={() => {
                handleClose()
                sendInsuranceCase({ id: id, companyId: companyId })
              }}
              variant='contained'
            >
              Да
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
