import { OnboardingUI } from 'entities/onboarding'
import { useNavigate } from 'react-router-dom'

export const Onboarding = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <OnboardingUI
      start={() => {
        navigate('/authentication')
      }}
    />
  )
}
