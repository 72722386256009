import type { StateTypes } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: StateTypes = {
  isAuth: false,
  isAnimationPlayed: false,
  isAdvertShown: false,
  authPhone: undefined,
  smsType: undefined,
  dealsPage: 'deals',
  currentArticlesPage: 'news',
  newsPage: 'actualYear'
}

export const metaSlice = createSlice({
  name: 'meta',
  initialState: initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
    setIsAnimationPlayed: (state, action: PayloadAction<boolean>) => {
      state.isAnimationPlayed = action.payload
    },
    setIsAdvertShown: (state, action: PayloadAction<boolean>) => {
      state.isAdvertShown = action.payload
    },
    setAuthPhone: (state, action: PayloadAction<string | undefined>) => {
      state.authPhone = action.payload
    },
    setSmsType: (state, action: PayloadAction<'bonus' | undefined>) => {
      state.smsType = action.payload
    },
    setDealsPageTab: (state, action: PayloadAction<'deals' | 'requests'>) => {
      state.dealsPage = action.payload
    },
    setCurrentArticlesPage: (state, action: PayloadAction<'news' | 'usefulNews'>) => {
      state.currentArticlesPage = action.payload
    },
    setNewsPage: (state, action: PayloadAction<'actualYear' | 'archive' | 'lastYear'>) => {
      state.newsPage = action.payload
    },
    resetMeta: state =>
      (state = {
        isAuth: false,
        isAnimationPlayed: true,
        isAdvertShown: false,
        authPhone: undefined,
        smsType: undefined,
        dealsPage: 'deals',
        currentArticlesPage: 'news',
        newsPage: 'actualYear'
      })
  }
})

export const {
  setIsAuth,
  setIsAnimationPlayed,
  setAuthPhone,
  setIsAdvertShown,
  setDealsPageTab,
  setCurrentArticlesPage,
  setNewsPage,
  setSmsType,
  resetMeta
} = metaSlice.actions

export default metaSlice.reducer
