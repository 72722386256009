// ** Import react-router-dom
import { Outlet } from 'react-router-dom'

// ** Import components
import { S_SecondLayout, S_Outlet } from './SecondLayout.styled'

export const SecondLayout = (): JSX.Element => {
  return (
    <S_SecondLayout>
      <S_Outlet>
        <Outlet />
      </S_Outlet>
    </S_SecondLayout>
  )
}
