// ** Import react
import { useState, useEffect } from 'react'

// ** Import redux
import { useAppSelector } from '../../shared/hooks'

// ** Import react-router-dom
import { useNavigate } from 'react-router-dom'

// ** Import formik
import { Formik, Form } from 'formik'

// ** Import MUI components
import { useTheme, Alert, Button, Box, Snackbar } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// ** Import components
import { TopNavigation, Code, Loader } from 'shared/ui'

// ** Import styles
import { S_Container, S_Heading, S_Navigation, S_Seconds, S_Sended } from './Sms.styled'

// ** Import api
import { useSendSmsMutation, useSendPhoneMutation } from 'entities/auth'
import { useSendBonusPhoneMutation, useSendBonusSmsMutation } from 'entities/bonus'

export const Sms = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const smsType = useAppSelector(state => state.meta.smsType)
  const userPhone = useAppSelector(state => state.meta.authPhone)
  const [sendAuthSms, { isLoading, isSuccess, isError, data: authData, error, reset }] = useSendSmsMutation()
  const [sendBonusSms, { data: bonusData, isError: isBonusError, error: bonusError, reset: bonusReset }] =
    useSendBonusSmsMutation()
  const [sendBonusPhone, { isError: isBonusPhoneError, error: bonusPhoneError, reset: bonusPhoneReset }] =
    useSendBonusPhoneMutation({
      fixedCacheKey: 'bonusPhone'
    })
  const [sendPhone] = useSendPhoneMutation()
  const [seconds, setSeconds] = useState<number>(bonusPhoneError ? (bonusPhoneError as number) : 60)

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [seconds])

  useEffect(() => {
    if (bonusData === 'success') {
      navigate('/more/bonus', { state: true })
    }
  }, [authData, bonusData, smsType, navigate])

  return (
    <>
      {isLoading || isSuccess ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Snackbar
        open={isError || isBonusError || isBonusPhoneError}
        autoHideDuration={3000}
        onClose={() => {
          reset()
          bonusReset()
          bonusPhoneReset()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<ErrorOutlineIcon fontSize='inherit' sx={{ color: theme.palette['Main-2'] }} />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          {isError ? (error as string) : null}
          {isBonusError ? (bonusError as string) : null}
          {isBonusPhoneError
            ? `Превышен лимит запросов! Вы можете повторить попытку через ${bonusPhoneError as number} секунд`
            : null}
        </Alert>
      </Snackbar>

      <TopNavigation
        header='Подтверждение'
        back={() => {
          navigate(-1)
        }}
      />

      {userPhone ? (
        <S_Container>
          <S_Heading variant='Head-2-B' component='h2'>
            Теперь введите код
          </S_Heading>
          <S_Sended variant='Text-1-R' component='p'>{`Код отправили на номер\n${userPhone}`}</S_Sended>

          <Formik
            initialValues={{ code: '', phone: userPhone }}
            validateOnBlur={false}
            onSubmit={values => {
              if (smsType === 'bonus') {
                sendBonusSms(values)
              } else {
                sendAuthSms(values)
                  .unwrap()
                  .then(res => {
                    if (res === 'success') {
                      navigate('/create-pin')
                    }
                  })
                  .catch(() => {})
              }
            }}
          >
            {({ values, setFieldValue, submitForm }) => (
              <>
                <Form noValidate>
                  <Code
                    status={'error'}
                    pin={false}
                    onComplete={(value: string) => {
                      setFieldValue('code', value)
                      submitForm()
                    }}
                  />

                  <S_Navigation>
                    {seconds !== 0 && seconds !== -1 ? (
                      <S_Seconds variant='Small-text-1-R' component='p'>
                        Повторная отправка доступна через {seconds} сек
                      </S_Seconds>
                    ) : null}

                    <Button
                      variant='outlined'
                      aria-label='send code'
                      type='button'
                      disabled={seconds !== 0 ? true : false}
                      onClick={() => {
                        setSeconds(60)

                        if (smsType === 'bonus') {
                          sendBonusPhone(values.phone)
                        } else {
                          sendPhone(values.phone)
                        }
                      }}
                    >
                      Получить код повторно
                    </Button>
                  </S_Navigation>
                </Form>
              </>
            )}
          </Formik>
        </S_Container>
      ) : null}
    </>
  )
}
