import { ENV } from 'env'
import type { StorySlide } from '../../api/types'

// ** Import react
import { useState, useEffect, SetStateAction, Dispatch } from 'react'

// ** Import stories
import { Story } from 'react-insta-stories/dist/interfaces'

// ** Import components
import { S_Link, S_Picture, S_Text } from './InstaStory.styled'
import { Button, Box } from '@mui/material'

type InstaStoryProps = {
  stories: StorySlide[]
  image: string
  text: string
  setStories: Dispatch<SetStateAction<Story[]>>
}

export const InstaStory = ({ stories, image, text, setStories }: InstaStoryProps): JSX.Element => {
  const [status, setStatus] = useState<'viewed' | 'new'>('new')
  const [slides, setSlides] = useState<Story[]>([])

  console.log(stories)

  useEffect(() => {
    const generatedSlides: Story[] = []

    stories.forEach(slide => {
      generatedSlides.push({
        url: `${window.routes5[`media${ENV}`]}${slide.background}`,
        ...(slide.location !== '' && {
          seeMoreCollapsed: () => (
            <Box
              sx={{
                maxWidth: '150px',
                margin: '0 auto',
                marginBottom: '20px'
              }}
            >
              <Button
                variant='contained'
                size='small'
                onClick={() => {
                  // @ts-ignore
                  window.location = slide.location
                }}
                sx={{
                  padding: '10px'
                }}
              >
                Подробнее
              </Button>
            </Box>
          ),
          seeMore: () => {
            // // @ts-ignore
            // window.location = slide.location
          }
        })
      })
    })

    setSlides(generatedSlides)
  }, [stories])

  return (
    <>
      <S_Link
        status={status}
        onClick={() => {
          setStatus('viewed')
          setStories(slides)
        }}
      >
        <S_Picture>
          <img src={image} alt='story' />
        </S_Picture>
        <S_Text variant='Small-text-2-R' component='p'>
          {text}
        </S_Text>
      </S_Link>
    </>
  )
}
