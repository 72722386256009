import type { NotificationData } from '../../entities/notification'

// ** react-router
import { useLoaderData, useNavigate, useNavigation } from 'react-router-dom'

// ** MUI
import { useTheme, Box, Typography } from '@mui/material'

// ** components
import { TopNavigation } from 'shared/ui'
import { Loader } from 'shared/ui'

const HEADER_LENGTH = 25

export const NotificationDetail = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()

  const { image, date, header, text } = useLoaderData() as NotificationData

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <TopNavigation
        header={header.length > HEADER_LENGTH ? `${header.slice(0, HEADER_LENGTH)}...` : header}
        icon={image}
        back={() => {
          navigate(-1)
        }}
      />

      <Box
        sx={{
          height: 'calc(100% - 70px)',
          paddingTop: '20px',
          paddingLeft: '16px',
          paddingRight: '16px',
          backgroundColor: theme.palette['Main-2']
        }}
      >
        <Typography variant='Text-2-M' component='p' sx={{ marginBottom: '15px', color: theme.palette['Sup-2'] }}>
          {date}
        </Typography>

        <Typography variant='Head-3-B' component='h2' sx={{ marginTop: '15px', marginBottom: '10px' }}>
          {header}
        </Typography>

        <Typography variant='Text-1-R' component='p'>
          {text}
        </Typography>
      </Box>
    </>
  )
}
