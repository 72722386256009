import { styled, Step, StepLabel, Stepper } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

export const QontoConnector = styled(StepConnector)(props => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: props.theme.palette['Sup-1']
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: props.theme.palette['Corp-1']
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: props.theme.palette['Sup-1'],
    borderTopWidth: 2
  }
}))

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean }
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette['Sup-1'],
  ...(ownerState.active && {
    color: '#784af4'
  }),
  '& .QontoStepIcon-completedIcon__wrapp': {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette['Corp-1']
  },

  '& .QontoStepIcon-completedIcon': {
    color: theme.palette['Main-2'],
    zIndex: 1,
    fontSize: 18
  },

  '& .QontoStepIcon-circle': {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette['Sup-1'],

    '& .QontoStepIcon-image': {
      display: 'none'
    }
  }
}))

export const StepCustom = styled(Step)({
  '&:first-of-type': {
    paddingLeft: 0
  },

  '&:last-child': {
    paddingRight: 0,

    '& .MuiStepLabel-root': {
      '& .MuiStepLabel-iconContainer': {
        '& .QontoStepIcon-circle': {
          '& .QontoStepIcon-image': {
            display: 'block'
          }
        }
      }
    }
  }
})

export const StepLabelCustom = styled(StepLabel)({
  '& .MuiStepLabel-labelContainer': {
    '& .MuiStepLabel-label': {
      marginTop: '4px',
      fontWeight: 500,
      fontSize: '0.5rem',
      lineHeight: '10px',
      letterSpacing: '0.1px'
    }
  }
})

export const StepperCustom = styled(Stepper)(props => ({
  width: '100%',
  marginLeft: '-10px',
  marginTop: '20px',
  paddingBottom: '25px',
  borderBottom: `1px solid ${props.theme.palette['Sup-1']}`
}))
