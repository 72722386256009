// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box } from '@mui/material'

// ** Import components
import { TopNavigation, Loader } from 'shared/ui'

// ** Import api
// import { loadAbout } from '../../api/about/api'
import { useGetAboutDataQuery } from 'entities/meta'

export const About = (): JSX.Element => {
  const theme = useTheme()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data, isSuccess } = useGetAboutDataQuery()

  function createMarkup() {
    if (isSuccess) {
      return { __html: data.content }
    }
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <>
          <TopNavigation
            header={data.header}
            back={() => {
              navigate(-1)
            }}
          />
          <Box
            sx={{
              padding: '20px 16px',
              background: theme.palette['Main-2']
            }}
          >
            <Box dangerouslySetInnerHTML={createMarkup()} />
          </Box>
        </>
      ) : null}
    </>
  )
}
