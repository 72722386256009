import { styled, Box, Link } from '@mui/material'

export const S_Link = styled(Link)(props => ({
  position: 'relative',
  backgroundColor: props.theme.palette['Main-2'],
  paddingTop: '10px',
  borderRadius: '16px',
  textDecoration: 'none',

  '&:before': {
    position: 'absolute',
    top: '15px',
    right: '12px',
    width: '7px',
    height: '7px',
    borderTop: `1px solid ${props.theme.palette['Main-1']}`,
    borderRight: `1px solid ${props.theme.palette['Main-1']}`,
    transform: 'rotate(45deg)',
    content: '""'
  }
})) as typeof Link

export const S_Points = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  paddingTop: '14px',
  paddingRight: '5px',
  paddingBottom: '13px',
  paddingLeft: '5px',
  borderRadius: '16px',
  backgroundColor: props.theme.palette['copper']
}))
