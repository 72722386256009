import { Tab, styled } from '@mui/material'
import TabList from '@mui/lab/TabList'

export const AntTabs = styled(TabList)(props => ({
  minHeight: 'unset',

  '& .MuiTabs-scroller': {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '30px',
    border: `1px solid ${props.theme.palette['Sup-1']}`,
    padding: '3px',
    '& .MuiTabs-indicator': {
      visibility: 'hidden'
    },

    '& .MuiTabs-centered': {
      alignItems: 'center'
    }
  }
}))

export const AntTab = styled(Tab)(props => ({
  minHeight: 'unset',
  fontWeight: 500,
  fontSize: '0.75rem',
  lineHeight: '15px',
  padding: '7px 23px',
  border: `1px solid ${props.theme.palette['Main-2']}`,
  textTransform: 'capitalize',
  '&.Mui-selected': {
    border: `1px solid ${props.theme.palette['Corp-1']}`,
    borderRadius: '30px',
    color: props.theme.palette['Corp-1']
  }
}))
