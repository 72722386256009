// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box } from '@mui/material'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'
import { ServiceOffer } from './ui/ServiceOffer/ServiceOffer'

// ** Import api
import { useGetServicesQuery } from 'entities/services'

export const Services = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()

  const { data, isSuccess } = useGetServicesQuery()

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <Box sx={{ background: theme.palette['Main-2'], display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
          <TopNavigation header='Предложения от партнеров' back={() => navigate(-1)} />

          <Box sx={{ paddingTop: '20px', paddingRight: '16px', paddingLeft: '16px' }}>
            {data.map(offer => (
              <Box key={offer.id} sx={{ marginBottom: '20px' }}>
                <ServiceOffer navigationRoute={`/more/services/service-detail/${offer.id}`} {...offer} />
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </>
  )
}
