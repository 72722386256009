// ** Import MUI components
import { StepIconProps } from '@mui/material/StepIcon'

// ** Import components
import { QontoConnector, QontoStepIconRoot, StepperCustom, StepCustom, StepLabelCustom } from './Steps.styled'
import { ReactComponent as CheckIcon } from './check-icon.svg'
import FinishIcon from './finish-icon.svg'

const QontoStepIcon = (props: StepIconProps): JSX.Element => {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className='QontoStepIcon-completedIcon__wrapp'>
          <CheckIcon className='QontoStepIcon-completedIcon' />
        </div>
      ) : (
        <div className='QontoStepIcon-circle'>
          <img className='QontoStepIcon-image' src={FinishIcon} alt='finish' />
        </div>
      )}
    </QontoStepIconRoot>
  )
}

const steps = ['0%', '25%', '50%', '75%', '100%']

export const Steps = ({ step }: { step: number }) => {
  return (
    <StepperCustom alternativeLabel activeStep={step} connector={<QontoConnector />}>
      {steps.map(label => (
        <StepCustom key={label}>
          <StepLabelCustom StepIconComponent={QontoStepIcon}>{label}</StepLabelCustom>
        </StepCustom>
      ))}
    </StepperCustom>
  )
}
