import type { CatalogData, AdsResponse } from 'entities/catalog'

// ** react
import { useState, useEffect, useRef, Fragment } from 'react'

// ** react-router
import { useNavigation, useLocation } from 'react-router-dom'

import InfiniteScroll from 'react-infinite-scroller'

// ** Import formik
import { Formik, Form, FieldArray, FormikValues, FormikProps } from 'formik'

// ** MUI
import {
  useTheme,
  Box,
  Button,
  Chip,
  SwipeableDrawer,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  Slider
} from '@mui/material'

// ** components
import { Header } from './ui/Header/Header'
import { Ad } from './ui/Ad/Ad'
import { CatalogCard } from './ui/CatalogCard/CatalogCard'

import { Loader } from 'shared/ui'

import { ReactComponent as DeleteIcon } from './assets/delete-icon.svg'
import { ReactComponent as CheckboxIcon } from './assets/checkbox.svg'
import { ReactComponent as CheckboxCheckedIcon } from './assets/checkbox-checked.svg'
import { ReactComponent as SearchIcon } from './assets/search-icon.svg'
import { ReactComponent as NothingFound } from './assets/nfound-icon.svg'

// ** styles
import { S_CloseButton, S_Icon, S_CheckedIcon, S_Search, S_Chips } from './Catalog.styled'

// ** utils
import { toNumber, getRandomNumber } from 'shared/utils'

// ** API
import { useGetFiltersQuery, useGetAdQuery, useGetCatalogMutation } from 'entities/catalog'

type CheckboxType = {
  index: number
  value: string
  label: string
  checked: boolean
}

const getRandomAd = (array: AdsResponse['result']) => {
  const randomIndex = getRandomNumber(0, array.length - 1)

  return (
    <Box sx={{ gridColumnStart: '1', gridColumnEnd: '3' }}>
      <Ad image={array[randomIndex].image} text={array[randomIndex].text} link={array[randomIndex].link} />
    </Box>
  )
}

export const Catalog = () => {
  const theme = useTheme()
  const navigation = useNavigation()
  const location = useLocation()
  const [isCategoryOpen, setIsCategoryOpen] = useState<boolean>(false)
  const [isMarkOpen, setIsMarkOpen] = useState<boolean>(false)
  const [isMarkTypeOpen, setIsMarkTypeOpen] = useState<boolean>(false)
  const [isPriceOpen, setIsPriceOpen] = useState<boolean>(false)
  const initialRender = useRef(true)

  const { data: filtersData } = useGetFiltersQuery()

  const [loadCatalog, { data: catalogData, isLoading: isCatalogLoading, reset: catalogReset }] = useGetCatalogMutation()

  const { data: adsData } = useGetAdQuery()
  const [currentReccomendations, setCurrentReccomendations] = useState<CatalogData['recommendations']>([])

  ///////// search
  const [search, setSearch] = useState<string>('')
  ///////// search

  ///////// sorting
  const [sorting, setSorting] = useState<'expensive' | 'cheap' | 'alphabet'>(
    filtersData ? filtersData.sorting : 'alphabet'
  )
  ///////// sorting

  ///////// category
  const [activeCategory, setActiveCategory] = useState<{ value: string; label: string } | string>(
    location.state !== null ? location.state.activeCategory : ''
  )
  const categoryFormRef = useRef<FormikProps<FormikValues>>(null)
  ///////// category

  ///////// models
  //only selected MODEL TYPES checkboxes
  const [activeModel, setActiveModels] = useState<CheckboxType[]>(location.state ? location.state.activeModel : [])
  //MODEL TYPES form ref
  const modelsFormRef = useRef<FormikProps<FormikValues>>(null)
  // getting modelsCheckboxes
  const modelInitialValues: CheckboxType[] = []
  const modelEmptyValues: CheckboxType[] = []
  filtersData?.filters.models.forEach((item, index) => {
    modelInitialValues.push({
      index: index,
      value: item.value,
      label: item.label,
      checked: activeModel.some((itm: { value: string; label: string }) => itm.value === item.value)
    })

    modelEmptyValues.push({
      index: index,
      value: item.value,
      label: item.label,
      checked: false
    })
  })
  //current state of ALL MODEL checkboxes
  const [modelSelectedValues, setModelSelectedValues] = useState<CheckboxType[]>(modelInitialValues)
  ///////// models

  ///////// model types
  //current state of ALL MODEL TYPES checkboxes
  const [modelTypesSelectedValues, setModelTypesSelectedValues] = useState<CheckboxType[]>([])
  //only selected MODEL TYPES checkboxes
  const [activeModeTypes, setActiveModelTypes] = useState<CheckboxType[]>(
    location.state ? location.state.activeModelType : []
  )
  //MODEL TYPES form ref
  const modelTypesFormRef = useRef<FormikProps<FormikValues>>(null)
  // getting modelTypesCheckboxes
  const modelTypesInitialValues: CheckboxType[] = []
  const modelTypesEmptyValues: CheckboxType[] = []

  catalogData?.modelType?.forEach((item, index) => {
    modelTypesInitialValues.push({
      index: index,
      value: item.value,
      label: item.label,
      checked: activeModeTypes.some((itm: { value: string; label: string }) => itm.value === item.value)
    })

    modelTypesEmptyValues.push({
      index: index,
      value: item.value,
      label: item.label,
      checked: false
    })
  })

  ///////// model types

  ///////// priceRange
  const [activePriceRange, setActivePriceRange] = useState<{ min: number; max: number }>(
    location.state !== null
      ? location.state.priceRange
      : {
          min: 500000,
          max: 150000000
        }
  )
  const PriceRangeFormRef = useRef<FormikProps<FormikValues>>(null)
  ///////// priceRange

  useEffect(() => {
    if (initialRender.current === false) {
      loadCatalog({
        action: 'getCatalog',
        page: 1,
        search: search,
        activeCategory: activeCategory,
        activeModel: activeModel,
        activeModelType: activeModeTypes,
        priceRange: activePriceRange,
        sorting: sorting
      })
        .unwrap()
        .then(data => {
          setCurrentReccomendations(data.recommendations)
        })
        .catch(() => {})
    }
  }, [loadCatalog, search, sorting, activeCategory, activeModel, activeModeTypes, activePriceRange])

  useEffect(() => {
    if (initialRender.current) {
      // get initial data
      loadCatalog(
        location.state
          ? {
              action: location.state.action,
              page: location.state.page,
              search: location.state.search,
              activeCategory: location.state.activeCategory,
              activeModel: location.state.activeModel,
              activeModelType: location.state.activeModelType,
              priceRange: location.state.priceRange,
              sorting: location.state.sorting
            }
          : {
              action: 'getCatalog',
              page: 1,
              search: '',
              activeCategory: '',
              activeModel: [],
              activeModelType: [],
              priceRange: { min: 500000, max: 150000000 },
              sorting: 'alphabet'
            }
      )
        .unwrap()
        .then(data => {
          setCurrentReccomendations(data.recommendations)

          // setting modelTypes
          const modelTypesFirstLoadValues: CheckboxType[] = []
          data.modelType?.forEach((item, index) => {
            modelTypesFirstLoadValues.push({
              index: index,
              value: item.value,
              label: item.label,
              checked: activeModeTypes.some((itm: { value: string; label: string }) => itm.value === item.value)
            })
          })

          setModelTypesSelectedValues(modelTypesFirstLoadValues)
          // setting modelTypes

          // mark that first render has happened
          initialRender.current = false
        })
        .catch(() => {})
    }
  }, [loadCatalog, location.state, activeModeTypes])

  const fetchMoreData = () => {
    if (catalogData) {
      loadCatalog({
        action: 'getCatalog',
        page: catalogData.page + 1,
        search: search,
        activeCategory: activeCategory,
        activeModel: activeModel,
        activeModelType: activeModeTypes,
        priceRange: activePriceRange,
        sorting: sorting
      })
        .unwrap()
        .then(data => {
          setCurrentReccomendations(prevState => prevState.concat(data.recommendations))
        })
        .catch(() => {})
    }
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2000,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Header searchHandler={setSearch} sortingHandler={setSorting} />

      <S_Chips>
        <Box
          sx={{
            display: 'flex',
            columnGap: '8px',
            flexWrap: 'nowrap'
          }}
        >
          {typeof activeCategory !== 'string' ? (
            <Chip
              label={activeCategory.label}
              variant='outlined'
              deleteIcon={<DeleteIcon />}
              onClick={() => {
                setIsCategoryOpen(true)
              }}
              onDelete={() => {
                setActiveCategory('')
                categoryFormRef.current?.resetForm()
              }}
            />
          ) : (
            <Chip
              label='Категории'
              variant='filled'
              onClick={() => {
                setIsCategoryOpen(true)
              }}
            />
          )}

          {activeModel.length !== 0 ? (
            <>
              {activeModel.map((model, index) => (
                <Chip
                  key={index}
                  label={model.label}
                  variant='outlined'
                  deleteIcon={<DeleteIcon />}
                  onClick={() => {
                    setIsMarkOpen(true)
                  }}
                  onDelete={() => {
                    const updatedSelectedList = [...modelSelectedValues]
                    // making change to false on checkbox which we want to delete
                    updatedSelectedList[model.index].checked = false
                    // get all the rest active checkboxes
                    const updetedActiveList = [...activeModel.filter(act => act.index !== model.index)]

                    // updating current state of all checkboxes so we are in sync
                    setModelSelectedValues(updatedSelectedList)
                    //updating active checkboxes
                    setActiveModels(updetedActiveList)
                    //reseting form with updated values
                    modelsFormRef.current?.resetForm({ values: { checkboxes: updatedSelectedList } })

                    // if there are no active checkboxes we reset mark chekboxes
                    if (updetedActiveList.length === 0) {
                      // updating current state of all checkboxes so we are in sync
                      setModelTypesSelectedValues([])
                      //updating active checkboxes
                      setActiveModelTypes([])
                      //reseting form with updated values
                      modelTypesFormRef.current?.resetForm({ values: { checkboxes: modelTypesInitialValues } })
                    }
                  }}
                />
              ))}
            </>
          ) : (
            <Chip
              label='Марка'
              variant='filled'
              onClick={() => {
                setIsMarkOpen(true)
              }}
            />
          )}

          {activeModeTypes.length !== 0 ? (
            <>
              {activeModeTypes.map((modelType, index) => (
                <Chip
                  key={index}
                  label={modelType.label}
                  variant='outlined'
                  deleteIcon={<DeleteIcon />}
                  onClick={() => {
                    setIsMarkTypeOpen(true)
                  }}
                  onDelete={() => {
                    const updatedSelectedList = [...modelTypesSelectedValues]
                    // making change to false on checkbox which we want to delete
                    updatedSelectedList[modelType.index].checked = false
                    // get all the rest active checkboxes
                    const updetedActiveList = [...activeModeTypes.filter(act => act.index !== modelType.index)]
                    // updating current state of all checkboxes so we are in sync
                    setModelTypesSelectedValues(updatedSelectedList)
                    //updating active checkboxes
                    setActiveModelTypes(updetedActiveList)
                    //reseting form with updated values
                    modelTypesFormRef.current?.resetForm({ values: { checkboxes: updatedSelectedList } })
                  }}
                />
              ))}
            </>
          ) : (
            <>
              {activeModel.length !== 0 && catalogData && catalogData.modelType && catalogData.modelType.length > 0 ? (
                <Chip
                  label='Модель'
                  variant='filled'
                  onClick={() => {
                    setIsMarkTypeOpen(true)
                  }}
                />
              ) : null}
            </>
          )}

          {activePriceRange.min !== 500000 || activePriceRange.max !== 150000000 ? (
            <Chip
              label={`${activePriceRange.min}-${activePriceRange.max}`}
              variant='outlined'
              deleteIcon={<DeleteIcon />}
              onClick={() => {
                setIsPriceOpen(true)
              }}
              onDelete={() => {
                setActivePriceRange({ min: 500000, max: 150000000 })
                PriceRangeFormRef.current?.resetForm()
              }}
            />
          ) : (
            <Chip
              label='Цена'
              variant='filled'
              onClick={() => {
                setIsPriceOpen(true)
              }}
            />
          )}
        </Box>
      </S_Chips>

      <Box
        sx={{
          background: theme.palette['Main-2'],
          paddingBottom: '70px',
          height: 'calc(100% - 134px)',
          overflow: 'auto'
        }}
      >
        {currentReccomendations.length !== 0 ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchMoreData}
            hasMore={catalogData ? catalogData.loadMore : false}
            useWindow={false}
            initialLoad={false}
            threshold={1}
          >
            <Box
              sx={{
                display: 'grid',
                paddingRight: '16px',
                paddingLeft: '16px',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '16px'
              }}
            >
              {currentReccomendations.map((item, index) => (
                <Fragment key={index}>
                  <CatalogCard
                    {...item}
                    link={`/recommendation-detail/${item.id}`}
                    action={catalogReset}
                    catalogState={{
                      action: 'getCatalog',
                      page: 1,
                      search: search,
                      activeCategory: activeCategory,
                      activeModel: activeModel,
                      activeModelType: activeModeTypes,
                      priceRange: activePriceRange,
                      sorting: sorting
                    }}
                  />

                  {adsData && (index + 1) % 4 === 0 ? getRandomAd(adsData) : null}
                </Fragment>
              ))}

              {isCatalogLoading ? (
                <Box
                  key={Math.random()}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    gridColumnStart: '1',
                    gridColumnEnd: '3'
                  }}
                >
                  <Loader width={30} />
                </Box>
              ) : null}
            </Box>
          </InfiniteScroll>
        ) : initialRender.current === false ? (
          <Box sx={{ marginTop: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <NothingFound />
            <Typography variant='Head-3-B' component='p' sx={{ marginTop: '15px', marginBottom: '5px' }}>
              Ничего не найдено.
            </Typography>
            <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
              Попробуйте изменить поисковый запрос.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <Loader width={30} />
          </Box>
        )}
      </Box>

      {/* catagory */}
      <SwipeableDrawer
        open={isCategoryOpen}
        onClose={() => {
          setIsCategoryOpen(false)
        }}
        onOpen={() => {}}
        disableBackdropTransition={true}
        disableSwipeToOpen={true}
        anchor='bottom'
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px 12px 0px 0px'
          }
        }}
      >
        <Divider
          sx={{
            width: '35px',
            height: '5px',
            border: '0',
            backgroundColor: '#D9D9D9',
            borderRadius: '5px',
            margin: '0 auto',
            marginTop: '10px',
            marginBottom: '20px'
          }}
        />

        <Formik
          innerRef={categoryFormRef}
          initialValues={{ category: typeof activeCategory !== 'string' ? activeCategory.value : '' }}
          enableReinitialize
          onSubmit={values => {
            const match = filtersData?.filters.category.find(item => item.value === values.category)
            setActiveCategory(match ? match : '')
            setIsCategoryOpen(false)
          }}
        >
          {({ values, resetForm, handleChange }) => (
            <>
              <Form>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '19px',
                    paddingRight: '16px',
                    paddingLeft: '16px',
                    borderBottom: `1px solid ${theme.palette['Sup-1']}`
                  }}
                >
                  <Typography variant='Head-2-B' component='p'>
                    Категория
                  </Typography>
                  <S_CloseButton
                    type='button'
                    onClick={() => {
                      setActiveCategory('')
                      resetForm()
                    }}
                  >
                    Сбросить
                  </S_CloseButton>
                </Box>

                <Box
                  sx={{
                    position: 'relative',
                    padding: '20px 16px 77px 16px',
                    '&:before': {
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      height: '130px',
                      background: `linear-gradient(180deg, rgba(255, 255, 255, 0) -17.94%, ${theme.palette['Main-2']} 42.26%)`,
                      content: '""'
                    }
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: 'calc(100vh - 250px)',
                      overflow: 'auto',
                      height: 'fit-content',
                      paddingBottom: '50px'
                    }}
                  >
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      value={values.category}
                      onChange={handleChange}
                      name='category'
                    >
                      {filtersData?.filters.category.map(item => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          control={<Radio checkedIcon={<S_CheckedIcon />} icon={<S_Icon style={{ zIndex: 0 }} />} />}
                          label={item.label}
                          disableTypography
                        />
                      ))}
                    </RadioGroup>
                  </Box>

                  <Button
                    variant='outlined'
                    type='submit'
                    sx={{
                      width: 'calc(100% - 32px)',
                      position: 'absolute',
                      bottom: '25px',
                      left: 0,
                      right: 0,
                      margin: 'auto'
                    }}
                  >
                    Применить
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </SwipeableDrawer>

      {/* Mark */}
      <SwipeableDrawer
        open={isMarkOpen}
        onClose={() => {
          setIsMarkOpen(false)
        }}
        onOpen={() => {}}
        disableBackdropTransition={true}
        disableSwipeToOpen={true}
        anchor='bottom'
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px 12px 0px 0px'
          }
        }}
      >
        <Divider
          sx={{
            width: '35px',
            height: '5px',
            border: '0',
            backgroundColor: '#D9D9D9',
            borderRadius: '5px',
            margin: '0 auto',
            marginTop: '10px',
            marginBottom: '20px'
          }}
        />

        <Formik
          innerRef={modelsFormRef}
          initialValues={{ checkboxes: modelInitialValues }}
          onSubmit={values => {
            const activeCheckboxes = [...values.checkboxes.filter((item: CheckboxType) => item.checked === true)]
            //set selected checkboxes
            setActiveModels(activeCheckboxes)
            setIsMarkOpen(false)
          }}
        >
          {({ values, resetForm }) => (
            <>
              <Form>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '19px',
                    paddingRight: '16px',
                    paddingLeft: '16px',
                    borderBottom: `1px solid ${theme.palette['Sup-1']}`
                  }}
                >
                  <Typography variant='Head-2-B' component='p'>
                    Марка автомобиля
                  </Typography>
                  <S_CloseButton
                    type='button'
                    onClick={() => {
                      resetForm({ values: { checkboxes: modelEmptyValues } })
                    }}
                  >
                    Сбросить
                  </S_CloseButton>
                </Box>

                <Box
                  sx={{
                    position: 'relative',
                    padding: '20px 16px 77px 16px',
                    '&:before': {
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      height: '130px',
                      background: `linear-gradient(180deg, rgba(255, 255, 255, 0) -17.94%, ${theme.palette['Main-2']} 42.26%)`,
                      content: '""'
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      columnGap: '5px',
                      paddingBottom: '10px',
                      marginBottom: '5px',
                      borderBottom: `1px solid ${theme.palette['Sup-1']}`
                    }}
                  >
                    <SearchIcon />
                    <S_Search
                      placeholder='Введите поисковый запрос'
                      onChange={evt => {
                        const currentFieldValue = new RegExp(`^${evt.target.value}`, 'i')

                        //patch values of original data with current values
                        const patchedValues = [
                          ...modelInitialValues.map(item => ({
                            index: item.index,
                            value: item.value,
                            label: item.label,
                            checked: values.checkboxes[item.index]
                              ? values.checkboxes[item.index].checked
                              : modelSelectedValues[item.index].checked
                          }))
                        ]

                        //save patched array so we always have full current state
                        setModelSelectedValues(patchedValues)

                        //looking for matches
                        const matchingCheckboxes = [...patchedValues.filter(item => currentFieldValue.test(item.label))]

                        resetForm({ values: { checkboxes: matchingCheckboxes } })
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'grid',
                      maxHeight: 'calc(100vh - 250px)',
                      overflow: 'auto',
                      height: 'fit-content',
                      paddingBottom: '50px'
                    }}
                  >
                    <FieldArray
                      name='checkboxes'
                      render={arrayHelpers => (
                        <>
                          {values.checkboxes.map((item: CheckboxType, index: number) => (
                            <FormControlLabel
                              key={index}
                              name={item.value}
                              checked={item.checked}
                              onChange={() => {
                                arrayHelpers.replace(index, {
                                  index: index,
                                  value: item.value,
                                  label: item.label,
                                  checked: !item.checked
                                })

                                const pachedValues = [...values.checkboxes]
                                pachedValues[index].checked = !pachedValues[index].checked
                                //save patched array so we always have full current state
                                setModelSelectedValues(pachedValues)
                              }}
                              control={
                                <Checkbox icon={<CheckboxIcon />} disableRipple checkedIcon={<CheckboxCheckedIcon />} />
                              }
                              label={item.label}
                            />
                          ))}
                        </>
                      )}
                    />
                  </Box>

                  <Button
                    variant='outlined'
                    type='submit'
                    sx={{
                      width: 'calc(100% - 32px)',
                      position: 'absolute',
                      bottom: '25px',
                      left: 0,
                      right: 0,
                      margin: 'auto'
                    }}
                  >
                    Применить
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </SwipeableDrawer>

      {/* MarkType */}
      {/* this check is needed so initial values are not empty */}
      {catalogData ? (
        <SwipeableDrawer
          open={isMarkTypeOpen}
          onClose={() => {
            setIsMarkTypeOpen(false)
          }}
          onOpen={() => {}}
          disableBackdropTransition={true}
          disableSwipeToOpen={true}
          anchor='bottom'
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '12px 12px 0px 0px'
            }
          }}
        >
          <Divider
            sx={{
              width: '35px',
              height: '5px',
              border: '0',
              backgroundColor: '#D9D9D9',
              borderRadius: '5px',
              margin: '0 auto',
              marginTop: '10px',
              marginBottom: '20px'
            }}
          />

          <Formik
            innerRef={modelTypesFormRef}
            initialValues={{ checkboxes: modelTypesInitialValues }}
            onSubmit={values => {
              const activeCheckboxes = [...values.checkboxes.filter((item: CheckboxType) => item.checked === true)]
              //set selected checkboxes
              setActiveModelTypes(activeCheckboxes)
              setIsMarkTypeOpen(false)
            }}
          >
            {({ values, resetForm }) => (
              <>
                <Form>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingBottom: '19px',
                      paddingRight: '16px',
                      paddingLeft: '16px',
                      borderBottom: `1px solid ${theme.palette['Sup-1']}`
                    }}
                  >
                    <Typography variant='Head-2-B' component='p'>
                      Модель
                    </Typography>
                    <S_CloseButton
                      type='button'
                      onClick={() => {
                        resetForm({ values: { checkboxes: modelTypesEmptyValues } })
                      }}
                    >
                      Сбросить
                    </S_CloseButton>
                  </Box>

                  <Box
                    sx={{
                      position: 'relative',
                      padding: '20px 16px 77px 16px',
                      '&:before': {
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: '130px',
                        background: `linear-gradient(180deg, rgba(255, 255, 255, 0) -17.94%, ${theme.palette['Main-2']} 42.26%)`,
                        content: '""'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: '5px',
                        paddingBottom: '10px',
                        marginBottom: '5px',
                        borderBottom: `1px solid ${theme.palette['Sup-1']}`
                      }}
                    >
                      <SearchIcon />
                      <S_Search
                        placeholder='Введите поисковый запрос'
                        onChange={evt => {
                          const currentFieldValue = new RegExp(`^${evt.target.value}`, 'i')

                          //patch values of original data with current values
                          const patchedValues = [
                            ...modelTypesInitialValues.map(item => ({
                              index: item.index,
                              value: item.value,
                              label: item.label,
                              checked: values.checkboxes[item.index]
                                ? values.checkboxes[item.index].checked
                                : modelTypesSelectedValues[item.index].checked
                            }))
                          ]

                          //save patched array so we always have full current state
                          setModelTypesSelectedValues(patchedValues)

                          //looking for matches
                          const matchingCheckboxes = [
                            ...patchedValues.filter(item => currentFieldValue.test(item.label))
                          ]

                          resetForm({ values: { checkboxes: matchingCheckboxes } })
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: 'grid',
                        maxHeight: 'calc(100vh - 250px)',
                        overflow: 'auto',
                        height: 'fit-content',
                        paddingBottom: '50px'
                      }}
                    >
                      <FieldArray
                        name='checkboxes'
                        render={arrayHelpers => (
                          <>
                            {values.checkboxes.map((item: CheckboxType, index: number) => (
                              <FormControlLabel
                                key={index}
                                name={item.value}
                                checked={item.checked}
                                onChange={() => {
                                  arrayHelpers.replace(index, {
                                    index: index,
                                    value: item.value,
                                    label: item.label,
                                    checked: !item.checked
                                  })

                                  const pachedValues = [...values.checkboxes]
                                  pachedValues[index].checked = !pachedValues[index].checked
                                  //save patched array so we always have full current state
                                  setModelTypesSelectedValues(pachedValues)
                                }}
                                control={
                                  <Checkbox
                                    icon={<CheckboxIcon />}
                                    disableRipple
                                    checkedIcon={<CheckboxCheckedIcon />}
                                  />
                                }
                                label={item.label}
                              />
                            ))}
                          </>
                        )}
                      />
                    </Box>

                    <Button
                      variant='outlined'
                      type='submit'
                      sx={{
                        width: 'calc(100% - 32px)',
                        position: 'absolute',
                        bottom: '25px',
                        left: 0,
                        right: 0,
                        margin: 'auto'
                      }}
                    >
                      Применить
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </SwipeableDrawer>
      ) : null}

      {/* Price */}
      <SwipeableDrawer
        open={isPriceOpen}
        onClose={() => {
          setIsPriceOpen(false)
        }}
        onOpen={() => {}}
        disableBackdropTransition={true}
        disableSwipeToOpen={true}
        anchor='bottom'
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px 12px 0px 0px'
          }
        }}
      >
        <Divider
          sx={{
            width: '35px',
            height: '5px',
            border: '0',
            backgroundColor: '#D9D9D9',
            borderRadius: '5px',
            margin: '0 auto',
            marginTop: '10px',
            marginBottom: '20px'
          }}
        />

        <Formik
          innerRef={PriceRangeFormRef}
          initialValues={{
            min: activePriceRange.min,
            max: activePriceRange.max,
            minMax: [activePriceRange.min, activePriceRange.max]
          }}
          enableReinitialize
          onSubmit={values => {
            setActivePriceRange({ min: values.min, max: values.max })
            setIsPriceOpen(false)
          }}
        >
          {({ values, resetForm, setFieldValue, handleChange }) => (
            <>
              <Form>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '19px',
                    paddingRight: '16px',
                    paddingLeft: '16px',
                    borderBottom: `1px solid ${theme.palette['Sup-1']}`
                  }}
                >
                  <Typography variant='Head-2-B' component='p'>
                    Цена
                  </Typography>
                  <S_CloseButton
                    type='button'
                    onClick={() => {
                      setActivePriceRange({ min: 500000, max: 150000000 })
                      resetForm()
                    }}
                  >
                    Сбросить
                  </S_CloseButton>
                </Box>

                <Box
                  sx={{
                    padding: '20px 16px 25px 16px'
                  }}
                >
                  <Box sx={{ display: 'flex', columnGap: '10px', marginBottom: '5px' }}>
                    <TextField
                      label='Цена от'
                      value={values.min}
                      name='min'
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      onChange={handleChange}
                      onBlur={evt => {
                        const formattedInput = toNumber(evt.target.value)

                        let value = formattedInput

                        if (isNaN(formattedInput)) {
                          value = 500000
                        }

                        setFieldValue('min', value)
                        setFieldValue('minMax', [value, values.max])
                      }}
                    />
                    <TextField
                      value={values.max}
                      name='max'
                      label='Цена до'
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      onChange={handleChange}
                      onBlur={evt => {
                        const formattedInput = toNumber(evt.target.value)

                        let value = formattedInput

                        if (isNaN(formattedInput)) {
                          value = 150000000
                        }

                        setFieldValue('max', value)
                        setFieldValue('minMax', [values.min, value])
                      }}
                    />
                  </Box>

                  <Slider
                    value={values.minMax}
                    valueLabelDisplay='off'
                    step={3000000}
                    min={500000}
                    max={150000000}
                    marks
                    onChange={evt => {
                      if (evt !== null) {
                        const target = evt.target as HTMLInputElement
                        setFieldValue('minMax', target.value)
                        setFieldValue('min', target.value[0])
                        setFieldValue('max', target.value[1])
                      }
                    }}
                  />

                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', marginBottom: '50px' }}
                  >
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      500 000 ₽
                    </Typography>
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      150 000 000 ₽
                    </Typography>
                  </Box>

                  <Button variant='outlined' type='submit'>
                    Применить
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </SwipeableDrawer>
    </>
  )
}
