// ** Import MUI components
import { Typography, styled } from '@mui/material'

export const RootLink = styled('a')({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center'
})

export const NamePdf = styled(Typography)(props => ({
  color: props.theme.palette['Main-1'],
  mb: '4px'
})) as typeof Typography

export const WeightPdf = styled(Typography)(props => ({
  color: props.theme.palette['Sup-2']
})) as typeof Typography
