import { MenuLink } from 'shared/ui'
import type { AppStoreType } from './model/store'

//react
import { useEffect, useState } from 'react'

//router
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom'

// ** Layouts
import { MainLayout, SecondLayout } from 'shared/layouts'
// ** Pages
import {
  Splash,
  Onboarding,
  Authentication,
  Sms,
  Home,
  Notifications,
  NotificationDetail,
  News,
  Deals,
  DealDetail,
  RequestDetail,
  More,
  Help,
  NewsDetail,
  UsefulNews,
  PersonalManager,
  ManagerMessage,
  OrderCall,
  Info,
  About,
  Calculator,
  RecommendationDetail,
  Catalog,
  Faq,
  BugBounty,
  Profile,
  Bonus,
  BonusEmpty,
  Points,
  Adverts,
  AdvertDetail,
  ServiceDetail,
  Services,
  Settings,
  Program,
  Pin,
  CreatePin
  // Chat
} from 'pages'

// api
import { OnboardingApi } from 'entities/onboarding'
import { StoriesApi } from 'entities/stories'
import { RequestApi } from 'entities/request'
import { DealApi } from 'entities/deal'
import { RecommendationApi } from 'entities/recommendation'
import { ProfileApi, setDefaultCompany } from 'entities/profile'
import { NotificationApi } from 'entities/notification'
import { NewsApi } from 'entities/news'
import { UsefulNewsApi } from 'entities/usefulNews'
import { MetaApi } from 'entities/meta'
import { BugBountyApi } from 'entities/bugBounty'
import { CalculatorApi } from 'entities/calculator'
import { CatalogApi } from 'entities/catalog'
import { FaqApi } from 'entities/faq'
import { AdvertApi } from 'entities/advert'
import { ServicesApi } from 'entities/services'
import { BonusApi } from 'entities/bonus'
import { PointApi } from 'entities/point'
import { SettingsApi } from 'entities/settings'
import { ProgramApi } from 'entities/program'

const AuthNavLinks = [
  { id: 1, href: '/', icon: 'main', name: 'Главная' },
  { id: 2, href: '/deals', isAuth: true, icon: 'deals', name: 'Сделки' },
  { id: 3, href: '/catalog', icon: 'catalog', name: 'Каталог' },
  { id: 4, href: '/personal-manager', icon: 'manager', name: 'Менеджер' },
  { id: 5, href: '/more', icon: 'more', name: 'Еще' }
] as MenuLink[]

const NavLinks = [
  { id: 1, href: '/', icon: 'main', name: 'Главная' },
  { id: 3, href: '/catalog', icon: 'catalog', name: 'Каталог' },
  { id: 4, href: '/order-call', icon: 'manager', name: 'Заказать звонок' },
  { id: 5, href: '/more', icon: 'more', name: 'Еще' }
] as MenuLink[]

const Error = () => {
  return <p>возникла ошибка!</p>
}

const createRoutes = (store: AppStoreType) => {
  return [
    {
      element: <MainLayout links={store.getState().meta.isAuth ? AuthNavLinks : NavLinks} />,
      errorElement: <Error />,
      children: [
        {
          path: '/',
          element: <Home />,
          loader: async () => {
            // check for splash screen
            if (store.getState().meta.isAnimationPlayed === false) {
              return redirect('/splash')
            }

            let data

            // load auth data
            if (store.getState().meta.isAnimationPlayed && store.getState().meta.isAuth) {
              let requests
              let deals

              const profile = store.dispatch(ProfileApi.endpoints.getProfile.initiate())
              profile.unsubscribe()

              //setting default company for user on first load
              const firstProfileLoad = store.getState().profile.defaultCompany

              if (firstProfileLoad === undefined) {
                const defaultCompany = (await profile).data?.defaultCompany

                if (defaultCompany !== undefined) {
                  store.dispatch(setDefaultCompany(defaultCompany))

                  // make requests with this data
                  requests = store.dispatch(RequestApi.endpoints.getRequests.initiate(defaultCompany.id))
                  requests.unsubscribe()

                  deals = store.dispatch(DealApi.endpoints.getDeals.initiate(defaultCompany.id))
                  deals.unsubscribe()
                }
              }

              const stories = store.dispatch(StoriesApi.endpoints.getStories.initiate())
              stories.unsubscribe()
              const notifications = store.dispatch(NotificationApi.endpoints.getNotificationData.initiate())
              notifications.unsubscribe()
              const recommendations = store.dispatch(RecommendationApi.endpoints.getRecommendations.initiate())
              recommendations.unsubscribe()

              const adverts = store.dispatch(AdvertApi.endpoints.getAdverts.initiate())
              adverts.unsubscribe()
              const services = store.dispatch(ServicesApi.endpoints.getServices.initiate())
              services.unsubscribe()

              if (store.getState().meta.isAdvertShown === false) {
                const activeAdvert = store.dispatch(AdvertApi.endpoints.getAdvert.initiate())
                activeAdvert.unsubscribe()
              }

              data = Promise.allSettled([
                profile,
                stories,
                notifications,
                recommendations,
                deals,
                requests,
                adverts,
                services
              ])
            } // load guest data
            else if (store.getState().meta.isAnimationPlayed) {
              const stories = store.dispatch(StoriesApi.endpoints.getStories.initiate())
              stories.unsubscribe()
              const recommendations = store.dispatch(RecommendationApi.endpoints.getRecommendations.initiate())
              recommendations.unsubscribe()
              const adverts = store.dispatch(AdvertApi.endpoints.getAdverts.initiate())
              adverts.unsubscribe()
              const services = store.dispatch(ServicesApi.endpoints.getServices.initiate())
              services.unsubscribe()

              if (store.getState().meta.isAdvertShown === false) {
                const activeAdvert = store.dispatch(AdvertApi.endpoints.getAdvert.initiate())
                activeAdvert.unsubscribe()
              }

              data = Promise.allSettled([stories, recommendations, adverts, services])
            }

            return await data
          }
        },
        {
          path: '/calculator',
          element: <Calculator />,
          loader: async () => {
            const calculatorPercentages = store.dispatch(CalculatorApi.endpoints.getPercentages.initiate())
            calculatorPercentages.unsubscribe()

            return await calculatorPercentages
          }
        },
        {
          path: '/catalog',
          element: <Catalog />,
          loader: async () => {
            let data

            const filters = store.dispatch(CatalogApi.endpoints.getFilters.initiate())
            filters.unsubscribe()

            const ads = store.dispatch(CatalogApi.endpoints.getAd.initiate())
            ads.unsubscribe()

            data = Promise.allSettled([filters, ads])

            return await data
          }
        },
        {
          path: '/notifications/',
          children: [
            {
              element: <Notifications />,
              loader: async () => {
                const notifications = store.dispatch(NotificationApi.endpoints.getNotificationData.initiate())
                notifications.unsubscribe()

                return await notifications
              },
              index: true
            },
            {
              path: ':id',
              element: <NotificationDetail />,
              loader: ({ params }: { params: any }) => {
                const data = NotificationApi.endpoints.getNotificationData.select()(store.getState()).data

                const selectedNotification = data?.find(notification => notification.id === params.id)

                // запрос на обновление, меняем статус на прочитанный
                if (selectedNotification?.status === 'unread') {
                  store.dispatch(NotificationApi.endpoints.markAsRead.initiate(params.id))
                }

                return selectedNotification
              }
            }
          ]
        },
        {
          path: '/deals',
          element: <Deals />,
          loader: async () => {
            let data
            let requests
            let deals

            const defaultCompany = store.getState().profile.defaultCompany

            const recommendations = store.dispatch(RecommendationApi.endpoints.getRecommendations.initiate())
            recommendations.unsubscribe()

            if (defaultCompany) {
              const requests = store.dispatch(RequestApi.endpoints.getRequests.initiate(defaultCompany.id))
              requests.unsubscribe()

              deals = store.dispatch(DealApi.endpoints.getDeals.initiate(defaultCompany.id))
              deals.unsubscribe()
            }

            data = Promise.allSettled([recommendations, deals, requests])

            return await data
          }
        },
        {
          path: '/deal-detail/:id',
          element: <DealDetail />,
          loader: async ({ params }: { params: any }) => {
            const dealDetail = store.dispatch(DealApi.endpoints.getDeal.initiate(params.id))
            dealDetail.unsubscribe()

            return { data: await dealDetail, id: params.id }
          }
        },
        {
          path: '/request-detail/:id',
          element: <RequestDetail />,
          loader: async ({ params }: any) => {
            const defaultCompany = store.getState().profile.defaultCompany

            if (defaultCompany) {
              const requestDetail = store.dispatch(
                RequestApi.endpoints.getRequest.initiate({ id: params.id, companyId: defaultCompany.id })
              )
              requestDetail.unsubscribe()

              return { data: await requestDetail, id: params.id }
            }
          }
        },
        {
          path: '/recommendation-detail/:id',
          element: <RecommendationDetail />,
          loader: async ({ params }: any) => {
            const recommendationDetail = store.dispatch(
              RecommendationApi.endpoints.getRecommendation.initiate(params.id)
            )
            recommendationDetail.unsubscribe()
            return { data: await recommendationDetail, id: params.id }
          }
        },
        {
          path: '/profile',
          element: <Profile />,
          loader: async () => {
            const profile = store.dispatch(ProfileApi.endpoints.getProfile.initiate())
            profile.unsubscribe()

            //setting default company for user on first load
            const firstProfileLoad = store.getState().profile.defaultCompany
            if (firstProfileLoad === undefined) {
              const defaultCompany = (await profile).data?.defaultCompany

              if (defaultCompany !== undefined) {
                store.dispatch(setDefaultCompany(defaultCompany))
              }
            }

            return await profile
          },
          errorElement: <Error />
        },
        {
          path: '/personal-manager',
          element: <PersonalManager />
        },
        {
          path: '/manager-message',
          element: <ManagerMessage />
        },
        {
          path: '/order-call',
          element: <OrderCall />
        },
        {
          path: '/advert-detail/:id',
          element: <AdvertDetail />,
          loader: async ({ params }: { params: any }) => {
            const advertDetail = store.dispatch(AdvertApi.endpoints.getDetailAdvert.initiate(params.id))
            advertDetail.unsubscribe()

            return { data: await advertDetail, id: params.id }
          }
        },
        {
          path: '/service-detail/:id',
          element: <ServiceDetail />,
          loader: async ({ params }: { params: any }) => {
            const serviceDetail = store.dispatch(ServicesApi.endpoints.getDetailService.initiate(params.id))
            serviceDetail.unsubscribe()

            return { data: await serviceDetail, id: params.id }
          }
        },
        {
          path: '/more',
          children: [
            {
              element: <More />,
              index: true,
              loader: async () => {
                if (store.getState().meta.isAuth) {
                  let data

                  const profile = store.dispatch(ProfileApi.endpoints.getProfile.initiate())
                  profile.unsubscribe()

                  // check if user is bonus program
                  const isBonusProgramMemeber = (await profile).data?.bonus

                  if (isBonusProgramMemeber) {
                    const points = store.dispatch(PointApi.endpoints.getPoints.initiate())
                    points.unsubscribe()

                    data = Promise.allSettled([profile, points])

                    return await data
                  }

                  return await profile
                }
                return null
              }
            },
            {
              path: '/more/news/',
              children: [
                {
                  element: <News />,
                  index: true,
                  loader: async () => {
                    const allNews = store.dispatch(NewsApi.endpoints.getAllNews.initiate())
                    allNews.unsubscribe()

                    return await allNews
                  }
                },
                {
                  path: ':id',
                  element: <NewsDetail />,
                  loader: async ({ params }: { params: any }) => {
                    const data = store.dispatch(NewsApi.endpoints.getFullNews.initiate(params.id))
                    data.unsubscribe()

                    return { data: (await data).data, sliderCardsRoute: '/more/news' }
                  }
                }
              ]
            },
            {
              path: '/more/adverts',
              children: [
                {
                  element: <Adverts />,
                  index: true,
                  loader: async () => {
                    const adverts = store.dispatch(AdvertApi.endpoints.getAdverts.initiate())
                    adverts.unsubscribe()

                    return await adverts
                  }
                },
                {
                  path: '/more/adverts/advert-detail/:id',
                  element: <AdvertDetail />,
                  loader: async ({ params }: { params: any }) => {
                    const advertDetail = store.dispatch(AdvertApi.endpoints.getDetailAdvert.initiate(params.id))
                    advertDetail.unsubscribe()

                    return { data: await advertDetail, id: params.id }
                  }
                }
              ]
            },
            {
              path: '/more/services',
              children: [
                {
                  element: <Services />,
                  index: true,
                  loader: async () => {
                    const services = store.dispatch(ServicesApi.endpoints.getServices.initiate())
                    services.unsubscribe()
                    return await services
                  }
                },
                {
                  path: '/more/services/service-detail/:id',
                  element: <ServiceDetail />,
                  loader: async ({ params }: { params: any }) => {
                    const serviceDetail = store.dispatch(ServicesApi.endpoints.getDetailService.initiate(params.id))
                    serviceDetail.unsubscribe()

                    return { data: await serviceDetail, id: params.id }
                  }
                }
              ]
            },
            {
              path: '/more/help',
              children: [
                {
                  element: <Help />,
                  index: true
                },
                {
                  path: '/more/help/useful-info/',
                  children: [
                    {
                      element: <UsefulNews />,
                      index: true,
                      loader: async () => {
                        const allUsefulNews = store.dispatch(UsefulNewsApi.endpoints.getAllUsefulNews.initiate())
                        allUsefulNews.unsubscribe()

                        return await allUsefulNews
                      }
                    },
                    {
                      path: ':id',
                      element: <NewsDetail />,
                      loader: async ({ params }: { params: any }) => {
                        const data = store.dispatch(UsefulNewsApi.endpoints.getUsefulNewsDetail.initiate(params.id))
                        data.unsubscribe()

                        return { data: (await data).data, sliderCardsRoute: '/more/help/useful-info' }
                      }
                    }
                  ]
                },
                {
                  path: '/more/help/faq',
                  element: <Faq />,
                  loader: async () => {
                    const data = store.dispatch(FaqApi.endpoints.getFaqData.initiate())
                    data.unsubscribe()

                    return await data
                  }
                }
              ]
            },
            {
              path: '/more/feedback',
              element: <OrderCall />,
              loader: () => {
                return 'more'
              }
            },
            {
              path: '/more/info',
              element: <Info />,
              loader: async () => {
                const info = store.dispatch(
                  MetaApi.endpoints.getInfoData.initiate(undefined, { fixedCacheKey: 'infoData' })
                )

                return await info
              }
            },
            {
              path: '/more/bug-bounty',
              element: <BugBounty />,
              loader: async () => {
                const allNews = store.dispatch(BugBountyApi.endpoints.getBugBounteHtml.initiate())
                allNews.unsubscribe()

                return await allNews
              }
            },
            {
              path: '/more/about',
              element: <About />,
              loader: async () => {
                const info = store.dispatch(MetaApi.endpoints.getAboutData.initiate())
                info.unsubscribe()
                return await info
              }
            },
            {
              path: '/more/bonus-welcome',
              element: <BonusEmpty />,
              loader: async () => {
                const profile = store.dispatch(ProfileApi.endpoints.getProfile.initiate())
                profile.unsubscribe()

                // check if user is bonus program
                const isBonusProgramMemeber = (await profile).data?.bonus

                if (isBonusProgramMemeber) {
                  throw redirect('/more/bonus')
                }

                return null
              }
            },
            {
              path: '/more/bonus',
              children: [
                {
                  element: <Bonus />,
                  index: true,
                  loader: async () => {
                    let data

                    const bonus = store.dispatch(BonusApi.endpoints.getBonus.initiate())
                    bonus.unsubscribe()
                    const point = store.dispatch(PointApi.endpoints.getPoints.initiate())
                    point.unsubscribe()

                    data = Promise.allSettled([point, bonus])
                    return await data
                  }
                },
                {
                  path: '/more/bonus/points',
                  element: <Points />,
                  loader: async () => {
                    const point = store.dispatch(PointApi.endpoints.getPoints.initiate())
                    point.unsubscribe()

                    return await point
                  }
                },
                {
                  path: '/more/bonus/program',
                  element: <Program />,
                  loader: async () => {
                    const program = store.dispatch(ProgramApi.endpoints.getProgram.initiate())
                    program.unsubscribe()
                    return await program
                  }
                }
              ]
            },
            {
              path: '/more/settings',
              element: <Settings />,
              loader: async () => {
                const settings = store.dispatch(SettingsApi.endpoints.getSettings.initiate())
                settings.unsubscribe()

                return await settings
              }
            }
            // {
            //   path: '/more/chat',
            //   element: <Chat />
            // }
          ]
        }
      ]
    },
    {
      element: <SecondLayout />,
      children: [
        {
          path: '/splash',
          element: <Splash />,
          loader: () => {
            if (store.getState().meta.isAnimationPlayed) {
              return redirect('/onboarding')
            }

            return null
          }
        },
        {
          path: '/onboarding',
          element: <Onboarding />,
          loader: async () => {
            const request = store.dispatch(OnboardingApi.endpoints.getOnboardingData.initiate())
            request.unsubscribe()

            return null
          }
        },
        {
          path: '/authentication',
          element: <Authentication />
        },
        {
          path: '/sms',
          element: <Sms />
        },
        {
          path: '/pin',
          element: <Pin />
        },
        {
          path: '/create-pin',
          element: <CreatePin />
        }
      ]
    }
  ]
}

const App = ({ store }: { store: AppStoreType }) => {
  const [isAuth, setIsAuth] = useState(store.getState().meta.isAuth)
  const [isSplashPlayed, setIsSplashPlayed] = useState(store.getState().meta.isAnimationPlayed)

  useEffect(() => {
    const unsubscribeIsSplashPlayed = store.subscribe(() => {
      setIsSplashPlayed(store.getState().meta.isAnimationPlayed)
    })

    return () => {
      unsubscribeIsSplashPlayed()
    }
  }, [store, isSplashPlayed])

  useEffect(() => {
    const unsubscribeIsAuth = store.subscribe(() => {
      setIsAuth(store.getState().meta.isAuth)
    })

    return () => {
      unsubscribeIsAuth()
    }
  }, [store, isAuth])

  return <RouterProvider router={createBrowserRouter(createRoutes(store))} />
}

export default App
