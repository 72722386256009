import type { MoreProps } from 'shared/@types'

// ** Import MUI components
import { Box } from '@mui/material'

// ** Import components
import { ReactComponent as ArrowBackIcon } from './arrow-back-icon.svg'
import { ReactComponent as NewsIcon } from './news-icon.svg'
import { ReactComponent as HelpIcon } from './help-icon.svg'
import { ReactComponent as FeedbackIcon } from './feedback-icon.svg'
import { ReactComponent as InfoIcon } from './more-info-icon.svg'
import { ReactComponent as AboutIcon } from './about-icon.svg'
import { ReactComponent as BugIcon } from './bug-icon.svg'
import { ReactComponent as BonusIcon } from './bonus-icon.svg'
import { ReactComponent as ChatIcon } from './chat-icon.svg'
import { ReactComponent as NotificationIcon } from './notification-icon.svg'
import { ReactComponent as PromoIcon } from './promo-icon.svg'
import { ReactComponent as ServiceIcon } from './service-icon.svg'

// ** Import styles
import { LinkItem, Wrapp, ImageContainer, TextName } from './MoreItem.styled'

const VariantIcon = {
  bonus: <BonusIcon />,
  news: <NewsIcon />,
  help: <HelpIcon />,
  feedback: <FeedbackIcon />,
  info: <InfoIcon />,
  bug: <BugIcon />,
  about: <AboutIcon />,
  chat: <ChatIcon />,
  notification: <NotificationIcon />,
  promo: <PromoIcon />,
  service: <ServiceIcon />,
  empty: null
}

export const MoreItem = ({ href, icon, title, pointsElement }: MoreProps): JSX.Element => {
  const variantIcon = VariantIcon[icon]

  return (
    <LinkItem to={href}>
      <Wrapp>
        {icon !== 'empty' ? <ImageContainer sx={{ marginRight: '10px' }}>{variantIcon}</ImageContainer> : null}

        <TextName>{title}</TextName>

        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px', marginLeft: 'auto' }}>
          {pointsElement ? pointsElement : null}

          <ImageContainer
            sx={{
              transform: 'rotate(180deg)'
            }}
          >
            <ArrowBackIcon />
          </ImageContainer>
        </Box>
      </Wrapp>
    </LinkItem>
  )
}
