// ** Import MUI components
import { Box } from '@mui/material'

// ** Import components
import { ReactComponent as DownloadPdfIcon } from './download-pdf-icon.svg'

// ** Import styles
import { RootLink, NamePdf, WeightPdf } from './DownloadPdf.styled'

type DownloadPdfProps = {
  link?: string
  name: string | undefined
  weight?: number | string
}

export const DownloadPdf = ({ link, name, weight }: DownloadPdfProps): JSX.Element => {
  return (
    <Box sx={{ marginBottom: '10px', '&:last-child': { marginBottom: 0 } }}>
      <RootLink href={link} download target='blank'>
        <Box>
          <DownloadPdfIcon width={24} height={24} />
        </Box>

        <Box sx={{ ml: '15px' }}>
          <NamePdf variant='Text-2-M' component='p'>
            {name}
          </NamePdf>
          {weight ? (
            <WeightPdf variant='Text-2-M' component='p'>
              {weight}
            </WeightPdf>
          ) : null}
        </Box>
      </RootLink>
    </Box>
  )
}
