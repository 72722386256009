// ** Import react
import { useState } from 'react'

// ** Import redux
import { useAppSelector } from 'shared/hooks'

// ** Import react-router
import { Link, useNavigation } from 'react-router-dom'

// ** Import swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

// ** Import MUI components
import Snackbar from '@mui/material/Snackbar'
import { useTheme, Avatar, Alert, Box, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

// ** Import components
import { TopNavigation, Loader } from 'shared/ui'
import avatar from './assets/avatar.png'
import { ReactComponent as Copy } from './assets/copy.svg'
import { ReactComponent as CheckIcon } from './assets/check-icon.svg'

// ** Import styles
import {
  S_Container,
  S_Manager,
  S_ManagerName,
  S_ManagerMail,
  S_Copy,
  S_Slider,
  S_Slide,
  S_Text,
  S_Buttons
} from './PersonalManager.styled'

// ** Import API
import { useGetProfileQuery } from 'entities/profile'
import { useSendManagerCallbackMutation } from 'features/feedback'

export const PersonalManager = (): JSX.Element => {
  const theme = useTheme()
  const navigation = useNavigation()
  const [copied, setCopied] = useState(false)
  const defaultCompnay = useAppSelector(state => state.profile.defaultCompany)
  const { data: profileData, isSuccess: isProfileSuccess } = useGetProfileQuery()
  const [
    sendManagerCallback,
    {
      isError: isManagerCallbackError,
      isSuccess: isManagerCallbackSuccess,
      isLoading: isManagerCallbackLoading,
      reset: managerCallbackReset
    }
  ] = useSendManagerCallbackMutation()
  // lala

  const copyLink = (value: string) => {
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)
    textarea.value = value
    textarea.select()
    document.execCommand('copy')
    textarea.remove()
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <>
      {navigation.state === 'loading' || isManagerCallbackLoading ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isProfileSuccess ? (
        <>
          <TopNavigation header='Персональный менеджер' />

          <S_Container>
            <Avatar alt='manager' src={avatar} />
            <S_Manager>
              <S_ManagerName variant='Head-3-B' component='p'>
                Иванов Иван
              </S_ManagerName>

              <a
                href='mailto:demo@mail.ru'
                style={{
                  textDecoration: 'none'
                }}
              >
                <S_ManagerMail variant='Text-1-R' component='p'>
                  hrefdemo@mail.ru
                </S_ManagerMail>
              </a>
            </S_Manager>
            <S_Copy
              type='button'
              onClick={() => {
                copyLink('demo@mail.ru')
              }}
            >
              <Copy />
            </S_Copy>
          </S_Container>

          <S_Slider>
            <Swiper
              modules={[Pagination]}
              allowTouchMove={true}
              slidesPerView={'auto'}
              spaceBetween={8}
              pagination={{
                el: '.swiper-pagination'
              }}
            >
              <SwiperSlide style={{ width: '84%' }}>
                <S_Slide>
                  <S_Text variant='Text-1-R' component='p'>
                    <span style={{ margin: 0 }}>{profileData.name}, приветствую!</span>Я могу помочь тебе быстро
                    оформить лизинг или подобрать технику для бизнеса, а ещё я с удовольствием расскажу про наши
                    действующие скидки от поставщиков и выгодные программы от партнёров. Выбери удобный формат для
                    общения: обратный звонок или сообщение в чат, до связи!
                  </S_Text>
                </S_Slide>
              </SwiperSlide>
              {/* <SwiperSlide style={{ width: '84%' }}>
                <S_Slide>
                  <S_Text variant='Text-1-R' component='p'>
                    <span style={{ margin: 0 }}>{profileData.name}, приветствую!</span>Я могу помочь тебе быстро
                    оформить лизинг или подобрать технику для бизнеса, а ещё я с удовольствием расскажу про наши
                    действующие скидки от поставщиков и выгодные программы от партнёров. Выбери удобный формат для
                    общения: обратный звонок или сообщение в чат, до связи!
                  </S_Text>
                </S_Slide>
              </SwiperSlide>
              <SwiperSlide style={{ width: '84%' }}>
                <S_Slide>
                  <S_Text variant='Text-1-R' component='p'>
                    <span style={{ margin: 0 }}>{profileData.name}, приветствую!</span>Я могу помочь тебе быстро
                    оформить лизинг или подобрать технику для бизнеса, а ещё я с удовольствием расскажу про наши
                    действующие скидки от поставщиков и выгодные программы от партнёров. Выбери удобный формат для
                    общения: обратный звонок или сообщение в чат, до связи!
                  </S_Text>
                </S_Slide>
              </SwiperSlide> */}
              <div slot='container-end' style={{ marginTop: '50px' }}>
                <div className='swiper-pagination'></div>
              </div>
            </Swiper>
          </S_Slider>

          <S_Buttons>
            <Button
              variant='outlined'
              onClick={() => {
                if (defaultCompnay) {
                  sendManagerCallback(defaultCompnay.id)
                }
              }}
            >
              Обратный звонок
            </Button>
            <Button
              variant='outlined'
              component={Link}
              to='/manager-message'
              style={{
                paddingTop: '16px',
                paddingBottom: '16px'
              }}
            >
              Написать
            </Button>
          </S_Buttons>
        </>
      ) : null}

      <Snackbar
        open={copied}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<CheckIcon fontSize='inherit' />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          E-mail скопирован!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isManagerCallbackError}
        onClose={managerCallbackReset}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={false}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          Возникла ошибка
        </Alert>
      </Snackbar>

      <Snackbar
        open={isManagerCallbackSuccess}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset',
            display: 'grid',
            gridTemplateColumns: '1fr 24px',
            columnGap: '8px'
          },

          '& .MuiSnackbarContent-action': {
            paddingLeft: 0,
            marginRight: 0
          }
        }}
        action={
          <div
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              margin: 0,
              padding: 0,
              border: 0
            }}
            onClick={() => {
              managerCallbackReset()
            }}
          >
            <IconButton
              aria-label='close'
              color='inherit'
              onClick={() => {}}
              sx={{
                width: '18px',
                height: '18px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',

                '& .MuiSvgIcon-root': {
                  fontSize: '0.6rem'
                }
              }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
        }
        message='Спасибо за заявку на обратный звонок. Менеджер перезвонит Вам в ближайшее время.'
      />
    </>
  )
}
