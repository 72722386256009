// ** Import react
import { useEffect } from 'react'

// ** Import redux
import { useAppDispatch } from 'shared/hooks'

// // ** Import animation library
// import { PlayState, Tween } from 'react-gsap'

// ** Import MUI components
import { useTheme, Container } from '@mui/system'

// ** Import components
import U from './assets/u.svg'
import L from './assets/l.svg'
import Leasing from './assets/leasing.svg'
import FrontWheel from './assets/front-wheel.svg'
import BackWheel from './assets/back-wheel.svg'

import { S_Truck, S_Trunk, S_Scoop, S_Wheel, S_Leasing } from './Splash.styled'

// ** Import api
import { setIsAnimationPlayed } from 'entities/meta'

export const Splash = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(setIsAnimationPlayed(true))
    }, 3500)
  })

  return (
    <Container
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: theme.palette['Main-2'],
        overflow: 'hidden'
      }}
    >
      <S_Truck>
        <S_Trunk src={U} />
        <S_Scoop src={L} />
        <S_Wheel src={BackWheel} />
        <S_Wheel src={FrontWheel} />
        <S_Leasing>
          <img src={Leasing} alt='Ural Leasing' />
        </S_Leasing>
      </S_Truck>
    </Container>
  )
}
