import { styled } from '@mui/material'
import { ReactComponent as LoaderIcon } from './Loader.svg'

export const S_LoaderIcon = styled(LoaderIcon)<{ width: number }>(props => ({
  '@keyframes rotation': {
    '0%': { transform: 'rotate(0)' },
    '50%': { transform: 'rotate(180deg)' },
    '100%': { transform: 'rotate(360deg)' }
  },
  width: props.width,
  height: props.width,
  animation: 'rotation 2s infinite'
}))
