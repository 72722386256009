import { styled, MenuItem } from '@mui/material'

export const S_Input = styled('input')(props => ({
  outline: 'none',
  width: '100%',
  marginRight: '14px',
  border: 0,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px',
  color: props.theme.palette['Main-1'],
  backgroundColor: 'transparent',

  '&:placeholder': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: props.theme.palette['Sup-3']
  }
}))

export const S_MenuItem = styled(MenuItem)(props => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',

  '&:hover': {
    backgroundColor: 'transparent',
    color: props.theme.palette['Corp-1']
  }
}))
