import { styled, Typography } from '@mui/material'

// ** Import react-router-dom
import { Link } from 'react-router-dom'

export const S_Link = styled(Link, { shouldForwardProp: prop => prop !== 'profile' })<{ profile: boolean }>(props => ({
  margin: 0,
  padding: 0,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  width: '40px',
  textDecoration: 'none',
  backgroundColor: props.profile ? props.theme.palette['Green'] : props.theme.palette['Main-2'],
  color: props.profile ? props.theme.palette['True'] : props.theme.palette['Main-1']
}))

export const S_Phone = styled('a')(props => ({
  margin: 0,
  padding: 0,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  width: '40px',
  textDecoration: 'none',
  backgroundColor: props.theme.palette['Main-2'],
  color: props.theme.palette['Main-1']
}))

export const S_Text = styled(Typography)(props => ({
  textTransform: 'uppercase',
  color: 'inherit'
})) as typeof Typography
