import { ENV } from 'env'
import { baseApi } from 'shared/api'
import { ResponseBugBountyHTML, RequestBugBountyForm } from './types'

export const BugBountyApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBugBounteHtml: build.query<string, void>({
      query: () => ({
        url: `${window.routes5.bugBounty.requests.getHtmlInfoBugBounty[`url${ENV}`]}`,
        params: {
          action: 'getHtmlInfoBugBounty'
        },
        credentials: 'include'
      }),
      transformResponse: (response: ResponseBugBountyHTML) => {
        return response.result
      }
    }),
    sendFormBugBounty: build.mutation<string, RequestBugBountyForm>({
      query: body => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'sendFormBugBounty')
        bodyFormData.append('phone', body.phone)
        bodyFormData.append('message', body.text)
        if (body.files.length > 0) {
          for (let i = 0; i < body.files.length; i++) {
            if (body.files[i] !== null) {
              bodyFormData.append(`file${i}`, body.files[i])
            }
          }
        }
        return {
          url: `${window.routes5.bugBounty.requests.sendFormBugBounty[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData
        }
      },
      transformResponse: (response: { result: any; status: 'success' | 'error' }) => response.status
    })
  })
})

export const { useGetBugBounteHtmlQuery, useSendFormBugBountyMutation } = BugBountyApi
