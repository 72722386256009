import { Typography, Box, IconButton, styled } from '@mui/material'

// ** Import react-router-dom
import { Link } from 'react-router-dom'

export const LinkItem = styled(Link)(props => ({
  textDecoration: 'none',
  color: props.theme.palette['Main-1']
}))

export const Wrapp = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'center',
  padding: '6px 0',
  borderBottom: `1px solid ${props.theme.palette['Sup-1']}`
}))

export const ImageContainer = styled(IconButton)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 0'
})

export const TextName = styled(Typography)(props => ({
  // fontSize: '0.875rem',
  // fontWeight: 500,
  color: props.theme.palette['Main-1']
}))
