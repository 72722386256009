import { ENV } from 'env'
import type { StoriesResponse, Story, StorySlide } from './types.js'
import { baseApi } from 'shared/api'

export const StoriesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getStories: build.query<Story[], void>({
      query: () => ({
        url: `${window.routes5.stories.requests.getStories[`url${ENV}`]}`
      }),
      transformResponse: (response: StoriesResponse) => {
        const transformedResponse: Story[] = []

        Object.values(response.ITEMS).forEach(responseStory => {
          const story: Story = {
            name: responseStory.NAME,
            previewPicture: responseStory.PICTURE_SRC,
            slides: []
          }

          Object.values(responseStory.ITEMS).forEach(responseStorySlide => {
            const storySlide = {} as StorySlide

            storySlide.name = responseStorySlide.NAME
            storySlide.title = responseStorySlide.TITLE
            storySlide.subtitle = responseStorySlide.SUBTITLE
            storySlide.text = responseStorySlide.TEXT
            storySlide.img = responseStorySlide.IMG
            storySlide.background = responseStorySlide.BG
            storySlide.location = responseStorySlide.BTN_ATTR.LINK

            story.slides.push(storySlide)
          })

          transformedResponse.push(story)
        })

        return transformedResponse
      }
    })
  })
})

export const { useGetStoriesQuery } = StoriesApi
