import { styled, Typography } from '@mui/material'

export const S_Link = styled('button', { shouldForwardProp: prop => prop !== 'status' })<{ status: 'viewed' | 'new' }>(
  props => ({
    display: 'block',
    margin: 0,
    height: 'calc(100% - 8px)',
    padding: '3px',
    border: `1px solid ${props.status === 'new' ? props.theme.palette['Corp-2'] : props.theme.palette['Sup-2']}`,
    borderRadius: '12px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  })
)

export const S_Picture = styled('div')({
  position: 'relative',
  height: '100%',
  margin: 0,
  padding: 0,

  '&:before': {
    bottom: 0,
    position: 'absolute',
    width: '100%',
    height: '50%',
    borderRadius: '8px',
    background: 'linear-gradiend(180deg, rgba(17, 17, 17, 0), 0%, rgba(17, 17, 17, 0.6) 100%)',
    content: "''"
  },
  '& img': {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '12px'
  }
})
// p 'Small-text-2-R'
export const S_Text = styled(Typography)(props => ({
  position: 'absolute',
  left: '10px',
  bottom: '10px',
  margin: 0,
  padding: 0,
  color: props.theme.palette['Main-2']
})) as typeof Typography
