// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import {
  useTheme,
  Box
  // Tab
} from '@mui/material'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'
import { Advert } from './ui/Advert'

// ** Import api
import { useGetAdvertsQuery } from 'entities/advert'

export const Adverts = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()

  const { data, isSuccess } = useGetAdvertsQuery()

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <>
          <TopNavigation header='Акции' back={() => navigate(-1)} />

          <Box
            sx={{
              backgroundColor: theme.palette['Main-2'],
              paddingTop: '20px',
              paddingRight: '16px',
              paddingBotom: '5px',
              paddingLeft: '16px'
            }}
          >
            {data.map(advert => (
              <Advert navigationRoute={`/more/adverts/advert-detail/${advert.id}`} {...advert} key={advert.id} />
            ))}
          </Box>
        </>
      ) : null}
    </>
  )
}
