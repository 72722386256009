// ** Import react
import { useEffect } from 'react'

// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import redux
import { useAppDispatch, useAppSelector } from 'shared/hooks'

// ** Import MUI components
import { useTheme, Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useGetAllNewsQuery } from 'entities/news'

// ** Import components
import { Loader, NewsCard, TopNavigation } from 'shared/ui'

// ** Import api
import { setNewsPage, setCurrentArticlesPage } from 'entities/meta/model/model'

export const News = (): JSX.Element => {
  const YearForNews = useAppSelector(store => store.meta.newsPage)
  const { data, isSuccess } = useGetAllNewsQuery()
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const CURRENT_YEAR = new Date().getFullYear()

  //set previous year if there is no news in current year
  useEffect(() => {
    if (isSuccess && data.actualYear && data.actualYear.length === 0) {
      dispatch(setNewsPage('lastYear'))
    }
  })

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      {isSuccess ? (
        <Box sx={{ background: theme.palette['Main-2'] }}>
          <TopNavigation header='Новости' back={() => navigate(-1)} />

          <TabContext value={data.actualYear && data.actualYear.length > 0 ? YearForNews : 'lastYear'}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList aria-label='lab API tabs example' variant='fullWidth'>
                {data['actualYear']?.length !== 0 ? (
                  <Tab
                    sx={{
                      fontSize: '0.75rem',
                      '&.Mui-selected ': {
                        color: theme.palette['Main-1']
                      }
                    }}
                    label={CURRENT_YEAR}
                    value='actualYear'
                    onClick={() => dispatch(setNewsPage('actualYear'))}
                  />
                ) : null}
                {data['lastYear']?.length !== 0 ? (
                  <Tab
                    sx={{
                      fontSize: '0.75rem',
                      textTransform: 'none',
                      '&.Mui-selected ': {
                        color: theme.palette['Main-1']
                      }
                    }}
                    label={CURRENT_YEAR - 1}
                    value='lastYear'
                    onClick={() => dispatch(setNewsPage('lastYear'))}
                  />
                ) : null}
                {data['archive']?.length !== 0 ? (
                  <Tab
                    sx={{
                      fontSize: '0.75rem',
                      '&.Mui-selected ': {
                        color: theme.palette['Main-1']
                      }
                    }}
                    label='Архив'
                    value='archive'
                    onClick={() => dispatch(setNewsPage('archive'))}
                  />
                ) : null}
              </TabList>
            </Box>

            <TabPanel
              value={YearForNews}
              sx={{
                padding: '20px  16px'
              }}
            >
              {data[YearForNews] !== undefined
                ? data[YearForNews]?.map(item => (
                    <Box key={item.ID} sx={{ marginBottom: '20px' }}>
                      <NewsCard
                        navigationRoute={item.ID.toString()}
                        {...item}
                        onClick={() => {
                          dispatch(setCurrentArticlesPage('news'))
                        }}
                      />
                    </Box>
                  ))
                : null}
            </TabPanel>
          </TabContext>
        </Box>
      ) : null}
    </>
  )
}
