import { ENV } from 'env'
import type {
  RequestData,
  RequestResponse,
  RequestDetailData,
  RequestDetailResponse,
  InsuranceResponse
} from './types.js'
import { baseApi } from 'shared/api'
import { formatter, formatDate } from 'shared/utils'
import { closestIndexTo } from 'date-fns'

export const RequestApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getRequests: build.query<RequestData[], number>({
      query: companyId => {
        return {
          url: `${window.routes5.requests.requests.getRequests[`url${ENV}`]}`,
          params: {
            action: 'getAllData',
            company_id: companyId
          },
          credentials: 'include',
          validateStatus: (response, result: RequestResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: RequestResponse) => {
        const transformedResponse: RequestData[] = []

        response.result.forEach(request => {
          transformedResponse.push({
            id: request.ID,
            statusText: request.STATUS_DISAPPEARS.UF_NAME,
            statusColor: request.STATUS_DISAPPEARS.UF_COLOR,
            price: formatter.format(Number(request.SUM.replace(',', '.'))),
            duration: request.LEASING_DURATION,
            date: request.DATE_TREAT,
            contract: request.NUMBER_TREAT,
            images: request.SLIDES,
            ...(request.MODEL_AND_MARK && { model: request.MODEL_AND_MARK.UF_NAME })
          })
        })

        return transformedResponse
      }
    }),
    getRequest: build.query<RequestDetailData, { id: string; companyId: number }>({
      query: data => {
        return {
          url: `${window.routes5.requests.requests.getRequestData[`url${ENV}`]}`,
          params: {
            action: 'getData',
            id: data.id,
            company_id: data.companyId
          },
          credentials: 'include',
          validateStatus: (response, result: RequestDetailResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: RequestDetailResponse) => {
        const transformedResponse = {} as RequestDetailData

        transformedResponse.id = response.result.ID
        transformedResponse.header = response.result.NAME
        transformedResponse.status = {
          color: `#${response.result.PROP.STATUS_DISAPPEARS.UF_COLOR}`,
          text: response.result.PROP.STATUS_DISAPPEARS.UF_NAME
        }

        if (response.result.PROP.SUM) {
          transformedResponse.sum = Number(response.result.PROP.SUM.replace(',', '.'))
        }

        if (response.result.PROP.MODEL_AND_MARK) {
          transformedResponse.model = response.result.PROP.MODEL_AND_MARK.UF_NAME
        }

        if (response.result.PROP.PAYMENT_SUM) {
          transformedResponse.payment_sum = Number(response.result.PROP.PAYMENT_SUM.replace(',', '.'))
        }

        transformedResponse.treat = response.result.PROP.NUMBER_TREAT
        transformedResponse.treat_date = response.result.PROP.DATE_TREAT
        transformedResponse.leasing_duration = response.result.PROP.LEASING_DURATION

        if (response.result.PROP.REDEMPTION_SCHEDULE && response.result.PROP.REDEMPTION_SCHEDULE.length > 0) {
          //find closest redemtion date to current day
          const redemptionSchedule: Date[] = []

          response.result.PROP.REDEMPTION_SCHEDULE.forEach(value => {
            const date = new Date(formatDate(value.DATE))
            redemptionSchedule.push(date)
          })

          const closestRedemtionDayIndex = () => {
            const foundIndex = closestIndexTo(new Date().setMonth(new Date().getMonth() + 1), redemptionSchedule)

            if (!foundIndex) {
              return 0
            }

            return foundIndex
          }
          //find closest redemtion date to current day

          transformedResponse.redemption_value = formatter.format(
            Number(response.result.PROP.REDEMPTION_SCHEDULE[closestRedemtionDayIndex()].PRICE)
          )
          transformedResponse.redemption_date = `до ${
            response.result.PROP.REDEMPTION_SCHEDULE[closestRedemtionDayIndex()].DATE
          }`
        }

        //find closest payment date to current day
        const daysSchedule: Date[] = []

        response.result.PROP.PAYMENT_SCHEDULE.forEach(value => {
          const date = new Date(formatDate(value.DATE))
          daysSchedule.push(date)
        })

        const closestDayIndex = () => {
          const foundIndex = closestIndexTo(new Date(), daysSchedule)

          if (!foundIndex) {
            return 0
          }

          return foundIndex
        }
        //find closest payment date to current day

        transformedResponse.next_payment = formatter.format(
          Number(response.result.PROP.PAYMENT_SCHEDULE[closestDayIndex()].PRICE)
        )

        transformedResponse.next_payment_date = response.result.PROP.DATE_END_NEXT_PAYMENT
        transformedResponse.remaining_payments = formatter.format(
          Number(response.result.PROP.REMAINING_PAYMENTS.replace(',', '.'))
        )
        transformedResponse.insurance = {
          text: response.result.PROP.INSURANCE.UF_NAME,
          phone: response.result.PROP.INSURANCE.UF_PHONE,
          site: response.result.PROP.INSURANCE.UF_SITE
        }
        transformedResponse.docs = []
        transformedResponse.payment_schedule = []

        response.result.PROP.DOCS.forEach(doc => {
          transformedResponse.docs.push({
            name: doc.FILE.ORIGINAL_NAME,
            src: doc.FILE.SRC,
            size: (Number(doc.FILE.FILE_SIZE) / 1000000).toFixed(2)
          })
        })

        transformedResponse.policy = []

        response.result.PROP.POLICY.forEach(policy => {
          if (typeof policy.FILE === 'object') {
            transformedResponse.policy.push({
              name: policy.FILE.ORIGINAL_NAME,
              src: policy.FILE.SRC,
              size: (Number(policy.FILE.FILE_SIZE) / 1000000).toFixed(2)
            })
          }
        })

        if (response.result.PROP.CONTRACT_DOC.length > 0) {
          transformedResponse.contracts = []

          response.result.PROP.CONTRACT_DOC.forEach(contract_doc => {
            if (transformedResponse.contracts) {
              transformedResponse.contracts.push({
                name: contract_doc.FILE.ORIGINAL_NAME,
                src: contract_doc.FILE.SRC,
                size: (Number(contract_doc.FILE.FILE_SIZE) / 1000000).toFixed(2)
              })
            }
          })
        }

        if (response.result.PROP.ACCOMPANYING.length > 0) {
          transformedResponse.accompanying = []

          response.result.PROP.ACCOMPANYING.forEach(accompanying => {
            if (transformedResponse.accompanying) {
              transformedResponse.accompanying.push({
                name: accompanying.FILE.ORIGINAL_NAME,
                src: accompanying.FILE.SRC,
                size: (Number(accompanying.FILE.FILE_SIZE) / 1000000).toFixed(2)
              })
            }
          })
        }

        response.result.PROP.PAYMENT_SCHEDULE.forEach(payment => {
          transformedResponse.payment_schedule.push({
            date: payment.DATE,
            price: payment.PRICE,
            payment: payment.PAYMENT
          })
        })

        return transformedResponse
      }
    }),
    sendInsuranceCase: build.mutation<void, { id: string; companyId: number }>({
      query: data => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'callBackInsurance')
        bodyFormData.append('id', data.id)
        bodyFormData.append('company_id', `${data.companyId}`)
        return {
          url: `${window.routes5.requests.requests.sendInsuranceCase[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: InsuranceResponse) => response.status === 200 && result.status !== 'error'
        }
      }
    })
  })
})

export const { useGetRequestsQuery, useGetRequestQuery, useSendInsuranceCaseMutation } = RequestApi
