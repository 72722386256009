import { createRoot } from 'react-dom/client'

//redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'app/model/store'

// styles
import { theme } from 'shared/theme'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// import App
import App from 'app'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PersistGate loading={null} persistor={persistor}>
        <App store={store} />
      </PersistGate>
    </ThemeProvider>
  </Provider>
)
