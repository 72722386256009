import type { MenuLink } from 'shared/ui'

// ** Import react-router-dom
import { Outlet } from 'react-router-dom'

// ** Import components
import { Menu } from '../../ui'
import { S_MainLayout, S_Outlet } from './MainLayout.styled'

export const MainLayout = ({ links }: { links: MenuLink[] }): JSX.Element => {
  return (
    <S_MainLayout>
      <S_Outlet>
        <Outlet />
      </S_Outlet>

      <Menu links={links} />
    </S_MainLayout>
  )
}
