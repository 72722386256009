import { styled } from '@mui/material'

import ReactCodeInput from 'react-verification-code-input'

export const S_Code = styled(ReactCodeInput)<{ status: string | undefined; pin: boolean }>(props => ({
  margin: '0 auto',

  '& .styles_react-code-input__CRulA': {
    display: 'flex',

    '& > input': {
      paddingRight: '20px',
      paddingLeft: '20px',
      border: 'none',
      borderRadius: 'none',
      display: 'flex',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '1.375rem',
      lineHeight: '1.6875rem',
      alignItems: 'center',
      textAlign: 'center',
      // color: props.status === 'Неверный код подтверждения' ? props.theme.palette.False : props.theme.palette['Main-1'],
      color: props.pin
        ? 'transparent'
        : props.status === 'Неверный код подтверждения'
        ? props.theme.palette.False
        : props.theme.palette['Main-1'],
      outline: 'none',
      transition: '0.3s',
      caretColor: `${props.theme.palette['Main-1']} !important`,

      '&:first-of-type': {
        borderRadius: 0,
        paddingLeft: 0
      },
      '&:last-of-type': {
        borderRadius: 0,
        paddingRight: 0
      },
      '&::placeholder': {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '1.375rem',
        lineHeight: '1.6875rem',
        color: props.theme.palette['Sup-1']
      },
      '&:not(:placeholder-shown)': {
        background: props.pin ? 'radial-gradient(#000 10%, #fff 14%)' : 'transparent'
      }
    }
  }
}))
