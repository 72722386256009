import { ENV } from 'env'
import type { BonusResponse, PhoneResponse } from './types.js'
import { baseApi } from 'shared/api'

export const BonusApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBonus: build.query<BonusResponse['result'], void>({
      query: () => {
        return {
          url: `${window.routes5.bonuses.requests.getBonuses[`url${ENV}`]}`,
          params: {
            action: 'getBonuses'
          },
          credentials: 'include',
          validateStatus: (response, result: BonusResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: BonusResponse) => response.result
    }),
    sendBonusPhone: build.mutation<PhoneResponse, string>({
      query: phone => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'send_phone_bonus')
        bodyFormData.append('phone', phone)
        return {
          url: `${window.routes5.bonuses.requests.sendPhone[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: PhoneResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as PhoneResponse

          if (serverResponse.status === 'error') {
            if (serverResponse.result) {
              return serverResponse.result.sms_time
            } else {
              return serverResponse.text
            }
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    }),
    sendBonusSms: build.mutation<
      'error' | 'success',
      {
        code: string
        phone: string
      }
    >({
      query: values => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'sms_code_bonus')
        bodyFormData.append('phone', values.phone)
        bodyFormData.append('code', values.code)
        return {
          url: `${window.routes5.bonuses.requests.sendSms[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: PhoneResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: PhoneResponse) => response.status,
      transformErrorResponse: response => {
        if (response.status === 200) {
          const serverResponse = response.data as PhoneResponse

          if (serverResponse.status === 'error') {
            if (serverResponse.result) {
              return `Превышен лимит запросов! Вы можете повторить попытку через ${serverResponse.result.sms_time} секунд`
            } else {
              return serverResponse.text
            }
          }
        }

        return 'Не удалось отправить запрос, повторите попытку'
      }
    })
  })
})

export const { useGetBonusQuery, useSendBonusPhoneMutation, useSendBonusSmsMutation } = BonusApi
