import { styled } from '@mui/material'

export const S_Menu = styled('section')(props => ({
  position: 'fixed',
  zIndex: 10,
  bottom: 0,
  left: 0,
  right: 0,
  margin: '0 auto',
  padding: 0,
  display: 'grid',
  width: '100%',
  maxWidth: '425px',
  gridTemplateColumns: 'repeat(auto-fit, minmax(64px, 1fr))',
  backgroundColor: props.theme.palette['Main-2'],
  boxShadow: '0px -5px 30px rgba(0, 0, 0, 0.05)'
}))
