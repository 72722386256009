// ** Import react-router
import { Link } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography } from '@mui/material'

// ** Import utils
import { format } from 'date-fns'

type AdvertProps = {
  navigationRoute: string
  image: string
  text: string
  from?: string
  to: string
}

const TEXT_LENGTH = 35

export const Advert = ({ navigationRoute, image, text, from, to }: AdvertProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Link to={navigationRoute} style={{ display: 'block', textDecoration: 'none', marginBottom: '20px' }}>
      <Box
        sx={{
          marginBottom: '10px',
          borderRadius: '10px',
          overflow: 'hidden',
          height: '165px',

          '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }
        }}
      >
        <img src={image} alt='advert' />
      </Box>

      <Typography variant='Text-1-M' component='p' sx={{ color: theme.palette['Main-1'], marginBottom: '5px' }}>
        {text.length > TEXT_LENGTH ? `${text.slice(0, TEXT_LENGTH)}...` : text}
      </Typography>

      {from ? (
        <Typography variant='Text-2-M' component='span' sx={{ color: theme.palette['Sup-2'] }}>
          C {format(new Date(from), 'dd.MM.yyyy')} 
        </Typography>
      ) : null}

      <Typography variant='Text-2-M' component='span' sx={{ color: theme.palette['Sup-2'] }}>
        {from ? 'по' : 'До'} {format(new Date(to), 'dd.MM.yyyy')}
      </Typography>
    </Link>
  )
}
