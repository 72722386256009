// ** Import react
import { useState } from 'react'

// ** Import redux
import { useAppDispatch } from 'shared/hooks'

// ** Import react-router
import { useNavigate } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Dialog, DialogActions, Box, Typography, Button, IconButton } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

// ** Import styles
import { S_Container, S_Header, S_LogoutLink } from './TopNavigation.styled'

import { ReactComponent as ExitIcon } from './exit-icon.svg'

// ** Import API
import { useSendLogoutMutation } from 'entities/auth'
import { resetMeta } from 'entities/meta'

type TopNavigationProps = {
  header: string
  icon?: string
  back?: () => void
  logout?: () => void
  background?: string
  color?: string
}

export const TopNavigation = ({ header, icon, back, logout, background, color }: TopNavigationProps): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [close, setClose] = useState<boolean>(false)
  const [sendLogout] = useSendLogoutMutation()

  return (
    <>
      <S_Container
        sx={{
          backgroundColor: background ? background : theme.palette['Main-2'],
          borderBottom: `1px solid ${background ? 'rgb(239, 242, 245, 0.1)' : theme.palette['Sup-1']}`
        }}
      >
        {back ? (
          <IconButton onClick={back} sx={{ margin: 0, padding: 0, height: '24px', width: '24px' }}>
            <ArrowBackIosNewIcon
              sx={{
                width: '18px',
                height: '18px',
                color: color ? color : theme.palette['Main-1']
              }}
            />
          </IconButton>
        ) : null}

        <S_Header variant='Head-2-B' component='h2' sx={{ color: color ? color : theme.palette['Main-1'] }}>
          {icon ? (
            <img
              style={{
                display: 'inline-block',
                width: '34px',
                height: '34px',
                borderRadius: '50%',
                marginRight: '8px',
                verticalAlign: 'middle'
              }}
              src={icon}
              alt='notification icon'
            />
          ) : null}

          {header}
        </S_Header>

        {logout ? (
          <S_LogoutLink
            onClick={() => {
              setClose(true)
            }}
          >
            <ExitIcon style={{ stroke: color ? color : theme.palette['Main-1'] }} />
          </S_LogoutLink>
        ) : null}
      </S_Container>

      {logout ? (
        <Dialog
          open={close}
          onClose={() => {
            setClose(false)
          }}
          sx={{
            maxWidth: '352px',
            margin: '0 auto',
            '& .MuiPaper-root': {
              borderRadius: '10px',
              marginRight: '16px',
              marginLeft: '16px',
              width: '100%'
            }
          }}
        >
          <Box sx={{ p: '20px' }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1rem',
                lineHeight: '1.1875rem',
                mb: '10px'
              }}
            >
              Вы точно хотите выйти?
            </Typography>

            <DialogActions
              sx={{
                p: 0,
                marginTop: '25px',
                '& .MuiButton-contained': { marginLeft: '14px' }
              }}
            >
              <Button
                aria-label='no'
                variant='outlined'
                type='button'
                onClick={() => {
                  setClose(false)
                }}
              >
                Нет
              </Button>
              <Button
                aria-label='yes'
                variant='contained'
                onClick={() => {
                  sendLogout()
                    .unwrap()
                    .then(res => {
                      if (res === 'success') {
                        dispatch(resetMeta())
                        logout()
                        navigate('/authentication')
                      }
                    })
                    .catch(() => {})
                }}
              >
                Выйти
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      ) : null}
    </>
  )
}
