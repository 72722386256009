// ** Import MUI components
import { styled, Box } from '@mui/material'

export const S_Truck = styled(Box)({
  '@keyframes roll-in': {
    '100%': { transform: 'translateX(0px) rotate(0deg)' }
  },
  position: 'absolute',
  display: 'flex',
  flexWrap: 'wrap',
  gridGap: '6px',
  width: '88px',
  height: '88px',
  transform: 'translateX(-280px) rotate(-45deg)',
  animationName: 'roll-in',
  animationDuration: '1s',
  animationDelay: '0.5s',
  animationFillMode: 'forwards'
})

export const S_Trunk = styled('img')({
  '@keyframes load-up': {
    '0%': { transform: 'rotate(-45deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  width: '40px',
  height: '40px',
  transform: 'rotate(-45deg)',
  animationName: 'load-up',
  animationDuration: '0.5s',
  animationDelay: '1s',
  animationFillMode: 'forwards'
})

export const S_Scoop = styled('img')({
  '@keyframes take-place': {
    '0%': { transform: 'rotate(180deg) translate(-10px,-20px)' },
    '50%': { transform: 'rotate(0deg) translate(0px,-20px)' },
    '100%': { transform: 'rotate(0deg) translate(0px, 0px)' }
  },
  width: '40px',
  height: '40px',
  transform: 'rotate(180deg) translate(-10px,-20px)',
  animationName: 'take-place',
  animationDuration: '1.5s',
  animationDelay: '1s',
  animationFillMode: 'forwards'
})

export const S_Leasing = styled('div')(props => ({
  '@keyframes revial': {
    '0%': { opacity: '0' },
    '100%': { opacity: '1' }
  },
  position: 'absolute',
  bottom: '0',
  display: 'flex',
  width: '88px',
  height: '20px',
  opacity: '0',
  animationName: 'revial',
  animationDuration: '0.5s',
  animationDelay: '2.5s',
  animationFillMode: 'forwards',
  backgroundColor: props.theme.palette['Main-2'],

  '& img': {
    width: '100%'
  }
}))

export const S_Wheel = styled('img')({
  width: '40px',
  height: '40px'
})
