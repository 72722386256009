import { ENV } from 'env'
import { baseApi } from 'shared/api'
import type { ManagerCallbackResponse } from './types'

export const FeedbackApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    sendManagerCallback: build.mutation<string, number>({
      query: companyID => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'callBackManager')
        bodyFormData.append('company_id', `${companyID}`)
        return {
          url: `${window.routes5.feedback.requests.sendManagerCallback[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: ManagerCallbackResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      }
    }),
    sendFeedbackMessage: build.mutation<string, { phone: string; text: string }>({
      query: values => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'callBack')
        bodyFormData.append('phone', values.phone)
        bodyFormData.append('text', values.text)
        return {
          url: `${window.routes5.feedback.requests.sendFeedbackMessage[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: ManagerCallbackResponse) =>
            response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: ManagerCallbackResponse) => response.status
    })
  })
})

export const { useSendManagerCallbackMutation, useSendFeedbackMessageMutation } = FeedbackApi
