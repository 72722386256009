import { ENV } from 'env'
import type { Story as StoryType } from 'entities/stories/api/types'

// ** Import react
import { useState } from 'react'

// ** Import swiper
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

// ** Import stories
import Stories from 'react-insta-stories'
import { Story } from 'react-insta-stories/dist/interfaces'

// ** Import components
import { InstaStory } from '../InstaStory/InstaStory'

// ** Import styles
import { S_Container } from './Stories.styled'

// ** Import api
import { useGetStoriesQuery } from 'entities/stories/api/api'

export const InstaStories = (): JSX.Element => {
  const { data } = useGetStoriesQuery()
  const [activeStories, setActiveStories] = useState<Story[]>([])

  return (
    <>
      {activeStories.length > 0 ? (
        <div
          style={{
            top: 0,
            left: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 12
          }}
        >
          <Stories
            stories={activeStories}
            defaultInterval={1500}
            width='100%'
            height='100%'
            storyStyles={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              margin: '0 auto'
            }}
            onAllStoriesEnd={() => {
              setActiveStories([])
            }}
          />
        </div>
      ) : null}
      <Swiper slidesPerView={3} spaceBetween={8} style={{ paddingRight: '16px' }}>
        {data?.map((slide: StoryType, index: number) => (
          <SwiperSlide key={index}>
            <InstaStory
              stories={slide.slides}
              image={`${window.routes5[`media${ENV}`]}${slide.previewPicture}`}
              text={slide.name}
              setStories={setActiveStories}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
