import { styled, Snackbar } from '@mui/material'

export const S_Snackbar = styled(Snackbar)(props => ({
  bottom: '95px',
  '& .MuiAlert-root': {
    paddingTop: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    borderRadius: '10px',
    background: 'rgba(33, 33, 33, 0.8)',
    backdropFilter: 'blur(15px)',
    alignItems: 'center',

    '& .MuiAlert-icon': {
      padding: 0,
      marginRight: '8px',
      color: props.theme.palette['Main-2']
    }
  },

  '& .MuiAlert-message': {
    padding: 0,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '15px',
    color: props.theme.palette['Main-2']
  }
}))
