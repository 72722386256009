import { ENV } from 'env'
import type { OnboardingResponse, Slides } from './types.js'
import { baseApi } from 'shared/api'

export const OnboardingApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getOnboardingData: build.query<Slides, void>({
      query: () => ({
        url: `${window.routes5.onboarding.requests.getSlides[`url${ENV}`]}`,
        validateStatus: (response, result: OnboardingResponse) => response.status === 200 && result.status !== 'error'
      }),
      transformResponse: (response: OnboardingResponse) => response.result
    })
  })
})

export const { useGetOnboardingDataQuery } = OnboardingApi
