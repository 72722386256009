// * react
import { useState } from 'react'

// * MUI
import { useTheme, Typography, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// * styles
import { S_Box, S_Link } from './Ad.styled'

type AdProps = {
  image?: string
  text: string
  link?: string
}

export const Ad = ({ image, text, link }: AdProps) => {
  const theme = useTheme()
  const [isShowing, setIsShowing] = useState<boolean>(true)

  return (
    <>
      {isShowing ? (
        link ? (
          <Box sx={{ position: 'relative' }}>
            <IconButton
              aria-label='close'
              color='inherit'
              onClick={() => {
                setIsShowing(false)
              }}
              sx={{
                position: 'absolute',
                zIndex: 1,
                top: '5px',
                right: '20px',
                width: '18px',
                height: '18px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',

                '& .MuiSvgIcon-root': {
                  fontSize: '0.6rem'
                }
              }}
            >
              <CloseIcon fontSize='small' htmlColor={theme.palette['Main-2']} />
            </IconButton>
            <S_Link href={link} target='_blank' rel='noopener' image={image}>
              <Typography
                variant='Text-2-M'
                component='p'
                sx={{ color: theme.palette['Main-2'], position: 'relative' }}
              >
                {text}
              </Typography>
            </S_Link>
          </Box>
        ) : (
          <S_Box image={image}>
            <IconButton
              aria-label='close'
              color='inherit'
              onClick={() => {
                setIsShowing(false)
              }}
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                width: '18px',
                height: '18px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',

                '& .MuiSvgIcon-root': {
                  fontSize: '0.6rem'
                }
              }}
            >
              <CloseIcon fontSize='small' htmlColor={theme.palette['Main-2']} />
            </IconButton>

            <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Main-2'], position: 'relative' }}>
              {text}
            </Typography>
          </S_Box>
        )
      ) : null}
    </>
  )
}
