import type { ProfileData } from 'entities/profile'

// ** react
import { useState } from 'react'

// ** redux
import { useAppSelector } from 'shared/hooks'

// ** react-router
import { useNavigate, useNavigation, useLocation } from 'react-router-dom'

// ** Import formik
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// ** MUI components
import {
  useTheme,
  Box,
  TextField,
  Button,
  Slider,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// ** components
import { TopNavigation, Loader, PhoneInput } from 'shared/ui'
import { ReactComponent as CheckIcon } from './check-icon.svg'

// ** utils
import { formatter, toNumber } from 'shared/utils'

// ** API
import { useGetProfileQuery } from 'entities/profile'
import { useGetPercentagesQuery, useSendCalculationRequestMutation } from 'entities/calculator'

type LocationData = { id: string; min: number; max: number } | null

type Percentages = {
  interestRateOne: number
  interestRateTwo: number
  interestRateThree: number
}

const validationSchemaGuest = Yup.object().shape({
  totalPrice: Yup.number().required(),
  initialDeposit: Yup.number().required(),
  leasingDuration: Yup.number().required(),
  interestRateOne: Yup.number().required(),
  interestRateTwo: Yup.number().required(),
  interestRateThree: Yup.number().required(),
  monthlyPayment: Yup.number().required(),
  VATpart: Yup.number().required(),
  yearAppreciation: Yup.number().required(),
  inn: Yup.number().required(),
  name: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required()
})

const validationSchemaAuth = Yup.object().shape({
  totalPrice: Yup.number().required(),
  initialDeposit: Yup.number().required(),
  leasingDuration: Yup.number().required(),
  interestRateOne: Yup.number().required(),
  interestRateTwo: Yup.number().required(),
  interestRateThree: Yup.number().required(),
  monthlyPayment: Yup.number().required(),
  VATpart: Yup.number().required(),
  yearAppreciation: Yup.number().required(),
  inn: Yup.number().required(),
  name: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().notRequired()
})

const calculateMonthlyPayment = (
  totalPrice: number,
  initialDepositPercentage: number,
  leasingDuration: number,
  interestRateOne: number,
  interestRateTwo: number,
  interestRateThree: number
): number => {
  const nds = 20
  const rate = (-interestRateOne / 100 / 12) * (1 + nds / 100)
  const numberOfInterestPeriods = leasingDuration
  const presentValue =
    totalPrice * (1 - initialDepositPercentage / 100) + (interestRateThree / 100) * totalPrice * (1 + nds / 100)
  const monthlyPayment = -(
    (rate * presentValue) /
    (1 - Math.pow(1 + (interestRateOne / 100 / 12) * (1 + nds / 100), -numberOfInterestPeriods))
  )

  return Math.round(monthlyPayment + (interestRateTwo / 100 / 12) * totalPrice * (1 + nds / 100))
}

const calculateVatPart = (initialDeposit: number, monthlyPayment: number, leasingDuration: number): number =>
  ((initialDeposit + monthlyPayment * leasingDuration) / 1.2) * 0.2

const calculateYearAppreciation = (
  initialDeposit: number,
  monthlyPayment: number,
  leasingDuration: number,
  totalPrice: number
): number =>
  Number(
    ((((initialDeposit + monthlyPayment * leasingDuration) / totalPrice - 1) / leasingDuration) * 12 * 100).toFixed(2)
  )

class InitialValues {
  //user
  totalPrice: number
  initialDeposit: number
  initialDepositPercentage: number
  leasingDuration: number

  // server
  interestRateOne: number
  interestRateTwo: number
  interestRateThree: number

  //calculated
  monthlyPayment: number
  VATpart: number
  yearAppreciation: number

  id: string | null
  defaultCompanyID: number
  inn: string
  name: string
  phone: string
  email: string

  constructor(locationData: LocationData, percentages: Percentages, companyID: number, data?: ProfileData) {
    // user
    this.totalPrice = locationData ? locationData.min : 500000
    this.initialDeposit = locationData ? 0.05 * locationData.min : 0.05 * 500000
    this.initialDepositPercentage = locationData ? (this.initialDeposit / this.totalPrice) * 100 : 5
    this.leasingDuration = 60

    // server
    this.interestRateOne = percentages.interestRateOne
    this.interestRateTwo = percentages.interestRateTwo
    this.interestRateThree = percentages.interestRateThree

    //calculated
    this.monthlyPayment = calculateMonthlyPayment(
      this.totalPrice,
      this.initialDepositPercentage,
      this.leasingDuration,
      this.interestRateOne,
      this.interestRateTwo,
      this.interestRateThree
    )
    this.VATpart = calculateVatPart(this.initialDeposit, this.monthlyPayment, this.leasingDuration)
    this.yearAppreciation = calculateYearAppreciation(
      this.initialDeposit,
      this.monthlyPayment,
      this.leasingDuration,
      this.totalPrice
    )

    this.id = locationData ? locationData.id : null
    this.defaultCompanyID = companyID
    this.inn = data ? data.defaultCompany.inn : ''
    this.name = data ? data.name : ''
    this.phone = data ? data.phone : ''
    this.email = data ? data.email : ''
  }
}

export const Calculator = () => {
  const theme = useTheme()
  const navigation = useNavigation()
  const location = useLocation()
  const navigate = useNavigate()
  const isAuth = useAppSelector(state => state.meta.isAuth)
  const defaultCompnay = useAppSelector(state => state.profile.defaultCompany)
  const { data } = useGetProfileQuery(undefined, { skip: !isAuth })
  const { data: percentages, isSuccess: isPercentagesSuccess } = useGetPercentagesQuery()
  const [sendCalculationRequest, { isSuccess: isCalculationRequestSuccess, isLoading }] =
    useSendCalculationRequestMutation()
  const [close, setClose] = useState<boolean>(false)
  const locationData: LocationData = location.state
  let initialValues

  if (isPercentagesSuccess) {
    initialValues = new InitialValues(locationData, percentages, defaultCompnay ? defaultCompnay.id : 0, data)
  } else {
    initialValues = new InitialValues(
      locationData,
      { interestRateOne: 9, interestRateTwo: 3, interestRateThree: 0.1 },
      defaultCompnay ? defaultCompnay.id : 0,
      data
    )
  }

  return (
    <>
      {navigation.state === 'loading' || isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1301,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <>
        <TopNavigation
          header='Расчет стоимости'
          back={() => {
            navigate(-1)
          }}
        />
        <Box
          sx={{
            backgroundColor: theme.palette['Main-2'],
            paddingRight: '16px',
            paddingLeft: '16px',
            paddingTop: '20px',
            paddingBottom: '105px'
          }}
        >
          <Formik
            validationSchema={isAuth ? validationSchemaAuth : validationSchemaGuest}
            initialValues={initialValues}
            onSubmit={values => {
              sendCalculationRequest(values)
                .unwrap()
                .then(data => {
                  if (data === 'success') {
                    setClose(false)
                    setTimeout(() => {
                      navigate('/catalog')
                    }, 1500)
                  }
                })
                .catch(() => {})
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue, submitForm }) => (
              <>
                <Form>
                  <TextField
                    value={values.totalPrice}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onChange={handleChange}
                    onBlur={evt => {
                      const formattedInput = toNumber(evt.target.value)

                      let value = formattedInput

                      setFieldValue('initialDepositPercentage', (values.initialDeposit / value) * 100)
                      let initialDeposit = values.initialDeposit

                      // if the field is empty set value to minimum
                      if (isNaN(formattedInput)) {
                        value = 500000
                        // if the field is lower than minimum set value to minimum
                      } else if (formattedInput < 500000) {
                        value = 500000
                        // if the field is greater than maximum set value to minimum
                      } else if (formattedInput > 150000000) {
                        value = 150000000
                      }

                      //check if initialDeposit value is not lesser than 5% of current sum
                      const minimalInitialDeposit = 0.05 * value
                      if (minimalInitialDeposit > values.initialDeposit) {
                        initialDeposit = minimalInitialDeposit
                        setFieldValue('initialDeposit', minimalInitialDeposit)
                        setFieldValue('initialDepositPercentage', (minimalInitialDeposit / value) * 100)
                      }

                      //check if initialDeposit value is not greater than 49% of current sum
                      const maximumInitialDeposit = 0.49 * value
                      if (maximumInitialDeposit < values.initialDeposit) {
                        initialDeposit = maximumInitialDeposit
                        setFieldValue('initialDeposit', maximumInitialDeposit)
                        setFieldValue('initialDepositPercentage', (maximumInitialDeposit / value) * 100)
                      }

                      if (isNaN(formattedInput)) {
                        setFieldValue('initialDeposit', 25000)
                        setFieldValue('initialDepositPercentage', (25000 / value) * 100)
                      }

                      setFieldValue('totalPrice', value)

                      const monthlyPayment = calculateMonthlyPayment(
                        value,
                        (initialDeposit / value) * 100,
                        values.leasingDuration,
                        values.interestRateOne,
                        values.interestRateTwo,
                        values.interestRateThree
                      )
                      setFieldValue('monthlyPayment', monthlyPayment)

                      setFieldValue('VATpart', calculateVatPart(initialDeposit, monthlyPayment, values.leasingDuration))

                      setFieldValue(
                        'yearAppreciation',
                        calculateYearAppreciation(initialDeposit, monthlyPayment, values.leasingDuration, value)
                      )
                    }}
                    name='totalPrice'
                    label='Стоимость имущества'
                    sx={{
                      marginBottom: '5px'
                    }}
                  />
                  <Slider
                    aria-label='totalPrice'
                    value={!isNaN(values.totalPrice) ? Number(values.totalPrice) : 500000}
                    valueLabelDisplay='off'
                    step={3000000}
                    min={500000}
                    max={150000000}
                    marks
                    onChange={evt => {
                      if (evt !== null) {
                        const target = evt.target as HTMLInputElement
                        setFieldValue('totalPrice', target.value)

                        setFieldValue('initialDepositPercentage', (values.initialDeposit / Number(target.value)) * 100)
                        let initialDeposit = values.initialDeposit

                        //check if initialDeposit value is not lesser than 5% of current sum
                        const minimalInitialDeposit = 0.05 * Number(target.value)
                        if (minimalInitialDeposit > values.initialDeposit) {
                          initialDeposit = minimalInitialDeposit
                          setFieldValue('initialDeposit', minimalInitialDeposit)
                          setFieldValue(
                            'initialDepositPercentage',
                            (minimalInitialDeposit / Number(target.value)) * 100
                          )
                        }

                        //check if initialDeposit value is not greater than 49% of current sum
                        const maximumInitialDeposit = 0.49 * Number(target.value)
                        if (maximumInitialDeposit < values.initialDeposit) {
                          initialDeposit = maximumInitialDeposit
                          setFieldValue('initialDeposit', maximumInitialDeposit)
                          setFieldValue(
                            'initialDepositPercentage',
                            (maximumInitialDeposit / Number(target.value)) * 100
                          )
                        }

                        const monthlyPayment = calculateMonthlyPayment(
                          Number(target.value),
                          (initialDeposit / Number(target.value)) * 100,
                          values.leasingDuration,
                          values.interestRateOne,
                          values.interestRateTwo,
                          values.interestRateThree
                        )
                        setFieldValue('monthlyPayment', monthlyPayment)

                        setFieldValue(
                          'VATpart',
                          calculateVatPart(initialDeposit, monthlyPayment, values.leasingDuration)
                        )

                        setFieldValue(
                          'yearAppreciation',
                          calculateYearAppreciation(
                            values.initialDeposit,
                            monthlyPayment,
                            values.leasingDuration,
                            Number(target.value)
                          )
                        )
                      }
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      500 000 ₽
                    </Typography>
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      150 000 000 ₽
                    </Typography>
                  </Box>
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                  <TextField
                    value={values.initialDeposit}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onChange={handleChange}
                    onBlur={evt => {
                      const formattedInput = toNumber(evt.target.value)

                      let value = formattedInput
                      // if the value is empty set value to minimum
                      if (isNaN(formattedInput)) {
                        value = 0.05 * values.totalPrice
                        // if input is less than 5% from price set value to minimum
                      } else if ((value / values.totalPrice) * 100 < 5) {
                        value = 0.05 * values.totalPrice
                        // if input is greater than 49% from price set value to maximum
                      } else if ((value / values.totalPrice) * 100 > 49) {
                        value = 0.49 * values.totalPrice
                      }

                      setFieldValue('initialDeposit', value)
                      setFieldValue('initialDepositPercentage', (value / values.totalPrice) * 100)

                      const monthlyPayment = calculateMonthlyPayment(
                        values.totalPrice,
                        (value / values.totalPrice) * 100,
                        values.leasingDuration,
                        values.interestRateOne,
                        values.interestRateTwo,
                        values.interestRateThree
                      )
                      setFieldValue('monthlyPayment', monthlyPayment)

                      setFieldValue('VATpart', calculateVatPart(value, monthlyPayment, values.leasingDuration))

                      setFieldValue(
                        'yearAppreciation',
                        calculateYearAppreciation(value, monthlyPayment, values.leasingDuration, values.totalPrice)
                      )
                    }}
                    name='initialDeposit'
                    label='Первоначальный взнос'
                    sx={{
                      marginBottom: '5px'
                    }}
                  />
                  <Slider
                    aria-label='Initial deposit'
                    value={
                      !isNaN((values.initialDeposit / values.totalPrice) * 100)
                        ? (values.initialDeposit / values.totalPrice) * 100
                        : 5
                    }
                    valueLabelDisplay='off'
                    step={5}
                    marks
                    min={5}
                    max={49}
                    onChange={evt => {
                      if (evt !== null) {
                        const target = evt.target as HTMLInputElement
                        const formattedInput = toNumber(target.value)
                        setFieldValue('initialDeposit', Math.floor((formattedInput / 100) * values.totalPrice))
                        setFieldValue('initialDepositPercentage', formattedInput)

                        const monthlyPayment = calculateMonthlyPayment(
                          values.totalPrice,
                          formattedInput,
                          values.leasingDuration,
                          values.interestRateOne,
                          values.interestRateTwo,
                          values.interestRateThree
                        )
                        setFieldValue('monthlyPayment', monthlyPayment)

                        setFieldValue(
                          'VATpart',
                          calculateVatPart(
                            (formattedInput / 100) * values.totalPrice,
                            monthlyPayment,
                            values.leasingDuration
                          )
                        )

                        setFieldValue(
                          'yearAppreciation',
                          calculateYearAppreciation(
                            (formattedInput / 100) * values.totalPrice,
                            monthlyPayment,
                            values.leasingDuration,
                            values.totalPrice
                          )
                        )
                      }
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      5%
                    </Typography>
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      49%
                    </Typography>
                  </Box>
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                  <TextField
                    value={values.leasingDuration}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onChange={handleChange}
                    onBlur={evt => {
                      const formattedInput = toNumber(evt.target.value)

                      let value = formattedInput

                      if (isNaN(formattedInput)) {
                        value = 60
                        // if input is less than 1 month set value to minimum
                      } else if (value < 12) {
                        value = 12
                        // if input is greater than 60 months set value to maximum
                      } else if (value > 60) {
                        value = 60
                      }

                      setFieldValue('leasingDuration', value)

                      const monthlyPayment = calculateMonthlyPayment(
                        values.totalPrice,
                        values.initialDepositPercentage,
                        value,
                        values.interestRateOne,
                        values.interestRateTwo,
                        values.interestRateThree
                      )
                      setFieldValue('monthlyPayment', monthlyPayment)

                      setFieldValue('VATpart', calculateVatPart(values.initialDeposit, monthlyPayment, value))

                      setFieldValue(
                        'yearAppreciation',
                        calculateYearAppreciation(values.initialDeposit, monthlyPayment, value, values.totalPrice)
                      )
                    }}
                    name='leasingDuration'
                    label='Срок лизинга'
                    sx={{
                      marginBottom: '5px'
                    }}
                  />
                  <Slider
                    aria-label='Leasing duration'
                    value={!isNaN(values.leasingDuration) ? Number(values.leasingDuration) : 12}
                    valueLabelDisplay='off'
                    step={1}
                    marks
                    min={12}
                    max={60}
                    onChange={evt => {
                      if (evt !== null) {
                        const target = evt.target as HTMLInputElement
                        setFieldValue('leasingDuration', target.value)

                        const monthlyPayment = calculateMonthlyPayment(
                          values.totalPrice,
                          values.initialDepositPercentage,
                          Number(target.value),
                          values.interestRateOne,
                          values.interestRateTwo,
                          values.interestRateThree
                        )

                        setFieldValue('monthlyPayment', monthlyPayment)

                        setFieldValue(
                          'VATpart',
                          calculateVatPart(values.initialDeposit, monthlyPayment, Number(target.value))
                        )

                        setFieldValue(
                          'yearAppreciation',
                          calculateYearAppreciation(
                            values.initialDeposit,
                            monthlyPayment,
                            Number(target.value),
                            values.totalPrice
                          )
                        )
                      }
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      12 мес.
                    </Typography>
                    <Typography variant='Text-2-M' component='p' sx={{ color: theme.palette['Sup-2'] }}>
                      60 мес.
                    </Typography>
                  </Box>
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                  <Box
                    sx={{
                      marginTop: '30px',
                      marginBottom: '20px',
                      backgroundColor: theme.palette['Sup-1'],
                      padding: '15px',
                      borderRadius: '12px'
                    }}
                  >
                    <Typography variant='Head-3-B' component='p' sx={{ color: theme.palette['Main-1'] }}>
                      {formatter.format(values.monthlyPayment)}
                    </Typography>
                    <Typography
                      variant='Text-2-M'
                      component='p'
                      sx={{ color: theme.palette['Sup-2'], marginTop: '5px' }}
                    >
                      Ежемесячный платеж c НДС
                    </Typography>
                    <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                    <Typography variant='Head-3-B' component='p' sx={{ color: theme.palette['Main-1'] }}>
                      {formatter.format(values.VATpart)}
                    </Typography>
                    <Typography
                      variant='Text-2-M'
                      component='p'
                      sx={{ color: theme.palette['Sup-2'], marginTop: '5px' }}
                    >
                      Экономия по налоговому платежу
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      position: 'fixed',
                      bottom: '58px',
                      zIndex: 10,
                      left: 0,
                      right: 0,
                      width: '100%',
                      maxWidth: '425px',
                      margin: '0 auto',
                      backgroundColor: theme.palette['Main-2'],
                      paddingLeft: '16px',
                      paddingRight: '16px'
                    }}
                  >
                    <Button
                      variant='outlined'
                      sx={{ marginBottom: '25px', marginTop: '25px' }}
                      onClick={() => {
                        setClose(true)
                      }}
                    >
                      Оформить заявку
                    </Button>
                  </Box>
                </Form>

                {isAuth ? (
                  <Dialog
                    open={close}
                    onClose={() => {
                      setClose(false)
                    }}
                    sx={{
                      maxWidth: '352px',
                      margin: '0 auto',
                      '& .MuiPaper-root': {
                        borderRadius: '10px'
                      }
                    }}
                  >
                    <Box sx={{ p: '20px' }}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '1rem',
                          lineHeight: '1.1875rem',
                          mb: '10px'
                        }}
                      >
                        Отправить заявку на лизинг?
                      </Typography>

                      <DialogActions
                        sx={{
                          p: 0,
                          marginTop: '25px',
                          '& .MuiButton-contained': { marginLeft: '14px' }
                        }}
                      >
                        <Button
                          aria-label='no'
                          variant='outlined'
                          type='button'
                          onClick={() => {
                            setClose(false)
                          }}
                        >
                          Нет
                        </Button>
                        <Button aria-label='yes' variant='outlined' onClick={submitForm}>
                          Да
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>
                ) : (
                  <Dialog
                    open={close}
                    onClose={() => {
                      setClose(false)
                    }}
                    sx={{
                      maxWidth: '352px',
                      margin: '0 auto',
                      '& .MuiPaper-root': {
                        borderRadius: '10px'
                      }
                    }}
                  >
                    <Box sx={{ p: '20px', position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '4px',
                          right: '8px',
                          margin: 0,
                          padding: 0,
                          border: 0
                        }}
                        onClick={() => {
                          setClose(false)
                        }}
                      >
                        <IconButton
                          aria-label='close'
                          onClick={() => {}}
                          sx={{
                            width: '18px',
                            height: '18px',
                            backgroundColor: theme.palette['Sup-1'],

                            '& .MuiSvgIcon-root': {
                              fontSize: '0.6rem',
                              color: theme.palette['Sup-2']
                            }
                          }}
                        >
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      </div>

                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '1rem',
                          lineHeight: '1.1875rem',
                          mb: '10px'
                        }}
                      >
                        Заявка на лизинг
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: '1.1875rem',
                          mb: '25px'
                        }}
                      >
                        Оставьте свои данные, и мы вам перезвоним для уточнения деталей!
                      </Typography>

                      <TextField
                        value={values.inn}
                        onChange={handleChange}
                        name='inn'
                        label='ИНН компании'
                        error={errors.inn && touched.inn ? true : false}
                        sx={{
                          marginBottom: '20px'
                        }}
                      />

                      <TextField
                        value={values.name}
                        onChange={handleChange}
                        name='name'
                        label='Имя'
                        error={errors.name && touched.name ? true : false}
                        sx={{
                          marginBottom: '20px'
                        }}
                      />

                      <PhoneInput
                        mask='+7 (999) 999-99-99'
                        value={values.phone}
                        onChange={handleChange}
                        name='phone'
                        error={errors.phone && touched.phone ? true : false}
                      />

                      <TextField
                        value={values.email}
                        onChange={handleChange}
                        name='email'
                        label='E-mail'
                        error={errors.email && touched.email ? true : false}
                        sx={{
                          marginBottom: '20px',
                          marginTop: '20px'
                        }}
                      />

                      <Button aria-label='sendForm' variant='outlined' type='button' onClick={submitForm}>
                        Отправить
                      </Button>
                    </Box>
                  </Dialog>
                )}
              </>
            )}
          </Formik>

          {/* заявка успешно отправлена */}
          <Snackbar
            open={isCalculationRequestSuccess}
            autoHideDuration={1500}
            onClose={() => {}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            sx={{
              bottom: '95px',
              '& .MuiPaper-root': {
                borderRadius: '10px',
                background: 'rgba(33, 33, 33, 0.8)',
                backdropFilter: 'blur(7.5px)',
                alignItems: 'center'
              },

              '& .MuiAlert-icon': {
                marginRight: '8px'
              },

              '& .MuiSnackbarContent-root': {
                minWidth: 'unset'
              },
              '& .MuiSnackbarContent-message': {
                margin: '0 auto'
              }
            }}
          >
            <Alert
              icon={<CheckIcon fontSize='inherit' />}
              sx={{
                '& .MuiAlert-message': {
                  color: theme.palette['Main-2'],
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '15px'
                }
              }}
            >
              Заявка отправлена
            </Alert>
          </Snackbar>
        </Box>
      </>
    </>
  )
}
