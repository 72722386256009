// ** Import MUI components
import { Box } from '@mui/material'

// ** Import react-router-dom
import { Link } from 'react-router-dom'

// ** Import assets
import banner from './calculatorBanner.png'

type CalculatorBannerProps = {
  link: string
}

export const CalculatorBanner = ({ link }: CalculatorBannerProps): JSX.Element => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingX: '16px',
        marginY: '16px'
      }}
    >
      <Link to={link} style={{ display: 'flex', width: '100%' }}>
        <img width='100%' height='auto' src={banner} alt='link to calculator page' />
      </Link>
    </Box>
  )
}
