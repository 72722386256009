// ** Import react
import { useState } from 'react'

// ** Import MUI components
import { useTheme, IconButton, Tooltip as MuiTooltip, ClickAwayListener } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

type TooltipType = {
  disabled: boolean
  text?: string
}

export const Tooltip = ({ disabled, text }: TooltipType): JSX.Element => {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MuiTooltip
        PopperProps={{
          disablePortal: true
        }}
        title={text}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='top-end'
        onClose={handleTooltipClose}
        open={open}
      >
        <IconButton disabled={disabled} onClick={handleTooltipOpen} sx={{ marginLeft: 'auto' }}>
          <InfoOutlinedIcon htmlColor={disabled ? '#CCD2E3' : theme.palette['copper']} />
        </IconButton>
      </MuiTooltip>
    </ClickAwayListener>
  )
}
