import { ENV } from 'env'

// ** Import react
import { useState } from 'react'

// ** Import swiper
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

// ** Import MUI components
import { Box } from '@mui/material'

// ** Import ImageViewer
import ImageViewer from 'react-simple-image-viewer'

type PictureSliderProps = {
  slides: string[]
  isViewer: boolean
}

export const PictureSlider = ({ slides, isViewer }: PictureSliderProps): JSX.Element => {
  const [currentImage, setCurrentImage] = useState<number | undefined>(undefined)
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false)

  const closeImageViewer = () => {
    setCurrentImage(undefined)
    setIsViewerOpen(false)
  }

  return (
    <>
      {isViewerOpen ? (
        <ImageViewer
          src={slides.map(item => `${window.routes5[`media${ENV}`]}${item}`)}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            zIndex: 10
          }}
        />
      ) : null}

      <Swiper
        slidesPerView={'auto'}
        spaceBetween={8}
        style={{
          paddingRight: '16px'
        }}
      >
        {slides.map((slide: string, index: number) => (
          <SwiperSlide
            key={index}
            style={{
              width: '120px'
            }}
          >
            <Box
              sx={{
                borderRadius: '8px',
                height: '90px',
                overflow: 'hidden'
              }}
              onClick={() => {
                if (isViewer) {
                  setIsViewerOpen(true)
                  setCurrentImage(index)
                }
              }}
            >
              <img
                src={`${window.routes5[`media${ENV}`]}${slide}`}
                alt='car'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
