// ** Import react
import { useState, SyntheticEvent } from 'react'

// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography, Tab, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// ** Import components
import { TopNavigation, Loader } from 'shared/ui'

// ** Import api
import { useGetFaqDataQuery } from 'entities/faq'

export const Faq = () => {
  const theme = useTheme()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const { data, isSuccess } = useGetFaqDataQuery()

  const [value, setValue] = useState<string>(data ? data[0].name : '1')
  const [expanded, setExpanded] = useState<string | false>(data ? data[0].questions[0].header : false)

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <TopNavigation
        header='Вопрос/Ответ'
        back={() => {
          navigate(-1)
        }}
      />

      {isSuccess ? (
        <Box sx={{ backgroundColor: theme.palette['Main-2'], paddingBottom: '30px' }}>
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              variant='scrollable'
              scrollButtons={false}
              sx={{
                position: 'relative',
                marginBottom: '15px',
                marginLeft: '16px',
                paddingRight: '16px',

                '&:before': {
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  display: 'block',
                  width: '100%',
                  height: '1px',
                  backgroundColor: theme.palette['Sup-1'],
                  content: '""'
                },
                '& .MuiTabs-indicator': {
                  height: '1px'
                }
              }}
            >
              {data.map(item => (
                <Tab
                  key={item.name}
                  sx={{
                    fontSize: '0.75rem',
                    textTransform: 'none',
                    padding: '8px 15px',

                    '&.Mui-selected': {
                      color: theme.palette['Main-1']
                    }
                  }}
                  label={item.name}
                  value={item.name}
                />
              ))}
            </TabList>

            {data.map((item, index) => (
              <TabPanel
                key={index}
                value={item.name}
                sx={{
                  padding: 0,
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  margin: 0
                }}
              >
                {item.questions.map((question, indx) => (
                  <Accordion
                    key={indx}
                    expanded={expanded === question.header}
                    onChange={handleAccordionChange(question.header)}
                    square={true}
                    sx={{
                      padding: 0,
                      boxShadow: 'none',
                      borderBottom: `1px solid ${theme.palette['Sup-1']}`,

                      '&.Mui-expanded': {
                        borderBottom: `1px solid ${theme.palette['copper']}`,
                        marginBottom: 0
                      },

                      '&:before': {
                        display: 'none'
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        padding: 0,
                        minHeight: '28px',
                        color: theme.palette['Main-1'],

                        '& .MuiSvgIcon-root': {
                          fill: theme.palette['Main-1']
                        },

                        '& .MuiAccordionSummary-content': {
                          marginTop: '15px',
                          marginBottom: '15px'
                        },

                        '&.Mui-expanded': {
                          color: theme.palette['Corp-1'],
                          minHeight: '28px',

                          '& .MuiSvgIcon-root': {
                            fill: theme.palette['Corp-1']
                          },

                          '& .MuiAccordionSummary-content': {
                            margin: '12px 0'
                          }
                        }
                      }}
                    >
                      <Typography variant='Text-1-B'>{question.header}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        paddingTop: '0px',
                        paddingRight: '30px',
                        paddingBottom: '15px',
                        paddingLeft: '0px'
                      }}
                    >
                      {/* <Typography component='div'>{question.text}</Typography> */}
                      <Typography component='div' dangerouslySetInnerHTML={{ __html: question.text }} />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      ) : null}
    </>
  )
}
