import { ENV } from 'env'
import type { InfoResponse, InfoData, AboutResponse, AboutData } from './types'
import { baseApi } from 'shared/api'

export const MetaApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getInfoData: build.mutation<InfoData, void>({
      query: () => {
        const bodyFormData = new FormData()
        bodyFormData.append('action', 'getAllData')

        return {
          url: `${window.routes5.info.requests.getInfoData[`url${ENV}`]}`,
          method: 'POST',
          body: bodyFormData,
          credentials: 'include',
          validateStatus: (response, result: InfoResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: InfoResponse) => {
        const transformedResponse = {
          text: '',
          docs: []
        } as InfoData

        Object.values(response.result).forEach(item => {
          transformedResponse.text = item.TEXT

          item.DOC.forEach(doc => {
            transformedResponse.docs.push({
              name: doc.NAME,
              src: `${window.routes5[`media${ENV}`]}${doc.SRC}`
            })
          })
        })

        return transformedResponse
      }
    }),
    getAboutData: build.query<AboutData, void>({
      query: () => ({
        url: `${window.routes5.about.requests.getInfoData[`url${ENV}`]}`,
        validateStatus: (response, result: AboutResponse) => response.status === 200 && result.status !== 'error'
      }),
      transformResponse: (response: AboutResponse) => {
        const transformedResponse = {
          header: '',
          content: ''
        } as AboutData

        Object.values(response.result).forEach(item => {
          transformedResponse.header = item.name
          transformedResponse.content = item.heading
        })

        return transformedResponse
      }
    })
  })
})

export const { useGetInfoDataMutation, useGetAboutDataQuery } = MetaApi
