// ** react
import { useState, Fragment, SyntheticEvent } from 'react'

// ** react-router
import { useNavigate, useNavigation } from 'react-router-dom'

// ** MUI
import { useTheme, Box, Divider, Tab, Button } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// ** Components
import { TopNavigation } from 'shared/ui'
import { NotificationPreview } from 'entities/notification'
import { Loader } from 'shared/ui'
import filler from './filler.jpg'

// ** Api
import { useGetNotificationDataQuery, useMarkAllAsReadMutation } from 'entities/notification'

export const Notifications = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const [value, setValue] = useState<string>('1')
  const { data, isSuccess } = useGetNotificationDataQuery()
  const [markAllAsRead, { isLoading }] = useMarkAllAsReadMutation()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      {navigation.state === 'loading' || isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <TopNavigation
        header='Уведомления'
        back={() => {
          navigate(-1)
        }}
      />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 60px)',
          backgroundColor: theme.palette['Main-2']
        }}
      >
        <TabContext value={value}>
          <TabList
            onChange={handleChange}
            variant='fullWidth'
            sx={{ marginBottom: '15px', borderBottom: `1px solid ${theme.palette['Sup-1']}` }}
          >
            <Tab
              sx={{
                fontSize: '0.75rem',
                textTransform: 'none',
                padding: '8px 15px',

                '&.Mui-selected': {
                  color: theme.palette['Main-1']
                }
              }}
              label='Все'
              value='1'
            />
            <Tab
              sx={{
                fontSize: '0.75rem',
                textTransform: 'none',
                padding: '8px 15px',

                '&.Mui-selected': {
                  color: theme.palette['Main-1']
                }
              }}
              label='Непрочитанные'
              value='2'
            />
          </TabList>

          <TabPanel
            value='1'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 0,
              paddingLeft: '16px',
              margin: 0
            }}
          >
            {isSuccess
              ? data.map((notification, index) => (
                  <Fragment key={notification.id}>
                    <NotificationPreview
                      to={notification.id}
                      image={notification.image ? notification.image : filler}
                      status={notification.status}
                      date={notification.date}
                      header={notification.header}
                    />
                    {index + 1 !== data.length ? <Divider variant='inset' sx={{ marginLeft: '44px' }} /> : null}
                  </Fragment>
                ))
              : null}

            {isSuccess ? (
              data.filter(unread => unread.status === 'unread').length > 0 ? (
                <Button
                  variant='contained'
                  sx={{
                    width: 'fit-content',
                    margin: '0 auto',
                    marginTop: '10px',
                    paddingTop: '5.5px',
                    paddingRight: '12.5px',
                    paddingBottom: '6.5px',
                    paddingLeft: '12.5px',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '15px'
                  }}
                  onClick={() => {
                    markAllAsRead()
                  }}
                >
                  Прочитать все
                </Button>
              ) : null
            ) : null}
          </TabPanel>

          <TabPanel
            value='2'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 0,
              paddingLeft: '16px',
              margin: 0
            }}
          >
            {isSuccess
              ? data
                  .filter(unread => unread.status === 'unread')
                  .map((notification, index, array) => (
                    <Fragment key={notification.id}>
                      <NotificationPreview
                        to={notification.id}
                        image={notification.image ? notification.image : filler}
                        status={notification.status}
                        date={notification.date}
                        header={notification.header}
                      />
                      {index + 1 !== array.length ? <Divider variant='inset' sx={{ marginLeft: '44px' }} /> : null}
                    </Fragment>
                  ))
              : null}

            {isSuccess ? (
              data.filter(unread => unread.status === 'unread').length > 0 ? (
                <Button
                  variant='contained'
                  sx={{
                    width: 'fit-content',
                    margin: '0 auto',
                    marginTop: '10px',
                    paddingTop: '5.5px',
                    paddingRight: '12.5px',
                    paddingBottom: '6.5px',
                    paddingLeft: '12.5px',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '15px'
                  }}
                  onClick={() => {
                    markAllAsRead()
                  }}
                >
                  Прочитать все
                </Button>
              ) : null
            ) : null}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}
