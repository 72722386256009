import { styled } from '@mui/material'

export const S_Dot = styled('div', { shouldForwardProp: prop => prop !== 'last' })<{ last: boolean }>(props => ({
  position: 'relative',
  width: '12px',
  height: '12px',
  marginTop: '2px',
  border: `2px solid ${props.last ? props.theme.palette['copper'] : `rgba(142, 147, 153, 0.4)`}`,
  borderRadius: '50%',

  '&:before': {
    display: props.last ? 'none' : 'initial',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    margin: 'auto',
    width: '4px',
    height: '4px',
    backgroundColor: 'rgba(142, 147, 153, 0.4)',
    borderRadius: '50%',
    content: '""'
  }
}))

export const S_Connector = styled('div')({
  width: '2px',
  height: '25px',
  marginTop: '-12px',
  marginBottom: '2px',
  marginLeft: '5px',
  backgroundColor: 'rgba(142, 147, 153, 0.4)',
  borderRadius: '2px'
})
