// ** Import MUI
import { Typography } from '@mui/material'

// ** Import styles
import { S_PointWrapp, S_PointTitle, S_PointDescription, S_PointLink } from './DealPoint.styled'

type DealPointProps = {
  title: string
  description?: string
  link?: string
  href?: string
  target?: string
}

export const DealPoint = ({ title, description, link, href, target }: DealPointProps): JSX.Element => {
  return (
    <S_PointWrapp>
      <S_PointTitle variant='Text-2-M' component='p'>
        {title}
      </S_PointTitle>
      {description && (
        <S_PointDescription variant='Text-2-M' component='p'>
          {description}
        </S_PointDescription>
      )}
      {link && (
        <S_PointLink target={target} href={href}>
          <Typography variant='Text-2-M'>{link}</Typography>
        </S_PointLink>
      )}
    </S_PointWrapp>
  )
}
