import type { CatalogBody } from 'entities/catalog/api/types'

// ** Import react-router
import { Link } from 'react-router-dom'

// ** Import MUI
import { useTheme, Box, Typography } from '@mui/material'

// ** Import components
import { PictureSlider } from '../PictureSlider/PictureSlider'

// ** Import utils
import { formatter } from 'shared/utils'

type RecommendationProps = {
  id: string
  price: number
  trend: 'positive' | 'negative'
  name: string
  images: string[]
  link: string
  action?: () => void
  catalogState?: CatalogBody
}

export const Recommendation = ({ price, trend, name, images, link, action, catalogState }: RecommendationProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        '&:not(:last-of-type)': {
          position: 'relative',
          marginBottom: '20px',
          paddingBottom: '20px',

          '&:after': {
            position: 'absolute',
            bottom: '0',
            left: '16px',
            width: 'calc(100% - 32px)',
            height: '1px',
            backgroundColor: theme.palette['Sup-1'],
            transform: 'rotate(0)',
            content: '""'
          }
        }
      }}
    >
      <Link
        to={link}
        state={catalogState}
        style={{ textDecoration: 'none', color: theme.palette['Main-1'] }}
        onClick={action}
      >
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box sx={{ display: 'flex', marginBottom: '2px' }}>
            <Typography variant='Head-3-B' component='p'>
              {formatter.format(price)}
            </Typography>
            <Typography
              variant='Head-3-B'
              component='span'
              sx={{
                color: trend === 'positive' ? theme.palette.True : theme.palette.False
              }}
            >
              {trend === 'positive' ? '↑' : '↓'}
            </Typography>
          </Box>

          <Typography variant='Text-1-M' component='p'>
            {name}
          </Typography>
        </Box>
      </Link>

      {images.length !== 0 ? (
        <Box sx={{ paddingLeft: '16px', marginTop: '10px' }}>
          <PictureSlider slides={images} isViewer={true} />
        </Box>
      ) : null}
    </Box>
  )
}
