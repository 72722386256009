// ** Import React
import { SyntheticEvent, useState } from 'react'

// ** Import Redux
import { useAppDispatch, useAppSelector } from 'shared/hooks'

// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import {
  useTheme,
  Box,
  Tab,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

// ** Import components
import { Loader, TopNavigation, PhoneInput } from 'shared/ui'

// ** Import API
import { useGetProfileQuery, setDefaultCompany } from 'entities/profile'
import { baseApi } from 'shared/api'

export const Profile = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const { data } = useGetProfileQuery()
  const defaultCompany = useAppSelector(state => state.profile.defaultCompany)
  const [value, setValue] = useState('1')

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Box sx={{ background: theme.palette['Main-2'] }}>
        <TopNavigation
          header='Профиль'
          logout={() => {
            dispatch(baseApi.util.resetApiState())
          }}
          back={() => navigate(-1)}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette['Main-2'],
          height: 'calc(100% - 60px)'
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginBottom: '20px'
            }}
          >
            <TabList onChange={handleChange} variant='fullWidth' aria-label='lab API tabs example'>
              <Tab
                sx={{
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  padding: '8px 15px',

                  '&.Mui-selected': {
                    color: theme.palette['Main-1']
                  }
                }}
                label='Ваши данные'
                value='1'
              />

              {data?.companies.length !== 0 ? (
                <Tab
                  sx={{
                    fontSize: '0.75rem',
                    textTransform: 'none',
                    padding: '8px 15px',

                    '&.Mui-selected': {
                      color: theme.palette['Main-1']
                    }
                  }}
                  label='Компания'
                  value='2'
                />
              ) : null}
            </TabList>
          </Box>

          <TabPanel
            value='1'
            sx={{
              backgroundColor: theme.palette['Main-2'],
              display: 'grid',
              rowGap: '20px',
              padding: 0,
              paddingRight: '16px',
              paddingLeft: '16px'
            }}
          >
            <TextField label='Фамилия' defaultValue={data?.lastName} disabled />
            <TextField label='Имя' defaultValue={data?.name} disabled />
            <TextField label='Отчество' defaultValue={data?.secondName} disabled />
            <TextField label='Должность' defaultValue={data?.workPosition} disabled />
            <TextField label='E-mail' defaultValue={data?.email} disabled />
            <PhoneInput mask='+7 (999) 999-99-99' value={data?.phone} readOnly disabled error={false} />
          </TabPanel>

          {data?.companies.length !== 0 && defaultCompany !== undefined ? (
            <TabPanel
              value='2'
              sx={{
                backgroundColor: theme.palette['Main-2'],
                display: 'grid',
                padding: 0,
                paddingRight: '16px',
                paddingLeft: '16px'
              }}
            >
              {data?.companies.map((item, index) => (
                <Accordion
                  key={index}
                  square={true}
                  sx={{
                    padding: 0,
                    boxShadow: 'none',
                    borderBottom: `1px solid ${theme.palette['Sup-1']}`,

                    '&.Mui-expanded': {
                      borderBottom: `1px solid ${theme.palette['Corp-3']}`,
                      marginBottom: 0
                    },

                    '&:before': {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      padding: 0,
                      minHeight: '28px',
                      color: theme.palette['Main-1'],

                      '& .MuiAccordionSummary-content': {
                        marginTop: '15px',
                        marginBottom: '15px'
                      },

                      '&.Mui-expanded': {
                        color: theme.palette['Corp-1'],
                        minHeight: '28px',

                        '& .MuiSvgIcon-root': {
                          fill: theme.palette['True']
                        },

                        '& .MuiAccordionSummary-content': {
                          margin: '12px 0'
                        }
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', columnGap: '6px', alignItems: 'center' }}>
                      {defaultCompany.id === item.id ? (
                        <CheckCircleRoundedIcon sx={{ width: '14px', height: '14px', fill: theme.palette['True'] }} />
                      ) : null}
                      <Typography variant='Text-1-B'>{item.companyName}</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: 0,
                      paddingBottom: '15px'
                    }}
                  >
                    <TextField
                      label='ИНН'
                      defaultValue={item.inn}
                      disabled
                      sx={{ marginBottom: '20px', marginTop: '20px' }}
                    />
                    <TextField label='КПП' defaultValue={item.kpp} disabled sx={{ marginBottom: '20px' }} />
                    <TextField label='ОГРН' defaultValue={item.ogrn} disabled sx={{ marginBottom: '20px' }} />
                    <TextField
                      label='Наименование компании'
                      defaultValue={item.companyName}
                      disabled
                      sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                      label='Юридический адрес'
                      defaultValue={item.companyAddress}
                      disabled
                      multiline
                      sx={{ '& .MuiInputBase-root': { padding: 0, paddingRight: '20px' } }}
                    />

                    <Button
                      variant='outlined'
                      disabled={item.inn === defaultCompany.inn ? true : false}
                      onClick={() => {
                        dispatch(setDefaultCompany(item))
                      }}
                      sx={{
                        marginTop: '20px'
                      }}
                    >
                      {item.inn === defaultCompany.inn ? 'Выбран по умолчанию' : 'Выбрать по умолчанию'}
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))}
            </TabPanel>
          ) : null}
        </TabContext>
      </Box>
    </>
  )
}
