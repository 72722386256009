// **Import styles
import { S_Bell } from './Bell.styled'

// **Import assets
import icon from './Bell.svg'

// **Import api
import { useGetNotificationDataQuery } from 'entities/notification/api/api'

type BellProps = {
  to: string
}

export const Bell = ({ to }: BellProps): JSX.Element => {
  const { data, isSuccess } = useGetNotificationDataQuery()

  return (
    <S_Bell
      to={to}
      isNotification={isSuccess && data.findIndex(item => item.status === 'unread') !== -1 ? true : false}
    >
      <img src={icon} alt='notifications bell' />
    </S_Bell>
  )
}
