import { styled, Box } from '@mui/material'

export const S_Box = styled(Box, { shouldForwardProp: prop => prop !== 'image' })<{ image: string | undefined }>(
  props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    minHeight: '110px',
    // marginRight: '16px',
    // marginLeft: '16px',
    // marginBottom: '20px',
    padding: '15px',
    paddingTop: '20px',
    borderRadius: '12px',
    backgroundColor: props.theme.palette['Sup-3'],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: props.image ? `url(${props.image})` : '',

    '&:before': {
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: '12px',
      background: 'linear-gradient(180deg, rgba(0, 14, 34, 0) 0%, #000E22 100%)',
      content: '""'
    }
  })
)

export const S_Link = styled('a', { shouldForwardProp: prop => prop !== 'image' })<{ image: string | undefined }>(
  props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    minHeight: '110px',
    // marginRight: '16px',
    // marginLeft: '16px',
    // marginBottom: '20px',
    padding: '15px',
    paddingTop: '20px',
    borderRadius: '12px',
    backgroundColor: props.theme.palette['Sup-3'],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: props.image ? `url(${props.image})` : '',
    textDecoration: 'none',

    '&:before': {
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: '12px',
      background: 'linear-gradient(180deg, rgba(0, 14, 34, 0) 0%, #000E22 100%)',
      content: '""'
    }
  })
)
