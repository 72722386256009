// ** Import react-router
import { useNavigation, useNavigate } from 'react-router-dom'

// ** Import MUI components
import { useTheme, Box, Typography } from '@mui/material'

// ** Import components
import { TopNavigation, DownloadPdf, Loader } from 'shared/ui'

// ** Import api
import { useGetInfoDataMutation } from 'entities/meta'

export const Info = (): JSX.Element => {
  const theme = useTheme()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const [, { data, isLoading }] = useGetInfoDataMutation({ fixedCacheKey: 'infoData' })

  return (
    <>
      {navigation.state === 'loading' || isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <TopNavigation
        header='Правовая информация'
        back={() => {
          navigate(-1)
        }}
      />
      <Box sx={{ p: '20px 16px', background: theme.palette['Main-2'], height: 'calc(100% - 60px)' }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '18px',
            color: theme.palette['Main-1'],
            pb: '20px',
            borderBottom: `1px solid ${theme.palette['Sup-1']}`
          }}
        >
          {data?.text}
        </Typography>

        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '22px',
            color: theme.palette['Main-1'],
            marginY: '20px'
          }}
        >
          Документы
        </Typography>

        {data?.docs.map(file => (
          <DownloadPdf key={file.name} link={file.src} name={file.name} />
        ))}
      </Box>
    </>
  )
}
