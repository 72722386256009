import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { baseApi } from 'shared/api'

export const redirectMiddleware: Middleware = (api: MiddlewareAPI) => next => action => {
  if (action.payload?.data?.text === 'needAuth') {
    api.dispatch(baseApi.util.resetApiState())
    window.location.href = '/authentication'
  }
  return next(action)
}
