// ** Import react
import { useState } from 'react'

// ** Import formik
import { Formik, Form } from 'formik'

// ** MUI
import { useTheme, Box, Typography, IconButton, Menu } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// ** components
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg'
import { ReactComponent as SortingIcon } from './sorting-icon.svg'

// ** styles
import { S_Input, S_MenuItem } from './Header.styled'

type HeaderProps = {
  searchHandler: (arg: string) => void
  sortingHandler: (arg: 'expensive' | 'cheap' | 'alphabet') => void
}

export const Header = ({ searchHandler, sortingHandler }: HeaderProps): JSX.Element => {
  const theme = useTheme()
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {isSearchActive ? (
        <Formik
          initialValues={{ search: '' }}
          onSubmit={values => {
            searchHandler(values.search)
          }}
        >
          {({ values, handleChange, submitForm }) => (
            <Form
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '18px',
                paddingRight: '16px',
                paddingBottom: '18px',
                paddingLeft: '16px',
                borderBottom: `1px solid ${theme.palette['Sup-1']}`,
                backgroundColor: theme.palette['Sup-1']
              }}
            >
              <S_Input
                name='search'
                onChange={evt => {
                  handleChange(evt)

                  if (timer) {
                    clearTimeout(timer)
                  }

                  setTimer(
                    setTimeout(() => {
                      submitForm()
                    }, 1000)
                  )
                }}
                value={values.search}
                placeholder='Введите запрос'
              />
              <IconButton
                type='button'
                sx={{ padding: 0, width: '24px', height: '24px' }}
                onClick={() => {
                  setIsSearchActive(false)
                }}
              >
                <CloseIcon sx={{ color: theme.palette['Corp-1'], width: '24px', height: '24px' }} />
              </IconButton>
            </Form>
          )}
        </Formik>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            background: theme.palette['Main-2'],
            paddingTop: '18px',
            paddingRight: '16px',
            paddingBottom: '18px',
            paddingLeft: '16px',
            borderBottom: `1px solid ${theme.palette['Sup-1']}`
          }}
        >
          <Typography variant='Head-1-B' component='h1'>
            Каталог
          </Typography>

          <Box sx={{ display: 'flex', columnGap: '14px' }}>
            <IconButton
              sx={{ padding: 0, width: '24px', height: '24px' }}
              onClick={() => {
                setIsSearchActive(true)
              }}
            >
              <SearchIcon />
            </IconButton>

            <IconButton sx={{ padding: 0, width: '24px', height: '24px' }} onClick={handleClick}>
              <SortingIcon />
            </IconButton>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={() => {
                handleClose()
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              sx={{
                '& .MuiPaper-root': {
                  boxShadow: '0px 8px 25px rgba(1, 26, 64, 0.35)',
                  borderRadius: '12px'
                }
              }}
            >
              <S_MenuItem
                onClick={() => {
                  sortingHandler('expensive')
                  handleClose()
                }}
              >
                Cначала дороже
              </S_MenuItem>
              <S_MenuItem
                onClick={() => {
                  sortingHandler('cheap')
                  handleClose()
                }}
              >
                Cначала дешевле
              </S_MenuItem>
              <S_MenuItem
                onClick={() => {
                  sortingHandler('alphabet')
                  handleClose()
                }}
              >
                По названию
              </S_MenuItem>
            </Menu>
          </Box>
        </Box>
      )}
    </>
  )
}
