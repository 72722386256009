// ** Import MUI components
import { Box } from '@mui/material'

// ** Import animation library
import { Reveal, Tween } from 'react-gsap'

// ** Import components
import Filler from './filler.svg'

// ** Import lazy components
import { LazyLoadImage } from 'react-lazy-load-image-component'

// ** Import styles
import { CardDate, CardTitle, CardWrapp, CardLink } from './NewsCard.styled'

// ** Import utils
import { formatDate, getHumanMonth } from 'shared/utils'

export type NewsCardProps = {
  ID: number
  PREVIEW_PICTURE?: string
  NAME: string
  DATE: string
  navigationRoute: string
  onClick?: () => void
}

export const NewsCard = ({ ID, PREVIEW_PICTURE, NAME, DATE, navigationRoute, onClick }: NewsCardProps): JSX.Element => {
  const cardDate = new Date(formatDate(DATE))

  return (
    <Reveal>
      <Tween from={{ opacity: 0 }} duration={2}>
        <CardWrapp>
          <CardLink to={navigationRoute} onClick={onClick}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mb: '10px',
                borderRadius: '10px',
                overflow: 'hidden',
                maxHeight: '165px'
              }}
            >
              <LazyLoadImage
                width='100%'
                height='100%'
                src={PREVIEW_PICTURE !== undefined ? PREVIEW_PICTURE : Filler}
                alt='news-img'
              />
            </Box>

            <CardTitle>{NAME}</CardTitle>
            <CardDate>{`${cardDate.getDate()} ${getHumanMonth(
              cardDate.getMonth()
            )} ${cardDate.getFullYear()}`}</CardDate>
          </CardLink>
        </CardWrapp>
      </Tween>
    </Reveal>
  )
}
