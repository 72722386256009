// ** Import react
import { useEffect } from 'react'

// ** Import redux
import { useAppDispatch, useAppSelector } from '../../shared/hooks'

// ** Import react-router-dom
import { useNavigate } from 'react-router-dom'

// ** Import formik
import { Formik, Form } from 'formik'

// ** Import MUI components
import { useTheme, Alert, Button, Box, Snackbar } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// ** Import components
import { TopNavigation, Code, Loader } from 'shared/ui'

// ** Import styles
import { S_Container, S_Heading, S_Navigation, S_Sended } from './Pin.styled'

// ** Import api
import { setIsAuth } from 'entities/meta'
import { useSendPinMutation, useSendForgotPinMutation } from 'entities/auth'

export const Pin = (): JSX.Element => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isAuth = useAppSelector(state => state.meta.isAuth)
  const userPhone = useAppSelector(state => state.meta.authPhone)
  const [sendAuthPin, { isLoading, isSuccess, isError, data, error, reset }] = useSendPinMutation()
  const [
    sendForgotPin,
    {
      isLoading: isForgotLoading,
      isSuccess: isForgotSuccess,
      isError: isForgotError,
      error: forgotError,
      reset: forgotReset
    }
  ] = useSendForgotPinMutation()

  useEffect(() => {
    if (data === 'success' && isAuth) {
      navigate('/')
    }
  }, [data, isAuth, navigate])

  return (
    <>
      {isLoading || isSuccess || isForgotLoading || isForgotSuccess ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Snackbar
        open={isError || isForgotError}
        autoHideDuration={3000}
        onClose={() => {
          reset()
          forgotReset()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          bottom: '95px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: 'rgba(33, 33, 33, 0.8)',
            backdropFilter: 'blur(7.5px)',
            alignItems: 'center'
          },

          '& .MuiAlert-icon': {
            marginRight: '8px'
          },

          '& .MuiSnackbarContent-root': {
            minWidth: 'unset'
          },
          '& .MuiSnackbarContent-message': {
            margin: '0 auto'
          }
        }}
      >
        <Alert
          icon={<ErrorOutlineIcon fontSize='inherit' sx={{ color: theme.palette['Main-2'] }} />}
          sx={{
            '& .MuiAlert-message': {
              color: theme.palette['Main-2'],
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px'
            }
          }}
        >
          {isError ? (error as string) : null}
          {isForgotError ? (forgotError as string) : null}
        </Alert>
      </Snackbar>

      <TopNavigation
        header='Пин-код'
        back={() => {
          navigate(-1)
        }}
      />

      {userPhone ? (
        <S_Container>
          <S_Heading variant='Head-2-B' component='h2'>
            Ведите ваш пин-код
          </S_Heading>

          <Formik
            initialValues={{ pin: '', phone: userPhone }}
            validateOnBlur={false}
            onSubmit={values => {
              sendAuthPin(values)
                .unwrap()
                .then(res => {
                  if (res === 'success') {
                    dispatch(setIsAuth(true))
                  }
                })
                .catch(() => {})
            }}
          >
            {({ setFieldValue, submitForm }) => (
              <>
                <Form noValidate>
                  <Code
                    status={'error'}
                    pin={true}
                    onComplete={(value: string) => {
                      setFieldValue('pin', value)
                      submitForm()
                    }}
                  />

                  <S_Navigation>
                    <S_Sended variant='Text-1-R' component='p' sx={{ marginBottom: '10px' }}>
                      Забыли пин-код?
                    </S_Sended>

                    <Button
                      variant='outlined'
                      aria-label='send code'
                      type='button'
                      onClick={() => {
                        sendForgotPin({ phone: userPhone })
                          .unwrap()
                          .then(res => {
                            if (res === 'success') {
                              navigate('/sms')
                            }
                          })
                          .catch(() => {})
                      }}
                    >
                      Восстановить пин-код
                    </Button>
                  </S_Navigation>
                </Form>
              </>
            )}
          </Formik>
        </S_Container>
      ) : null}
    </>
  )
}
