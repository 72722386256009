import { styled, Typography } from '@mui/material'

export const S_Container = styled('section')({
  margin: 0,
  padding: 0,
  width: '100%'
})

export const S_Empty = styled('img')({
  display: 'block',
  width: '64px',
  height: '62px',
  margin: '0 auto'
})

export const S_Heading = styled(Typography)(props => ({
  margin: 0,
  marginTop: '15px',
  marginBottom: '5px',
  padding: 0,
  textAlign: 'center',
  color: props.theme.palette['Main-1']
})) as typeof Typography

// p 'Text-2-R'
export const S_Description = styled(Typography)(props => ({
  margin: '0 auto',
  marginBottom: '20px',
  maxWidth: '200px',
  color: props.theme.palette['Sup-2'],
  textAlign: 'center'
})) as typeof Typography
