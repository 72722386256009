// ** Import MUI components
import { useTheme, Box } from '@mui/system'
import { Typography } from '@mui/material'

// ** Import redux
import { useAppSelector } from 'shared/hooks'

// ** Import react-router
import { useLoaderData, useNavigate, useNavigation } from 'react-router-dom'

// ** Import components
import { Loader, TopNavigation } from 'shared/ui'
import { NewsCard } from 'shared/ui'
import filler from './filler.svg'

// ** Import swiper components
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

// ** Import styles
import { Container, S_Date, Title } from './NewsDetail.styled'

// ** Import lazy components
import { LazyLoadImage } from 'react-lazy-load-image-component'

// // ** Import api
import { FullNews } from 'entities/news/api/types'
import { formatDate, getHumanMonth } from 'shared/utils'

export const NewsDetail = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const currentPage = useAppSelector(state => state.meta.currentArticlesPage)
  const { data, sliderCardsRoute } = useLoaderData() as {
    data: FullNews
    sliderCardsRoute: '/more/help/useful-info' | '/more/news'
  }
  const newsDate = new Date(formatDate(data.DATE))

  function createMarkup() {
    return { __html: data.DETAIL_TEXT }
  }

  return (
    <>
      {navigation.state === 'loading' ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 11,
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(17, 17, 17, 0.4)'
          }}
        >
          <Loader width={60} />
        </Box>
      ) : null}

      <Box>
        <Box
          sx={{
            position: 'sticky',
            zIndex: 100,
            top: '0'
          }}
        >
          <TopNavigation header='Публикация' back={() => navigate(-1)} />
        </Box>

        <Container sx={{ background: theme.palette['Main-2'] }}>
          <Title>{data.NAME}</Title>
          <S_Date>{`${newsDate.getDate()} ${getHumanMonth(newsDate.getMonth())} ${newsDate.getFullYear()}`}</S_Date>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginY: '20px',
              borderRadius: '10px',
              overflow: 'hidden',
              maxHeight: '165px'
            }}
          >
            <LazyLoadImage
              width='100%'
              height='100%'
              src={data.PREVIEW_PICTURE !== null ? data.PREVIEW_PICTURE : filler}
              alt='news-img'
            />
          </Box>

          <div className='fullNews' dangerouslySetInnerHTML={createMarkup()} />
        </Container>
      </Box>

      <Box sx={{ background: theme.palette['Main-2'], paddingY: '20px', marginTop: '8px' }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '22px',
            color: theme.palette['Main-1'],
            paddingX: '16px',
            mb: '20px'
          }}
        >
          {currentPage === 'news' ? 'Другие новости' : 'Другие статьи'}
        </Typography>
        {data.otherNews && (
          <Swiper slidesPerView={1} spaceBetween={25} autoplay style={{ paddingRight: '16px', paddingLeft: '16px' }}>
            {data.otherNews.map(slide => (
              <SwiperSlide key={slide.ID}>
                <NewsCard {...slide} navigationRoute={`${sliderCardsRoute}/${slide.ID}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
    </>
  )
}
