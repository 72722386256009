import { styled, Box } from '@mui/material'
import { Swiper } from 'swiper/react'

export const S_MainSwiper = styled(Swiper)({
  '& .swiper-pagination': {
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineJeight: '14px',
    width: 'fit-content',
    right: 0,
    margin: '0 auto',
    paddingTop: '1px',
    paddingRight: '6px',
    paddingBottom: '1px',
    paddingLeft: '6px',
    borderRadius: '15px',
    backgroundColor: 'rgba(33, 33, 33, 0.8)',
    backdropFilter: 'blur(2.5px)'
  }
})

export const S_MainSlide = styled(Box)({
  width: '100%',
  height: '190px',

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
})

export const S_ThumbsSwiper = styled(Swiper)(props => ({
  '& .swiper-slide-thumb-active': {
    outline: `solid 1px ${props.theme.palette['Corp-1']}`,
    outlineOffset: '-1px'
  }
}))

export const S_ThumbsSlide = styled(Box)({
  width: '50px',
  height: '30px',

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
})
