import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { ReactComponent as MainIcon } from './icons/main-icon.svg'
import { ReactComponent as DealsIcon } from './icons/deals-icon.svg'
import { ReactComponent as CatalogIcon } from './icons/catalog-icon.svg'
import { ReactComponent as ManagerIcon } from './icons/manager-icon.svg'
import { ReactComponent as MoreIconIcon } from './icons/more-icon.svg'

export const S_MainIcon = styled(MainIcon)({
  fill: 'currentColor'
})

export const S_DealsIcon = styled(DealsIcon)({
  fill: 'transparent',
  path: {
    '&:firstOfType': {
      stroke: 'currentColor'
    },
    '&:lastOfType': {
      fill: 'currentColor'
    }
  }
})

export const S_CatalogIcon = styled(CatalogIcon)({
  fill: 'currentColor',
  stroke: 'currentColor'
})

export const S_ManagerIcon = styled(ManagerIcon)({
  fill: 'currentColor'
})

export const S_MoreIcon = styled(MoreIconIcon)({
  fill: 'currentColor'
})

export const S_Link = styled(NavLink)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})
