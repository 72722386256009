import { styled, Typography } from '@mui/material'

export const S_Container = styled('div')(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'calc(100% - 60px)',
  paddingRight: '16px',
  paddingLeft: '16px',
  backgroundColor: props.theme.palette['Main-2']
}))

export const S_Heading = styled(Typography)(props => ({
  margin: 0,
  marginBottom: '10px',
  padding: 0,
  textAlign: 'center',
  color: props.theme.palette['Main-1']
})) as typeof Typography
