import { ENV } from 'env'

// ** Import React
import { useTheme, Typography, Box } from '@mui/material'

// ** Import components
import { DealPoint, DownloadPdf } from 'shared/ui'
import { Steps } from '../Steps/Steps'
import { TablePayments } from '../TablePayments/TablePayments'

// ** Import utils
import { formatter } from 'shared/utils'

type TabPaymentsProps = {
  payment_sum?: number
  sum?: number
  next_payment: string
  next_payment_date: string
  remaining_payments: string
  payment_schedule: {
    date: string
    price: number
    payment: number
  }[]
  questionRoute: string
  docs: {
    name: string
    src: string
    size: string
  }[]
}

const calculateStep = (pay_sum: number, total_sum: number) => {
  const value = Math.round((pay_sum / total_sum) * 100)

  switch (true) {
    case value < 25:
      return 1
    case value > 25 && value < 50:
      return 2
    case value > 50 && value < 75:
      return 3
    case value > 75 && value < 100:
      return 4
    case value >= 100:
      return 5
    default:
      return 1
  }
}

export const TabPayments = ({
  payment_sum,
  sum,
  next_payment,
  next_payment_date,
  remaining_payments,
  payment_schedule,
  questionRoute,
  docs
}: TabPaymentsProps): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      <Box sx={{ paddingX: '16px' }}>
        <DealPoint
          title='Очередной платеж'
          description={`${next_payment}<br/>${next_payment_date !== '' ? `(до ${next_payment_date})` : ''}`
            .split('<br/>')
            .join('\n')}
        />
        {payment_sum ? <DealPoint title='Оплаченная сумма' description={formatter.format(payment_sum)} /> : null}

        <DealPoint title='Остаток платежей' description={remaining_payments} />

        {sum && payment_sum ? <Steps step={calculateStep(payment_sum, sum)} /> : null}

        <Typography
          sx={{
            fontSize: '1.125rem',
            fontWeight: 700,
            marginY: '20px'
          }}
        >
          График платежей
        </Typography>
      </Box>
      <TablePayments schedule={payment_schedule} questionRoute={questionRoute} />

      {docs.length > 0 ? (
        <Box sx={{ paddingX: '16px' }}>
          <Typography
            sx={{
              borderTop: `1px solid ${theme.palette['Sup-1']}`,
              paddingTop: '20px',
              fontSize: '1.125rem',
              fontWeight: 700,
              marginBottom: '20px'
            }}
          >
            Закрытие договора
          </Typography>

          {docs.map(doc => (
            <DownloadPdf
              key={doc.name}
              link={`${window.routes5[`media${ENV}`]}${doc.src}`}
              name={doc.name}
              weight={doc.size}
            />
          ))}
        </Box>
      ) : null}
    </>
  )
}
