import { ENV } from 'env'
import type { ProfileResponse, ProfileData } from './types.js'
import { baseApi } from 'shared/api'

export const ProfileApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getProfile: build.query<ProfileData, void>({
      query: () => {
        return {
          url: `${window.routes5.profile.requests.getData[`url${ENV}`]}`,
          params: {
            action: 'getData'
          },
          credentials: 'include',
          validateStatus: (response, result: ProfileResponse) => response.status === 200 && result.status !== 'error'
        }
      },
      transformResponse: (response: ProfileResponse) => {
        const transformedResponse = {
          lastName: response.result.LAST_NAME,
          name: response.result.NAME,
          secondName: response.result.SECOND_NAME,
          workPosition: response.result.WORK_POSITION,
          email: response.result.EMAIL,
          phone: response.result.LOGIN,
          initials: `${response.result.LAST_NAME.substring(0, 1)}${response.result.NAME.substring(0, 1)}`,
          bonus: response.result.BONUS,
          defaultCompany: {
            id: response.result.company[0].ID,
            inn: response.result.company[0].PROPERTY_INN_VALUE,
            kpp: response.result.company[0].PROPERTY_KPP_VALUE,
            ogrn: response.result.company[0].PROPERTY_OGRN_VALUE,
            companyName: response.result.company[0].NAME,
            companyAddress: response.result.company[0].PROPERTY_ADDRESS_VALUE
          },
          companies: []
        } as ProfileData

        response.result.company.forEach(company => {
          transformedResponse.companies.push({
            id: company.ID,
            inn: company.PROPERTY_INN_VALUE,
            kpp: company.PROPERTY_KPP_VALUE,
            ogrn: company.PROPERTY_OGRN_VALUE,
            companyName: company.NAME,
            companyAddress: company.PROPERTY_ADDRESS_VALUE
          })
        })

        return transformedResponse
      }
    })
  })
})

export const { useGetProfileQuery } = ProfileApi
