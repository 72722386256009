// ** Import React
import { forwardRef } from 'react'

// ** Import MUI components
import { useTheme, ButtonBase, Typography } from '@mui/material'

// ** Import styles
import { S_MainIcon, S_DealsIcon, S_CatalogIcon, S_ManagerIcon, S_MoreIcon, S_Link } from './MenuItem.styled'

type MenuItemProps = {
  id: number
  href: string
  isAuth?: boolean
  icon: MenuType
  name: string
}

type MenuType = 'main' | 'deals' | 'catalog' | 'manager' | 'more'

type RippleProps = {
  children: null
}

const VariantIcon = {
  main: <S_MainIcon />,
  deals: <S_DealsIcon />,
  catalog: <S_CatalogIcon />,
  manager: <S_ManagerIcon />,
  more: <S_MoreIcon />
}

export const MenuItem = ({ id, href, name, icon }: MenuItemProps): JSX.Element => {
  const theme = useTheme()
  const variantIcon = VariantIcon[icon]

  return (
    <ButtonBase
      key={id}
      component={forwardRef<HTMLAnchorElement, RippleProps>((props, ref) => (
        <S_Link
          style={({ isActive }) => ({
            color: isActive ? theme.palette['copper'] : theme.palette['Corp-1']
          })}
          to={href}
          ref={ref}
          {...props}
        >
          {variantIcon}
          <Typography sx={{ fontSize: '0.5rem', fontWeight: 500, color: theme.palette['Corp-1'] }}>{name}</Typography>
          {props.children}
        </S_Link>
      ))}
      sx={{
        color: theme.palette.False,
        paddingTop: '10px',
        paddingBottom: '12px',
        '& .MuiTouchRipple-ripple': {
          '& .MuiTouchRipple-child': {
            backgroundColor: theme.palette['Corp-1']
          }
        }
      }}
    ></ButtonBase>
  )
}
